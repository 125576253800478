import {
  Directive,
  Input,
  OnInit,
  ElementRef,
  Host,
  HostListener,
  forwardRef,
  HostBinding,
  Renderer2,
  ChangeDetectorRef,
  Attribute
} from '@angular/core';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextFormElement } from '../text/text-form-element';
import { DateService } from '@tmhcc-ng-lib/framwork/services';

@Directive({
  selector: 'input[hccDateFormat]'
})
export class DateinputDirective implements OnInit {
  seprator;
  dateFormat;
  length;
  match;
  replace;
  @HostBinding('attr.maxlength') fieldLength = 10;
  constructor(
    protected configService: AppConfigService,
    private dateService: DateService,
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute('updateEvent') protected updateEvent
  ) {
    // super(renderer, el, csRef, updateEvent);
  }

  private getSeparater(format) {
    const match = format.match(/[-/.]/g);
    if (match) {
      return match[0];
    } else {
      return '/';
    }
  }

  ngOnInit() {
    this.dateService.getFormat();
    this.seprator = this.getSeparater(this.dateService.getFormat());
    this.dateFormat = this.dateService.getFormat().toLowerCase();
    this.match = new RegExp(
      this.dateFormat
        .replace(/(\w+)\W(\w+)\W(\w+)/, '^\\s*($1)\\W*($2)?\\W*($3)?([0-9]*).*')
        .replace(/d|m|y/g, '\\d')
    );
    this.replace = `$1${this.seprator}$2${this.seprator}$3$4`.replace(
      /\//g,
      this.dateFormat.match(/\W/)
    );
  }

  doFormat(target) {
    target.value = target.value
      .replace(/(^|\W)(?=\d\W)/g, '$10')
      .replace(this.match, this.replace)
      .replace(/(\W)+/g, '$1');
  }

  @HostListener('beforeinput', ['$event'])
  beforeInputValidate(event) {
    if (!event.data) {
      return;
    }
    if (/^[0-9/-]+$/i.test(event.data)) {
      return true;
    } else {
      return false;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyup(event) {
    if (
      !event.ctrlKventy &&
      !event.metaKey &&
      (event.keyCode === 32 || event.keyCode > 46)
    )
      this.doFormat(event.target);
  }
}

type dateFormatType =
  | 'mmddyyyy'
  | 'ddmmyyyy'
  | 'yyyyddmm'
  | 'yyddmm'
  | 'mmddyy'
  | 'ddmmyy'
  | 'yyyymmdd';
const sepratorPosition = {
  mmddyyyy: [2, 5],
  ddmmyyyy: [2, 5],
  yyyyddmm: [4, 7],
  yyddmm: [2, 5],
  mmddyy: [2, 5],
  ddmmyy: [2, 5],
  yyyymmdd: [4, 7]
};
