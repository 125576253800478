import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { GetCorePageDataService } from '../sitecore-data-mapper/get-core-page-data.service';
// import { ClaimMaster } from '@tmhcc-ng-lib/models';

@Injectable()
export class BaseResolverService implements Resolve<any> {
  // masterData: ClaimMaster;
  policyData: any;
  constructor(private getSiteCoreDataService: GetCorePageDataService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const pageName = route.data.state;
    return forkJoin({
      siteCore: this.getSiteCoreDataService.getSiteCoreData(pageName)
      //masterData: this.getSiteCoreDataService.getClaimMasterData(),
      //subCompany: this.getSiteCoreDataService.getSubCompanyData(),
      //policyMasterData: this.getSiteCoreDataService.getPolicyMasterData()
    });
  }
}
