// import { startCase } from '@tmhcc/util';
import { startCase } from './lodash-index';

export const JoinArgs = (args: string[] | string, format = false): string => {
  if (Array.isArray(args)) {
    const decorator = format ? ', ' : ',';
    return args.join(decorator);
  }

  return args;
};

export const ReplaceAt = (
  input: string,
  index: number,
  length: number,
  newStr: string
): string => {
  if (index > input.length - 1) {
    return newStr;
  }
  return input.substr(0, index) + newStr + input.substr(index + length);
};

export function convertToProperCase(s: string) {
  return startCase(s);
}

export function getBaseUrl(url: string) {
  if (!url || '') return '';
  var pathArray = url.split('/');
  if (pathArray && pathArray.length > 1) {
    var protocol = pathArray[0];
    var host = pathArray[2];
    return protocol + '//' + host;
  }
  return '';
}
