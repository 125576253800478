import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ClaimSearchGridRow, CoverageTypes } from '@tmhcc-ng-lib/models';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import {
  DeleteQuote,
  PolicyFormBuilderResponse,
  Quotes,
  Formbuilder,
  QuoteAttributes,
  Quote,
  Lobs,
  Risks,
  RiskAttributes,
  Coverages,
  FluffyDataElement,
  QuoteResult,
  CopyQuote,
  FormbuilderLOBS,
  FormbuilderRisks,
  PolicyTxnAttributes
} from './def/coverage-policies';
import { has } from '@tmhcc/util';
import {
  QuoteNum,
  CoverageCodes,
  CoverageElement,
  RiskType
} from './def/coverage-limit-data-elements';
import { InitialDataElements } from '@tmhcc-product-contamination/framwork/data-element-builder';
import { ElementName } from 'libs/framwork/data-element-builder/src/lib/utility/deb-utility.def';
import { CoverageDataElementsService } from '@tmhcc/product-contamination';
import {
  CancellationAdjustmentResponse
} from './def/cancellation-adjustment';
@Injectable()
export class CancellationAdjustmentService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService,
    private requestConverterService: CoverageDataElementsService
  ) {
    super(http, configService);
  }

  policySearchData: ClaimSearchGridRow[];
  quoteFormBuilderCachedData: PolicyFormBuilderResponse;
  quoteCachedData: Quote[] = [];
  quoteFormBuilderCache: Formbuilder;
  quoteAttributeCache = [];
  QuoteResultCache: QuoteResult[] = [];
  quoteCacheCoverages: Coverages[] = [];
  quoteGeneralCache: PolicyTxnAttributes;
  
  saveCancellation(payload: any) {
    const url = `${this.serviceBaseUrl}/SaveCancellation`;
    return this.put<CancellationAdjustmentResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  get GetGenralBuilder() {
    if (!this.quoteGeneralCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    return this.quoteGeneralCache;
  }
  get GetFormBuilder() {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    return this.quoteFormBuilderCache;
  }
 
  private handleError(err) {
    return throwError(err);
  }

  setRisks = {
    Id: null,
    Type: '',
    Attributes: {}
  };
}
