import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseHttpInterceptor } from './base-http-interceptor.service';

export const AcHttpInterceptorProviders: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseHttpInterceptor,
    multi: true
  }
];

export * from './http-options';
