<form [formGroup]="frmGroup">
  <label
    [ngbTooltip]="toolTipText"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validations">
    <div class="input-group date-field nopadding">
      <input
        type="text"
        class="form-control input-sm"
        placement="bottom"
        [value]="value"
        inputValidateRef
        [control]="control"
        [formGroup]="frmGroup"
        [bsValue]="InputConfig.datePickerConfig?.defaultDate"
        [bsConfig]="getDatePickerConfig()"
        [maxDate]="InputConfig.datePickerConfig?.maxDate"
        #dp="bsDatepicker"
        [minDate]="InputConfig.datePickerConfig?.minDate"
        hccDateFormat
        (bsValueChange)="onChange($event)"
        [id]="fieldInfo.fieldName"
        bsDatepicker
        [formControlName]="control"
        [readOnly]="InputConfig?.isDisabled"
      />
      <div
        class="input-group-append"
        *ngIf="InputConfig.datePickerConfig.isCalanderRequired"
      >
        <button
          class="input-group-text pointer"
          [disabled]="InputConfig?.isDisabled"
          (click)="dp.show()"
        >
          <span class="fa fa-calendar"></span>
        </button>
      </div>
    </div>
  </hcc-custom-validation>
</form>
