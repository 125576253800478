import { FieldInfo, InputConfig } from '@tmhcc-ng-lib/framwork/form-inputs';

export interface GridDefinition {
  Columns: Array<InputConfig>;
  Rows: any;
  RowsPerPage?: number;
}

export interface ColumnDefinition {
  fieldInfo: FieldInfo;
  validation?: [];
  optionField?: any;
  columnType?: any; // required to prashant
  isSortable?: boolean;
  alignment?: string;
  format?: string;
  isKeyColumn?: boolean;
}

export const enum AddRowTypes {
  Link = 1,
  Popup = 2
}

// export interface popUpDataConfig{
//   config : Array;
// }

export interface GridOptions {
  gridName?: string;
  addRow?: boolean;
  RowText?: string;
  deleteRow?: boolean;
  paging?: boolean;
  pageLength?: number;
  searching?: boolean;
  ordering?: boolean;
  isCheckboxRequired?: boolean;
  responsive?: boolean;
  addRowType?: AddRowTypes;
  popDataConfig?: Array<any>;
  isVisibility?: boolean;
  isPersist?: boolean;
  allowExcelExport?: boolean;
  allowPdfExport?: boolean;
  orderColumn?: any;
}

export interface AccordionGrid {
  columnDefination: ColumnDefinition[];
  gridOptions: GridOptions;
  data: any;
  isCheckbox?: boolean;
}

export enum Types {
  link = 'link',
  default = 'default',
  date = 'date',
  string = 'string',
  currency = 'currency',
  view = 'view',
  additionalLink = 'additionalLink',
  checkbox = 'checkbox'
}

export enum EventTypeFromGrid {
  radioBox = 'radioBox',
  checkBox = 'checkBox',
  link = 'link',
  delete = 'delete',
  view = 'view',
  info = 'Info',
  edit = 'edit' 
}

export interface GridOtherDefinitions {
  isDeleteOption?: boolean;
  isRadioBox?: boolean;
  isCheckbox?: boolean;
}

export interface PaginationSettings {
  itemsPerPage?: number;
  currentPage?: number;
  totalItems?: number;
}
