import { Injectable } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    message: string,
    title: string = 'Warning',
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm' | 'lg' = 'lg'
  ): Promise<boolean> {
    let option: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      keyboard: false
    };
    const modalRef = this.modalService.open(
      ConfirmationDialogComponent,
      option
    );
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
