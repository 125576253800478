import {
  GetClaimResponse,
  ClaimDataElementCollection,
  ClaimInsuredInfos,
  RetailAgency,
  Issues
} from 'libs/data-service/def/get-claim';
import * as _ from 'lodash';
import { isEmpty } from '@tmhcc/util';
import { has } from 'libs/util/has-value';
export class ClaimData {
  summary: Summary;
  claimEvent: ClaimEvent;
  claimNotes: ClaimNotesInfo;
  claimGeneralInfo: ClaimGeneralInfo;
  setupReserve: ClaimSetupReserve;
  fullResponse: GetClaimResponse;
  policyInformation: PolicyInformation;
  riskInformation: RiskInformation[];
  cashPayor: CashPayor[];
  claimInsuredSummaryInfo: InsuredClaimSummaryInfo[];
  claimAgencySummaryInfo: ClaimAgencyInfos[];
  claimCollection: AssociatedClaimCollection[];
  insuredInformation: InsuredInformation;
  agencyInformation: AgencyInformation;
  claimProcedureText: string;
  insuredInfos: ClaimInsuredInfos[];
  retailAgency: RetailAgency;
  relatedClaims: RelatedClaims[];
  claimants: Claimant[];
  claimIssueslist: Issues[];
}

export class Claimant {
  ContactId: number;
  FileAsName: string;
}

export class RelatedClaims {
  ClaimAssociationId?: number;
  AssociatedClaimId?: number;
  ClaimId?: number;
  ClaimNum?: string;
  PolicyNum?: string;
  ClaimStatus?: string;
  ClaimHandler?: string;
  ClaimantName?: string;
  ClaimTitle?: string;
  InsuredName?: string;
  ClaimAttorney?: string;
  LossDate?: string;
  checkboxChecked?: boolean;
}
export class ClaimAgencyInfos {
  Agency?: string;
  AddressType?: string;
  NameType?: string;
  Address?: string;
  City?: string;
  StateName?: string;
  ZipCode?: string;
  County?: string;
  PhoneNo?: string;
  Email?: string;
}

export class InsuredClaimSummaryInfo {
  InsuredName?: string;
  EntityNo?: string;
  EntityType?: string;
  Status?: string;
  Address?: string;
  City?: string;
  StateName?: string;
  ZipCode?: string;
  County?: string;
  PhoneNo?: string;
  Email?: string;
}

export class AssociatedClaimCollection {
  ClaimNum?: string;
  ClaimEntryType?: string;
  CauseOfLoss?: string;
  ClaimStatus?: string;
  LossDate: string;
  PolicyNum?: string;
  RiskDescription?: string;
  Claimant?: string;
  ClaimMadeDate?: string;
  Insured?: string;

  constructor(
    ClaimNum: string,
    ClaimEntryType: string,
    CauseOfLoss: string,
    ClaimStatus: string,
    LossDate: string,
    PolicyNum: string,
    RiskDescription: string,
    Claimant?: string,
    ClaimMadeDate?: string,
    Insured?: string
  ) {
    if (
      isEmpty(ClaimNum) ||
      isEmpty(ClaimEntryType) ||
      isEmpty(CauseOfLoss) ||
      isEmpty(ClaimStatus) ||
      isEmpty(LossDate) ||
      isEmpty(PolicyNum) ||
      isEmpty(RiskDescription) ||
      isEmpty(ClaimMadeDate)
    ) {
      throw new Error('Invalid Claim Event.');
    }
    this.ClaimNum = ClaimNum;
    this.ClaimEntryType = ClaimEntryType;
    this.CauseOfLoss = CauseOfLoss;
    this.ClaimStatus = ClaimStatus;
    this.LossDate = LossDate;
    this.PolicyNum = PolicyNum;
    this.RiskDescription = RiskDescription;
    this.Claimant = Claimant;
    this.ClaimMadeDate = ClaimMadeDate;
    this.Insured = Insured;
  }
}

export class Summary {
  ClaimId: number;
  EnteredDate: string;
  Status: string;
  SubCompany: string;
  ClaimAssistant: string;
  ClaimHandler: string;
  ClaimAttorney: string;
  PolicyNumber: string;
  PolicyId: number;
  InsuredName: string;
  AgencyName: string;
  EffectiveDate: string;
  PolicyType: string;
  Insured: string;
  Agency: string;
  CoverageDate: string;
  Claimant: string;
  Tpa: string;
  ClaimProfessional: string;
}

export class PolicyInformation {
  PolicyNumber: string;
  PolicyId: number;
  PolicyTxnId: number;
  PolicyTxnNumber: number;
  //  LOB: string;
  PolicyYear: number;
  PolicyTerm: string;
  State: string;
  IsMultiLob: boolean;
  TransactionType: string;
  Status: string;
  Underwriter: string;
  Reinsured: string;
  UnderwriterAssistant: string;
}

export class AgencyInformation {
  PrimaryAgency: AgencyAddress;
  MailingAgency: AgencyAddress;
  BillingAgency: AgencyAddress;
  Agent: AgencyAddress;
}
export class AgencyAddress {
  Address?: string;
  City?: string;
  State?: string;
  ZipCode?: string;
  County?: string;
  PhoneNo?: string;
  Name?: string;
  Email?: string;
}

export class InsuredInformation {
  InsuredName: string;
  EntityNo: string;
  EntityType: string;
  Status: string;
  Address: string;
  fullAddress: string;
  County: string;
  PrimaryContact: Contact;
  SecondryContact: Contact;
}

export class Contact {
  InsuredName: string;
  Address?: string;
  PhoneNo: string;
  Email: string;
}

export interface AircraftDetails {
  NameCode: string;
  Registration: string;
  SerialNumber: string;
  FleetName: string;
  ClassType: string;
  AircraftType: string;
  Make: string;
  Model: string;
  Country: string;
  YearBuild: string;
  Currency: string;
  Value: number;
  CslCurrency: string;
  CslValue: number;
}

export class RiskInformation {
  Risk: string;
  CovType: string;
  LimitCurrencyCode: string;
  CurrencyOfDeductible: string;
  PerOccurDed: number;
  EffectiveDate: string;
  PolicyAircraftId: number;
  ReinsuranceFAC: string;
  RetroDate?: string;
  LOBDescription: string;
  AggregateLimit?: number;

  OverrideLimits?: boolean;
  PerOccurLimit?: number;
  OriginalPerOccurLimit?: number;
  DefensePerOccurLimit?: number;
  OverrideDeductible?: boolean;
  PerDeductLimit?: number;
  DefenseAggregateLimit?: number;
  OriginalPerOccurDed?: number;
  Incurred?: number;
  IncurredAgainstLimit?: number;
  LOBId?: number;
  UWLOBId?: number;
  UWCovId?: number;
}

export class CashPayor {
  ContactId: number;
  FileAsName: string;
  IsDefault: boolean;
}

export class ClaimEvent {
  ClaimEventDesc: string;
  ClaimEventId: number;
  ClaimEventName: string;
  ClaimEventTypeId: number;
  FromDate: string;
  ToDate: string;
  CatCode: string;
  // constructor(ClaimEventDesc: string, ClaimEventId: number, ClaimEventName: string, ClaimEventTypeId: number,
  //   FromDate: string, ToDate: string, CatCode: string) {
  //   if(isEmpty(ClaimEventDesc) || isNaN(ClaimEventId) || isEmpty(ClaimEventName) || isNaN(ClaimEventTypeId) || isEmpty(FromDate) || isEmpty(ToDate) || isEmpty(CatCode)  ) {
  //     throw new Error('Invalid Claim Event.');
  //   }
  //   this.ClaimEventDesc = ClaimEventDesc;
  //   this.ClaimEventId = ClaimEventId;
  //   this.ClaimEventName = ClaimEventName;
  //   this.ClaimEventTypeId = ClaimEventTypeId;
  //   this.FromDate = FromDate;
  //   this.ToDate = ToDate;
  //   this.CatCode = CatCode
  // }
}

export class ClaimSetupReserve {
  LossDate: string;
  PolicyTxnId: number;
  constructor(getClaimApiData: GetClaimResponse) {
    if (getClaimApiData && getClaimApiData != null) {
      if (has(getClaimApiData, 'LossDate')) {
        this.LossDate = getClaimApiData.LossDate;
      }
      if (
        has(getClaimApiData, 'AttachedPolicyInfo') &&
        has(getClaimApiData.AttachedPolicyInfo, 'PolicyTxnId')
      ) {
        this.PolicyTxnId = getClaimApiData.AttachedPolicyInfo.PolicyTxnId;
      }
    }
  }
}
export class ClaimGeneralInfo {
  ClaimTitle: string;
  LossCity: string;
  LossDate: string;
  ReportedType: number;
  ReportedBy: number;
  ReportedDate: string;
  CoverageDate: string;
  FacPlacement: boolean;
  LossDescription: string;
  ClaimAttorney: string;
  ClaimHandler: any;
  ClaimStatus: string;
  NatureOfDamage: number;
  CauseofLoss: number;
  Countries: string;
  StateOrProvinces: number;
  LossCounty: number;
  AccidentYear: number;
  AltClaimNumber: string;
  ClaimEntryTypes: number;
  ClaimMade: string;
  CaseTypes: number;
  ClaimSeverities: number;
  ClaimClosedReasons: number;
  CloseDate: string;
  LobIds: Array<number> = [];
  EffectiveDate: string;
  ClaimNote: string;
  SubDisciplines: number;
  Disciplines: number;
  CaseSubTypes: number;
  AllowChangesCloseClaim: boolean;
  claimProcedureText: string;
  constructor(getClaimApiData: GetClaimResponse) {
    if (getClaimApiData && getClaimApiData != null) {
      if (has(getClaimApiData, 'SubDisciplineId')) {
        this.SubDisciplines = getClaimApiData.SubDisciplineId;
      }
      if (has(getClaimApiData, 'DisciplineId')) {
        this.Disciplines = getClaimApiData.DisciplineId;
      }
      if (has(getClaimApiData, 'CaseSubTypeId')) {
        this.CaseSubTypes = getClaimApiData.CaseSubTypeId;
      }
      if (has(getClaimApiData, 'ClaimTitle')) {
        this.ClaimTitle = getClaimApiData.ClaimTitle;
      }
      if (has(getClaimApiData, 'PrimaryCauseofLoss')) {
        this.CauseofLoss = getClaimApiData.PrimaryCauseofLoss;
      }
      if (has(getClaimApiData, 'LossCountry')) {
        this.Countries = getClaimApiData.LossCountry;
      }
      if (has(getClaimApiData, 'LossState')) {
        this.StateOrProvinces = getClaimApiData.LossState;
      }
      if (has(getClaimApiData, 'LossCity')) {
        this.LossCity = getClaimApiData.LossCity;
      }

      if (has(getClaimApiData, 'LossDate')) {
        this.LossDate = getClaimApiData.LossDate;
      }
      if (has(getClaimApiData, 'ReportedType')) {
        this.ClaimEntryTypes = getClaimApiData.ReportedType;
      }

      if (has(getClaimApiData, 'ReportedBy')) {
        this.ReportedBy = getClaimApiData.ReportedBy;
      }
      if (has(getClaimApiData, 'ReportedDate')) {
        this.ReportedDate = getClaimApiData.ReportedDate;
      }
      if (has(getClaimApiData, 'CoverageDate')) {
        this.CoverageDate = getClaimApiData.CoverageDate;
      }

      if (has(getClaimApiData, 'FacPlacement')) {
        this.FacPlacement = getClaimApiData.FacPlacement;
      }

      if (has(getClaimApiData, 'LossDescription')) {
        this.LossDescription = getClaimApiData.LossDescription;
      }

      if (has(getClaimApiData, 'ClaimAttorney')) {
        this.ClaimAttorney = getClaimApiData.ClaimAttorney;
      }

      if (has(getClaimApiData, 'NatureofDamages')) {
        this.NatureOfDamage = getClaimApiData.NatureofDamages;
      }

      if (has(getClaimApiData, 'ClaimHandler')) {
        this.ClaimHandler = getClaimApiData.ClaimHandler;
      }

      if (has(getClaimApiData, 'CaseTypeId')) {
        this.CaseTypes = getClaimApiData.CaseTypeId;
      }

      if (has(getClaimApiData, 'LossCounty')) {
        this.LossCounty = getClaimApiData.LossCounty;
      }

      if (has(getClaimApiData, 'ClaimSeverityId')) {
        this.ClaimSeverities = getClaimApiData.ClaimSeverityId;
      }

      if (has(getClaimApiData, 'ClaimMade')) {
        this.ClaimMade = getClaimApiData.ClaimMade;
      }
      if (has(getClaimApiData, 'AltClaimNumber')) {
        this.AltClaimNumber = getClaimApiData.AltClaimNumber;
      }

      if (has(getClaimApiData, 'AccidentYear')) {
        this.AccidentYear = getClaimApiData.AccidentYear;
      }

      if (has(getClaimApiData, 'ClaimStatus')) {
        this.ClaimStatus = getClaimApiData.ClaimStatus;
      }
      if (has(getClaimApiData, 'ClaimCloseReasonId')) {
        this.ClaimClosedReasons = getClaimApiData.ClaimCloseReasonId;
      } else {
        this.ClaimClosedReasons = 0;
      }
      if (has(getClaimApiData, 'DateClosed')) {
        this.CloseDate = getClaimApiData.DateClosed;
      }
      if (has(getClaimApiData, 'ClaimCloseNote')) {
        this.ClaimNote = getClaimApiData.ClaimCloseNote;
      }
      if (has(getClaimApiData, 'AttachedPolicyInfo')) {
        if (has(getClaimApiData.AttachedPolicyInfo, 'EffectiveDate')) {
          this.EffectiveDate = getClaimApiData.AttachedPolicyInfo.EffectiveDate;
        }
      }
      if (has(getClaimApiData, 'AllowChangesCloseClaim')) {
        this.AllowChangesCloseClaim = getClaimApiData.AllowChangesCloseClaim;
      }
      if (has(getClaimApiData, 'CoverageCollection')) {
        if (getClaimApiData.CoverageCollection.length) {
          getClaimApiData.CoverageCollection.forEach(cov => {
            if (has(cov, 'LOBId')) {
              this.LobIds.push(cov.LOBId);
              this.LobIds = this.setUniqueId(this.LobIds);
            }
          });
        } else {
          if (
            has(getClaimApiData, 'AttachedPolicyInfo') &&
            has(getClaimApiData.AttachedPolicyInfo, 'LOBId')
          ) {
            this.LobIds.push(getClaimApiData.AttachedPolicyInfo.LOBId);
          }
        }
      }
      if (has(getClaimApiData, 'ClaimProcedureText')) {
        this.claimProcedureText = getClaimApiData.ClaimProcedureText;
      }
    }
  }

  setUniqueId(lobIds) {
    return lobIds
      .map(item => item)
      .filter((value, index, self) => self.indexOf(value) === index);
  }
}

export class ClaimNotesInfo {
  ClaimDataElementCollection: ClaimDataElementCollection[];
  constructor(getClaimApiData) {
    if (has(getClaimApiData, 'ClaimDataElementCollection')) {
      this.ClaimDataElementCollection =
        getClaimApiData.ClaimDataElementCollection;
    }
  }
}
