import { Component, OnInit } from '@angular/core';
import { DialogConfig } from 'libs/framwork/dialog/src/lib/dialog-config';
import { DialogRef } from 'libs/framwork/dialog/src/lib/dialog-ref';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pg-alert-dialog',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.css']
})
export class AlertContainerComponent implements OnInit {
  parentComponentData: string;
  formGroup: UntypedFormGroup;

  dialogSetting: string;
  constructor(
    public dialogData: DialogConfig,
    private dialogRef: DialogRef,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.parentComponentData = this.dialogData.data.value;
  }

  onClose() {
    this.dialogRef.close(true);
  }

  onSave() {
    this.dialogRef.close(false);
  }
}
