import { Accordion } from '@tmhcc-ng-lib/framwork/models';

export interface ClaimNewPolicy {
  claimPoliySelectPageData: {
    EntitySearchData: EntitySearchData;
    ClaimSummaryData: ClaimSummaryData;
    SelectedRiskData: Accordion[];
    SelectedCoverageData: SelectedCoverageData[];
  };
  claimPolicyInformationPageData: {
    CauseOfLossData: CauseOfLossData;
    AdditionalClaimData: AdditionalClaimData;
  };
}

export interface EntitySearchData {
  PolicyNum: number;
  InsuredContact: string;
  AircraftRegistration: string;
  LossDate: Date;
  SubCompanyCode: Object;
}

export interface ClaimSummaryData {
  PolicyNum: number;
  PolicyEffDate: Date;
  InsuredName: string;
  PolicyType?: string;
  Agency?: string;
  PolicyId?: string;
}

export interface SelectedCoverageData {
  riskName: string;
  CovDescription: string;
  GrossLimit: string;
  PerOccurDed: string;
  CovFromDate: Date;
  LOBId?: number;
}

export interface CauseOfLossData {
  claimTitle: string;
  primaryCauseofLoss: Object;
  Countries: Object;
  StateOrProvinces: Object;
  lossCity: string;
  lossDate: Date;
  reportedType: Object;
  reportedBy: Object;
  reportedDate: Date;
  coverageDate: Date;
  overrideCoverage: boolean;
  lossDescription: string;
}

export interface AdditionalClaimData {
  phaseofFlight: Object;
  serverity: Object;
  profession: Object;
  disciplineIntreset: Object;
  subDisciplineIntreset: Object;
  groupName: Object;
}
