import { Pipe, PipeTransform } from '@angular/core';
import {
  CurrencyService,
  CurrencyTypes
} from '@tmhcc-ng-lib/framwork/services';

@Pipe({
  name: 'hccCurrency'
})
export class HccCurrencyPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(value: any, currencyType: CurrencyTypes): any {
    return this.currencyService.toCurrency(value, currencyType);
  }
}
