import { Injectable } from "@angular/core";
@Injectable()
export class DialogConfig<D = any> {
  data?: D;
  modalSetting?: {
    hideHeader?: boolean;
    hideFooter?: boolean;
    title?: string;
    hideHeaderX?: boolean; // header X button;
    hideSave?: boolean;
    dialogSize?: string;
    closeOnOutsideClick?: boolean;
    SaveButton?: string;
    CancelButton?: string;
  };
}
