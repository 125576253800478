import { FieldTypes } from './field-types';

export interface FieldInfo {
  fieldName: string;
  label: string;
  labelLength?: number;
  showLabel?: boolean;
  labelOnly?: boolean;
  fieldType?: FieldTypes;
  required?: boolean;
  fieldLength?: number;
  minChar?: number;
  maxChar?: number;
  decimal?: number;
  pattern?: string;
  validations?: any;
  currencyType?: string;
  allowDash?: boolean;
  hidden?: boolean;
  class?: string;
  style?: string;
  value?: any;
}
