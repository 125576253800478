import {
  Directive,
  forwardRef,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextFormElement } from '../text/text-form-element';

@Directive({
  selector: 'select[acSelectBox]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBoxDirective),
      multi: true
    }
  ]
})
export class SelectBoxDirective extends TextFormElement {
  @HostListener('change', ['$event'])
  onchange(event) {
    this.change(event.target.value);
  }

  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute('updateEvent') protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
}
