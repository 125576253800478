import { Moment } from '@tmhcc-ng-lib/framwork/services';

export class FormsEcomodel {
  EndorsementForms: EndorsementForms;
}
export class ApproveResponse {
  Status?: string;
}
export class EndorsementForms {
  PolicyFormId?: number;
  FormCode?: number;
  FormName?: string;
  FormEff?: Moment;
  IsCustomEntry?: boolean;
  Version?: string;
  CountryCode?: string;
  Rated?: boolean;
  AllowMultiples?: boolean;
  AddDate?: Date;
  AttachedAs?: string;
  ICMEndorseSeqNum?: number;
  IsChanged?: boolean;
  UWFormId?: number;
  PremTypeCode?: string;
  PremEntryTypeId?: number;
  FormText?: string;
  IsAdded?: boolean;
  IsUpdated?: boolean;
  IsDeleted?: boolean;
  EndorseSeqNum?: number;
}

export class AddOptionalEndorsementForms {
  IsMultiRisk: boolean;
  PolicyTxnId: Number;
  OptionalEndorsementForms: OptionalEndorsementForms[];
  CreateActivityLogItem: CreateActivityLogItems;
  PolicyDataElement?: PolicyDataElement;
}
export class PolicyDataElement {
  ElementUpdateRequests: ElementUpdateRequestObj[];
}
export class ElementUpdateRequestObj {
  DataGroupId?: number;
  DataGroupName?: string;
  IsArray?: boolean;
  ElementId?: number;
  ElementName: string;
  ResponseType: string;
  FreeTextValue: string;
  BooleanValue: boolean;
}
export class AddOptionalEndorsementResponse {
  IsSuccessful?: boolean;
}
export class CreateActivityLogItems {
  PolicyTxnId?: number;
  ActivityType?: string;
  ActivityDate?: string;
  Description?: string;
  LoggedInUser?: string;
  Amount?: number;
}
export class OptionalEndorsementForms {
  PolicyFormId?: number;
  FormCode?: string;
  IsCustomEntry?: boolean;
  IsChanged?: boolean;
  UWFormId?: number;
  ContactId?: number;
  CustomFields?: CustomFields;
  IsAdded?: boolean;
  IsUpdated?: boolean;
  IsDeleted?: boolean;
}
export class CustomFields {}

export class AvailableForms {
  PolicyFormId?: number;
  FormCode?: number;
  FormName?: string;
  FormEff?: Moment;
  IsCustomEntry?: boolean;
  Version?: number;
  CountryCode?: string;
  Rated?: boolean;
  AllowMultiples?: boolean;
  AddDate?: Moment;
  EndorseSeqNum?: number;
  ICMEndorseSeqNum?: number;
  IsChanged?: boolean;
  UWFormId: number;
  IsAdded: boolean;
  IsUpdated: boolean;
  IsDeleted: boolean;
}
