import { BaseContentService } from './base-content-service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { Injectable } from '@angular/core';
import { ClaimsSearchLabels } from './def/claim-search-content';
import { Errors } from './def/common-content';

@Injectable()
export class ClaimSearchContentService extends BaseContentService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  private labels: ClaimsSearchLabels;

  getClaimSearchLabels() {
    if (!this.labels) {
      this.labels = this.staticLabels;
    }
    return this.labels;
  }

  private readonly staticLabels: ClaimsSearchLabels = {
    ClaimSearchForm: {
      ClaimNumber: 'Claim No.',
      PolicyNumber: 'Policy No.',
      InsuredName: 'Insured Name',
      AircraftRegistrationNumber: 'Aircraft Registration No.',
      LossDate: 'Loss Date',
      SubCompanies: 'Sub-Company',
      Status: 'Status'
    },
    ClaimSearchResultGrid: {
      ClaimNumber: 'Claim No.',
      ClaimTitle: 'Claim Title',
      PolicyNumber: 'Policy No.',
      InsuredName: 'Insured',
      Status: 'Status',
      LossDate: 'Loss Date',
      ClaimHandler: 'Claim Handler',
      ClaimId: 'Claim Id'
    },
    commonMessages: {
      dataNotFound: 'No data found'
    }
  };
}
