import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreInputsModule } from '@tmhcc-ng-lib/framwork/core-inputs';
import { DebComboboxComponent } from './deb-combobox/deb-combobox.component';
import { DebGroupComponent } from './deb-group/deb-group.component';
import { ResponseElementConverterService } from './services/response-element-converter.service';
import { RequestElementConverterService } from './services/request-element-converter.service';

@NgModule({
  imports: [CommonModule, CoreInputsModule],
  declarations: [DebComboboxComponent, DebGroupComponent],
  providers: [ResponseElementConverterService, RequestElementConverterService],
  exports: [DebGroupComponent]
})
export class DataElementBuilderModule {}
