import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import { map, catchError } from 'rxjs/operators';
import {
  ElementUpdateRequests,
  PolicySubmissionTxnResponse,
  PolicyTxnRequest,
  PolicyTxnResponse,
  ManualTaxAndFeeRequest,
  ManualTaxAndFeeResponse
} from '@tmhcc-ng-lib/data-service';
import {
  policyDataElements,
  PolicySubmissionTxnData,
  PolicyTxnData,
  ManualTaxAndFeeData,
  ManualTaxAndFeeDataList,
  PolicyTxnPayload,
  PolicyData
} from '@tmhcc-ng-lib/models';
import { of, throwError } from 'rxjs';
import { has } from '@tmhcc/util';

@Injectable({
  providedIn: 'root'
})
export class QuoteStepService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }
  quotePolicyCachedData: PolicyTxnData;
  quotePolicyMomentCachedData: any;
  insuredContactCachedData: any;
  brokerContactCachedData: any;

  policySubmissionRRICached: PolicySubmissionTxnData;
  manualTaxAndFeeDataCached : ManualTaxAndFeeDataList;

  setQuotePolicyStepData(
    payload: PolicyTxnRequest,
    doRefresh = false
  ): Observable<PolicyTxnData> {
    // if (
    //   this.quotePolicyCachedData &&
    //   !doRefresh &&
    //   sessionStorage.oldPolicyTxnId === sessionStorage.policyTxnId
    // ) {
    //   return of(this.quotePolicyCachedData);
    // }
    if (!payload.PolicyTxnID) {
      return of(null);
    }
    const url = `${this.serviceBaseUrl}/GetPolicy/${payload.PolicyTxnID}`;
    return this.get<PolicyTxnResponse>(url).pipe(
      map(data => this.mapToQuotePolicyDataStep(data)),
      catchError(this.handleError)
    );
  }

  setPolicyData() {
    this.quotePolicyCachedData = null;
  }

  getInsuredData(Id: number, doRefresh = false): Observable<PolicyTxnData> {
    if (
      this.insuredContactCachedData &&
      !doRefresh &&
      sessionStorage.oldInsuredContactId === sessionStorage.insuredContactId
    ) {
      return of(this.insuredContactCachedData);
    }
    const url = `${this.serviceBaseUrl}/GetContact/${Id}`;
    return this.get<PolicyTxnResponse>(url).pipe(
      map(data => {
        this.insuredContactCachedData = data;
        return this.insuredContactCachedData;
      }),
      catchError(this.handleError)
    );
  }

  getBrokerData(Id: number, doRefresh = false): Observable<PolicyTxnData> {
    // if (
    //   this.brokerContactCachedData &&
    //   !doRefresh &&
    //   sessionStorage.oldBrokerContactId === sessionStorage.brokerContactId
    // ) {
    //   return of(this.brokerContactCachedData);
    // }
    const url = `${this.serviceBaseUrl}/GetContact/${Id}`;
    return this.get<PolicyTxnResponse>(url).pipe(
      map(data => {
        this.brokerContactCachedData = data;
        return this.brokerContactCachedData;
      }),
      catchError(this.handleError)
    );
  }

  getPolicyTypeId(policyData: PolicyTxnData) {
    if (has(policyData, 'UWQuotes[0].LOBs[0]')) {
      const lobTypeRisk = policyData.UWQuotes[0].LOBs[0].Risks.find(
        risk => risk.RiskTypeCode === 'LOB'
      );
      if (has(lobTypeRisk, 'RiskDataElements.ElementUpdateRequests[0]')) {
        const objIndex = lobTypeRisk.RiskDataElements.ElementUpdateRequests.find(
          obj => obj.ElementCode === 'RProductType'
        );

        // const objIndex: ElementUpdateRequests = policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests.find(
        //   obj => obj.ElementCode === 'RProductType'
        // );
        if (
          has(objIndex, 'SelectedDataElementResponseIds') &&
          objIndex.SelectedDataElementResponseIds[0]
        ) {
          return objIndex.SelectedDataElementResponseIds[0];
        } else {
          return null; // Need to rework, once root cause will found
          // throw new Error(
          //   'Get Policy does not have data for RProductType - UserConfigurableDataElements'
          // );
        }
      }
    }
  }
  private mapToQuotePolicyDataStep(data: PolicyTxnResponse): PolicyTxnData {
    if (!data) return;
    if (data) {
      let policyTxData = new PolicyTxnData();
      policyTxData.CountryCode = data.CountryCode;
      policyTxData.CurrencyCode = data.CurrencyCode;
      policyTxData.InsuredContactId = data.InsuredContactId;
      policyTxData.PolicyTxnId = data.PolicyTxnId;
      policyTxData.PolicyTypeDescription = data.PolicyTypeDescription;
      policyTxData.StateId = data.StateId;
      policyTxData.TxnEff = policyTxData.TxnEff;
      policyTxData.User1Id = data.User1Id;
      policyTxData.User2Id = data.User2Id;
      policyTxData.TermTypeId = data.TermTypeId;
      policyTxData.TxnBillToContactId = data.TxnBillToContactId;
      policyTxData.RateDate = data.RateDate;
      policyTxData.NextRenewalOptionId = data.NextRenewalOptionId;
      policyTxData.PolicyTypeId = data.PolicyTypeId;
      policyTxData.PolicyYear = data.PolicyYear;
      policyTxData.SubmissionTypeId = data.SubmissionTypeId;
      policyTxData.TxnPayPlanId = data.TxnPayPlanId;
      policyTxData.DateReceived = data.DateReceived;
      policyTxData.PolicyStatus = data.PolicyStatus;
      policyTxData.PolicyExp = data.PolicyExp;
      policyTxData.PolicyEff = data.PolicyEff;
      policyTxData.PolicyMovement = data.PolicyMovement;
      policyTxData.PolicyProducerContactId = data.PolicyProducerContactId;
      policyTxData.PolicyNum = data.PolicyNum;
      policyTxData.TxnPayPlanId = data.TxnPayPlanId;
      policyTxData.PolicyTxnNum = data.PolicyTxnNum;
      policyTxData.PolicyTermNum = data.PolicyTermNum;
      policyTxData.CreateActivityLogItem = data.CreateActivityLogItem;
      policyTxData.PolicyContacts = data.PolicyContacts;
      policyTxData.UWQuotes = data.UWQuotes;
      policyTxData.ProcessStatusCode = data.ProcessStatusCode;
      policyTxData.TxnStatusCode = data.TxnStatusCode;
      policyTxData.AdditionalSummaries = data.AdditionalSummaries;
      policyTxData.policyDataElements = data.policyDataElements;
      policyTxData.UpdateUserConfigurableDataElement =
        data.UpdateUserConfigurableDataElement;
      policyTxData.DMSFolderId = data.DMSFolderId;
      policyTxData.OrgFullName = data.OrgFullName;
      policyTxData.BrokerName = data.BrokerName;
      policyTxData.Insuredname = data.InsuredName;
      policyTxData.TxnTypeCodeDesc = data.TxnTypeCodeDesc;
      this.quotePolicyCachedData = policyTxData; // Caching data
      return policyTxData;
    }
  }

  public getSelectedQuoteId() {
    let quotes = this.quotePolicyCachedData.policyDataElements
      .ElementUpdateRequests;
    let selectedQuote = quotes.filter(
      data => data.ElementCode === 'SelectedQuote'
    );
    return selectedQuote[0]['IntegerValue'];
  }

  private handleError(err) {
    return throwError(err);
  }

  setPolicyMovementData(
    payload: PolicyTxnRequest,
    doRefresh = false
  ): Observable<any> {
    const url = `${this.serviceBaseUrl}/GetPolicyMovement/${payload.PolicyTxnID}`;
    return this.get<any>(url).pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  getPolicySubmissionRRIData(
    payload: PolicyTxnRequest,
    doRefresh = false
  ): Observable<PolicySubmissionTxnData> {
    if (!payload.PolicyTxnID) {
      return of(null);
    }
    //debugger;
    if (this.policySubmissionRRICached && !doRefresh) {
      return of(this.policySubmissionRRICached);
    }

    const url = `${this.serviceBaseUrl}/GetPolicySubmissionRRI/${payload.PolicyTxnID}`;
    return this.get<PolicySubmissionTxnResponse>(url).pipe(
      map(data => this.mapToGetPolicySubmissionStep(data)),
      catchError(this.handleError)
    );
  }

  /** Get Policy details by Id
   * 
   * @returns 
   */
  getPolicyById(payload: PolicyTxnPayload,
    doRefresh = false): Observable<any> {
    if (!payload.policyTxnID) {
      return of(null);
    }
    //debugger;
    // if (this.policySubmissionRRICached && !doRefresh) {
    //   return of(this.policySubmissionRRICached);
    // }

    const url = `${this.serviceBaseUrl}/api/v1.0/policies/${payload.policyTxnID}`;
    return this.get<any>(url).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private mapToGetPolicySubmissionStep(
    data: PolicySubmissionTxnResponse
  ): PolicySubmissionTxnData {
    if (!data) return;
    if (data) {
      let policyTxData = new PolicySubmissionTxnData();
      policyTxData.PolicyTxnId = data.PolicyTxnId;
      policyTxData.PolicyNum = data.PolicyNum;
      policyTxData.PolicyTypeId = data.PolicyTypeId;
      policyTxData.PolicyType = data.PolicyType;
      policyTxData.PolicyStatus = data.PolicyStatus;
      policyTxData.StatusOfPolicy = data.StatusOfPolicy;
      policyTxData.ProcessStatusCode = data.ProcessStatusCode;
      policyTxData.ProcessStatusDescription = data.ProcessStatusDescription;
      policyTxData.TxnStatusCode = data.TxnStatusCode;
      policyTxData.TxnTypeCode = data.TxnTypeCode;
      policyTxData.TxnTypeCodeDesc = data.TxnTypeCodeDesc;
      policyTxData.InsuredContactId = data.InsuredContactId;
      policyTxData.InsuredContactName = data.InsuredContactName;
      policyTxData.PolicyProducerContactId = data.PolicyProducerContactId;
      policyTxData.PolicyProducerName = data.PolicyProducerName;
      policyTxData.PortalStatusId = data.PortalStatusId;
      policyTxData.SelectedQuoteId = data.SelectedQuoteId;
      policyTxData.PortalStatus = data.PortalStatus;
      policyTxData.DataelementResponseID = data.DataElementResponseId;
      policyTxData.ProductDescription = data.ProductDescription;
      this.policySubmissionRRICached = policyTxData;
      return policyTxData;
    }
  }

  getManualTaxesAndFees(
    payload: ManualTaxAndFeeRequest,
    doRefresh = false
  ): Observable<ManualTaxAndFeeDataList> {
    if (!payload.PolicyTxnId) {
      return of(null);
    }
    //debugger;
    if(this.manualTaxAndFeeDataCached && !doRefresh)
    {
      return of(this.manualTaxAndFeeDataCached);
    }

    // const url = `${this.serviceBaseUrl}/GetPolicySubmissionRRI/${payload.PolicyTxnID}`;
    // return this.get<PolicySubmissionTxnResponse>(url).pipe(
    //   map(data => this.mapToGetPolicySubmissionStep(data)),
    //   catchError(this.handleError)
    //);

    // const url = `${this.serviceBaseUrl}/ManualTaxAndFeeList/{SubCompanyCode}/{PolicyTxnId}/{UWQuoteId}/{LobId}`

    const url = `${this.serviceBaseUrl}/ManualTaxAndFeeList/${payload.SubCompanyCode}/${payload.PolicyTxnId}/${payload.UWQuoteId}/${payload.LobId}`;

    return this.get<ManualTaxAndFeeResponse>(url).pipe(
      map(data => this.mapToGetManualTaxAndFeeData(data)),
      catchError(this.handleError)
    );
  }

  private mapToGetManualTaxAndFeeData(
    data: ManualTaxAndFeeResponse
  ): ManualTaxAndFeeDataList {
    //debugger;
    if (!data) return;
    if (data) {
      let TaxAndFeeDataList = new ManualTaxAndFeeDataList();
      TaxAndFeeDataList.data = data.TaxFeeList;

      this.manualTaxAndFeeDataCached = TaxAndFeeDataList;
      return TaxAndFeeDataList;
    }
  }
}
