import { Observable } from 'rxjs';
import {
  CanDeactivate,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  DialogService,
  DialogConfig,
  DialogSize
} from '@tmhcc-ng-lib/framwork/dialog';
import { DialogContainerComponent } from './DialogContainer/dialog-container.component';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import { AllowedRoute } from 'libs/product-contamination/layout/top-stepper/navigation-def';
// import { AllowedRoute } from "@tmhcc-ng-lib/eco/src/lib/shared/page-header/page-header-navigation/page-header-navigation-def";

// export enum AllowedRoute {
//   GeneralInfo = '/info',
//   Events = '/events',
//   Quote = '/quote',
//   Bind = '/bind',
//   Dashboard = '/dashboard',
//   Subjectivity = '/subjectivity',
//   Document = '/document',
//   Forms = '/policy-forms',
//   Summary = '/bind-summary'
// }

export interface ComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class SaveChangeGaurd implements CanDeactivate<ComponentDeactivate> {
  formGroup: UntypedFormGroup;
  hideHeader: boolean;
  hideFooter: boolean;
  hideSave: boolean;
  hideHeaderX: boolean;
  titleText: string;
  backwardRoute: string;
  tabClicked: boolean;
  tabUrl: string;

  constructor(
    private dialogService: DialogService,
    private dialogConfig: DialogConfig,
    private contentService: CommonContentService,
    public router: Router
  ) {
    this.tabClicked = false;
    this.tabUrl = '';
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // alert(event.url);
        // Show loading indicator
        this.tabClicked = false;
        this.backwardRoute = event.url;
        //this.getBackwardRoute(event.url);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);

        sessionStorage.visitedStep = 0;
        throw event.error;
      }
    });
  }

  canDeactivate() {
    if (this.formGroup && this.formGroup.dirty) {
      return this.DeactivateRoute();
    }
    return true;
  }

  setFormGroup(formGroup: UntypedFormGroup) {
    this.tabClicked = false;
    this.tabUrl = '';
    this.formGroup = formGroup;
    const path = this.router.url;
    this.getBackwardRoute(path);
  }

  setRoute(url: string) {
    this.tabClicked = true;
    this.tabUrl = url;
  }

  getBackwardRoute(path) {
    this.tabClicked = false;
    if (path.includes(AllowedRoute.BindForm)) {
      this.backwardRoute = AllowedRoute.Location;
    } else if (path.includes(AllowedRoute.BindSubjectivities)) {
      this.backwardRoute = AllowedRoute.BindForm;
    } else if (path.includes(AllowedRoute.QuoteSubjectivities)) {
      this.backwardRoute = AllowedRoute.UCMQuoteInfo;
    } else if (path.includes(AllowedRoute.Dashboard)) {
      this.backwardRoute = AllowedRoute.Dashboard;
    }
  }
  // routeNevigation(path) {
  //   this.tabClicked = false;
  //   if (path.includes(AllowedRoute.BindForm)) {
  //     this.backwardRoute = AllowedRoute.Location;
  //   } else if (path.includes(AllowedRoute.BindSubjectivities)) {
  //     this.backwardRoute = AllowedRoute.BindForm;
  //   } else if (path.includes(AllowedRoute.QuoteSubjectivities)) {
  //     this.backwardRoute = AllowedRoute.QuoteInfo;
  //   } else {
  //     this.backwardRoute = AllowedRoute.Dashboard;
  //   }
  // }

  DeactivateRoute(): Observable<boolean> | boolean {
    let content = {
      value: this.contentService.getLabels().dirtyFormMsg
    };
    this.dialogConfig.data = content;

    this.dialogConfig.modalSetting = {
      title: this.titleText,
      hideHeader: this.hideHeader,
      hideFooter: true,
      hideSave: this.hideSave,
      hideHeaderX: this.hideHeaderX,
      SaveButton: 'Yes',
      CancelButton: 'No',
      dialogSize: DialogSize.SM
    };
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
      } else {
        this.formGroup.markAsPristine();
        if (!this.tabClicked && this.backwardRoute) {
          this.router.navigate([this.backwardRoute]);
        } else if (this.tabClicked && this.tabUrl) {
          this.router.navigate([this.tabUrl]);
        } else {
          sessionStorage.removeItem('selectedUwQuoteId');
          this.router.navigate([AllowedRoute.Dashboard]);
        }
      }
    });
    return false;
  }
}
