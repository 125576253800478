import { Component } from '@angular/core';
import { InputBase } from '../input-base';

@Component({
  selector: 'ac-custom-link',
  templateUrl: './custom-link.component.html',
  styleUrls: ['./custom-link.component.scss']
})
export class CustomLinkComponent extends InputBase<string> {
  constructor() {
    super();
  }

  change(event) {
    if (event) {
      this.frmGroup.get(this.fieldInfo.fieldName).setValue(event);
    }
  }
}
