import {
  Component,
  forwardRef,
  ViewChild,
  HostListener,
  DoCheck,
  Input
} from '@angular/core';
import { InputBase } from '../input-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { InputConfig, Datepicker } from '@tmhcc-ng-lib/framwork/form-inputs';
import { has } from '@tmhcc/util';

@Component({
  selector: 'hcc-datepicker-input',
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent extends InputBase<string> implements DoCheck {
  datepickerConfig: Datepicker;
  toolTipText = '';
  @ViewChild('dp', { static: false })
  datepicker: BsDatepickerDirective;
  // @Input() isDatePicker: boolean = true;
  @HostListener('window:scroll')
  onScrollEvent() {
    if (
      this.InputConfig != null &&
      this.InputConfig.hasOwnProperty('datePickerConfig') &&
      this.InputConfig.datePickerConfig.hasOwnProperty('hideOnScroll')
    ) {
      if (this.InputConfig.datePickerConfig.hideOnScroll) {
        this.datepicker.hide();
      }
    }
  }

  ngDoCheck() {
    if (
      this.InputConfig.hasOwnProperty('datePickerConfig') &&
      this.InputConfig.datePickerConfig.hasOwnProperty('isCalanderRequired')
    ) {
      if (
        !this.InputConfig.datePickerConfig.isCalanderRequired &&
        this.datepicker
      ) {
        this.datepicker.hide();
      }
    }
  }

  onChange(event) {
    if (event) {
      this.frmGroup.get(this.fieldInfo.fieldName).setValue(event);
    }
  }

  constructor() {
    super();
  }

  getDatePickerConfig() {
    if (has(this.fieldInfo, 'label') && this.fieldInfo.label.length > 30) {
      this.toolTipText = this.fieldInfo.label;
    }
    this.datepickerConfig = {
      isAnimated: this.InputConfig.datePickerConfig.isAnimated,
      adaptivePosition: this.InputConfig.datePickerConfig.adaptivePosition,
      dateInputFormat: this.InputConfig.datePickerConfig.dateInputFormat,
      returnFocusToInput: this.InputConfig.datePickerConfig.returnFocusToInput,
      selectFromOtherMonth: this.InputConfig.datePickerConfig
        .selectFromOtherMonth,
      containerClass:
        this.InputConfig.datePickerConfig.containerClass || 'theme-dark-blue',
      showWeekNumbers: false
    };
    return this.datepickerConfig;
  }
}
