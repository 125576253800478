export interface SaveClaim {
  ClaimTitle: string;
  CauseOfLossId?: number;
  LossStateId: number;
  LossCountryCode: string;
  LossCity: string;
  LossDate: string;
  ReportedTypeId: number;
  ReportedBy?: number;
  ReportedDate: string;
  CoverageDate?: string;
  OverrideCoverageDate: boolean;
  LossDescription: string;
  SubCompanyCode: string;
  PhaseOfFlight?: number;
  SeverityId?: number;
  ProfessionId?: number;
  DisciplineId?: number;
  SecondaryCauseOfLoss: string;
  SubDisciplineId?: number;
  GroupName?: number;
  ClaimMadeDate?: string;
  PolicyTxnId: number;
  ClaimHandler: number;
  ClaimsAttorney: number;
  InternalAdjuster?: number;
  CaseTypeId: number;
  CaseSubTypeId: number;
}
export interface SaveRelatedClaims {
  ClaimId: number;
  SubCompanyCode: string;
  RelatedClaim: AssociatedClaim[];
}
export interface AssociatedClaim {
  ClaimAssociationId: number;
  AssociatedClaimId: number;
}
export interface Reserve {
  CovTypeId: number;
  ReserveAmt: string; //Decimal
  ReserveTypeId: number;
}

export interface ClaimData {
  DataGroupId: number;
  DataGroupName: string;
  IsArray: boolean;
  ElementId: number;
  ElementName: string;
  ElementCode: string;
  ResponseType: string;
  SelectedDataElementResponseIds: number[];
  DecimalValue?: string; //decimal?
  MoneyValue?: string; //decimal?
  IntegerValue?: number;
  DateValue?: string;
  FreeTextValue: string;
  TextValue: string;
  BooleanValue: boolean;
  RowNum?: number;
}

export class Coverge {
  UWCovId: number;
  AddDate?: string;
  CovFromDate?: string;
  CovToDate?: string;
  DeleteDate?: string;
  CovTypeId?: number;
  CovTypeCode: string;
  PerOccurLimit?: string; //decimal?
  AggregateLimit?: string; //decimal?
  PerOccurDed?: string; //decimal?
  PerOccurDedRate?: string; //decimal?
  AggregateDed?: string; //decimal?
  PremiumAmount?: string; //decimal?
  LiabilityLimit?: string; //decimal?
  BenchMarkPremiumAmount?: string; //decimal?
  CovRetroDate?: string; //DateTime?
  PremEntryTypeId?: number; //int?
  LimitCurrencyCode: string;
  DedCurrencyCode: string;
  RiskTypeCode: string;
  UWRiskIdGuid: number; //Guid
  SecondaryPerOccurDed?: string; //decimal?
  SecondaryPerOccurDedRate?: string; //decimal?
  AttachmentPoint?: string; //decimal?
  CovDescription: string;
  GrossLimit?: string; //decimal?
  GrossPremium?: string; //decimal?
  WrittenPrem: string; //decimal
}
