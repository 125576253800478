import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPopupAlertComponent } from './custom-popup-alert/custom-popup-alert.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
@NgModule({
  imports: [CommonModule, ModalModule, ModalModule.forRoot()],
  declarations: [CustomPopupAlertComponent, ConfirmationDialogComponent],
  exports: [CustomPopupAlertComponent, ConfirmationDialogComponent]
})
export class CustomPopupAlertModule {}
