export interface Errors {
  InvalidLossDate: string;
}

export interface Labels {
  welcome: string;
  searchTypeLabel: string;
  searchByLabel: string;
  quoteTableHeading: string;
  policyTableHeading: string;
  locationHeading: string;
  searchByQuotes: string;
  searchByPolicies: string;
  franchiseeHeading: string;
  quoteNumber: string;
  policyNumber: string;
  insuredName: string;
  quoteCreatedDate: string;
  creationDate: string;
  expirationDate: string;
  broker: string;
  underwriter: string;
  status: string;
  grossPremium: string;
  policyEffectiveDate: string;
  policyEffectiveDate1: string;
  searchLabel: string;
  stepFirstLbl: string;
  stepSecondLbl: string;
  stepThirdLbl: string;
  stepLastLbl: string;
  quoteHeading: string;
  insuredHeading: string;
  brokerHeading: string;
  underwriterHeading: string;
  ecoProductTypeLbl: string;
  quoteInsuredLabel: string;
  quoteAddressFieldLbl: string;
  quoteCityFieldLbl: string;
  quoteStateFieldLbl: string;
  quoteZipCodeFieldLbl: string;
  quoteCountryFieldLbl: string;
  quoteBrokerFieldLBl: string;
  quoteSeriesFieldLBL: string;
  quoteUnderwriterFieldLbl: string;
  quoteUWAssistentFieldLbl: string;
  //Event Labels
  eventType: string;
  eventDescription: string;
  eventName: string;
  eventVenue: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  eventStartDate: string;
  eventEndDate: string;
  eventLimitInsurance: string;
  eventDeductible: string;
  baseRate: string;
  windstorm: string;
  strike: string;
  earthquake: string;
  winterWeather: string;
  wildfire: string;
  csTornadoHail: string;
  civilCommotion: string;
  cyber: string;
  era: string;
  nationalMounting: string;
  limitedTerrorism: string;
  terrorism: string;
  nucBioChemTerrorism: string;
  tria: string;
  threat: string;
  communicableDisease: string;
  limitedWar: string;
  additionalPerilLabel1: string;
  additionalPerilValue1: string;
  war: string;
  nuclearHazard: string;
  additionalPerilLabel2: string;
  additionalPerilValue2: string;
  nonAppearance: string;
  bioChemTerrIncl: string;
  additionalPerilLabel3: string;
  additionalPerilValue3: string;
  eventInformationLbl: string;
  optionsLbl: string;
  downloadTemplateLbl: string;
  copyQuoteLbl: string;
  uploadTemplateLbl: string;
  addNewEventLbl: string;
  emptyTableMsg: string;
  eventTypeRequiredValidationLbl: string;
  addressRequiredValidationLbl: string;
  cityRequiredValidationLbl: string;
  countryRequiredValidationLbl: string;
  esdRequiredValidationLbl: string;
  esdPastDateValidationLbl: string;
  esdInvalidDateValidationLbl: string;
  eedRequiredValidationLbl: string;
  eedPastDateValidationLbl: string;
  eedInvalidDateValidationLbl: string;
  limitofInsRequiredValidationLbl: string;
  baseRateRequiredLbl: string;
  baseRatePatternLbl: string;
  dirtyFormMsg: string;
  dirtyQuoteVersionChkMsg: string;
  quoteSummaryHeading: string;
  quoteSummaryLimitOfInssurence: string;
  summaryPolicyExpiryDate: string;
  QuoteSummaryFillingResponsibility: string;
  QuoteSummaryTaxState: string;
  QuoteSummaryEventInformation: string;
  QuoteSummaryDownLoadQuoteHeading: string;
  QuoteSummaryDescription: string;
  QuoteExpirationDate: string;
  addEditSubjectivityLbl: string;
  addEditEndorsementsLbl: string;
  endorsementsHeadingLbl: string;
  subjectivityHeadingLbl: string;
  QuptOptionPageHeading: string;
  FillingResponsibilityLabel: string;
  TaaxStateLabel: string;
  step1Save: string;
  currencyCode: string;
  summaryTextInfo: string;
  policyExpLbl: string;
  policyEffLabl: string;
  limitOfInsurance: string;
  download: string;
  policyFormName: string;
  policyFormNumber: string;
  policyFormDelete: string;
  policyFormHeading: string;
  slectFormInputLabel: string;
  selctFornInputReqMsg: string;
  selectQuoteVersionInputLabel: string;
  insured: string;
  agency: string;
  policyperiod: string;
  premiumsummary: string;
  commission: string;
  premium: string;
  taxesandfee: string;
  back: string;
  bindpolicy: string;
  policynum: string;
  policySummary: string;
  commissionsmall: string;
  endorsement: string;
  firstLossLimitEndorsement: string;
  changewithRespecttoCalculationofLoss: string;
  carrierName: string;
  bindQuoteDescription: string;
  bindSavedStepDescription: string;
  bindSelectFieldLabel: string;
  quoteDownloadSuccessTitle: string;
  quoteDownloadSuccessDesc: string;
  quotePreventDownloadTitle: string;
  quotePreventDownloadDesc: string;
  quotePolicyTypeValidationText: string;
  quoteInsuredValidaionText: string;
  quoteAddressValidationText: string;
  quoteCityRequiredValidationText: string;
  quoteValidCityText: string;
  quoteStateValidationText: string;
  quoteZiprequiredValidationText: string;
  quoteZipMaxlengthValidationText: string;
  quoteZipMinlengthValidationText: string;
  quoteCountryValidationText: string;
  quoteBrokerValidationText: string;
  quoteUnderwriterValidationText: string;
  standardPolicyOption1Label: string;
  standardPolicyOption2Label: string;
  standardPolicyOption3Label: string;
  standardAdditionalPerlsLabel: string;
  tradeShowPolicyOption1Label: string;
  tradeShowPolicyOption2Label: string;
  tradeShowPolicyOption3Label: string;
  tradeShowPolicyOption4Label: string;
  tradeShowPolicyOption1SubLabel: string;
  tradeShowPolicyOption2SubLabel: string;
  tradeShowPolicyOption3SubLabel: string;
  tradeShowPolicyOption4SubLabel: string;
  tradeShowAdditionalPerlsLabel: string;
  copyQuoteAlertWarning: string;
  eventVenueRequiredValidationLbl: string;
  reActivate: string;
  deActivate: string;
}

export interface Messages {}

export interface OtherValues {}
