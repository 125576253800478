export enum QuoteDataElementName {
  WaitingPeriod = 'WaitingPeriod',
  RecallExp = 'RecallExp',
  RehabExp = 'RehabExp',
  LGPIncRoyalties = 'LGPIncRoyalties',
  LGPIndPeriod = 'LGPIndPeriod',
  WPVIndemPeriod = 'WPVIndemPeriod',
  PremType = 'PremType',
  RNoLocs = 'RNoLocs',
  '2YrPrepaidFactor' = '2YrPrepaidFactor',
  BasePrem = 'BasePrem',
  WPVPrem = 'WPVPrem',
  TRIARate = 'TRIARate',
  RecallExpPerLoc = 'RecallExpPerLoc',
  RehabExpPerLoc = 'RehabExpPerLoc',
  TermLength = 'TermLength',
  TotalRevenue = 'TotalRevenue',
  '2YrPrem' = '2YrPrem',
  '1YrPrem' = '1YrPrem',
  WPVSelect = 'WPVSelect',
  TRIASelect = 'TRIASelect',
  SelectedQuote = 'SelectedQuote',
  FPLPerLocLimit = 'FPLPerLocLimit',
  CorpLocations = 'CorpLocations',

  TaxFeeDscr = 'TaxFeeDscr',
  FR = 'FR',
  TaxFeeAmnt = 'TaxFeeAmnt',
  TaxState = 'TaxState'
}

export enum PolicyTxnDataElement {
  SelectedQuote = 'SelectedQuote'
}

export enum CoverageElement {
  PolicyAggr = 'PolicyAggr',
  OneYrPremium = 'OneYrPremium',
  TwoYrPremium = 'TwoYrPremium',
  InsRetention = 'InsRetention',
  ExtortionCost = 'ExtortionCost',
  CrisisConsultExp = 'CrisisConsultExp',
  LostGrossProfitLimit = 'LostGrossProfitLimit',
  IncresedCost = 'IncresedCost',
  Inoculations = 'Inoculations',
  Accidental = 'Accidental',
  Tampering = 'Tampering',
  SupContamination = 'SupContamination',
  AdversePub = 'AdversePub',
  OverallAggr = 'OverallAggr',
  PerOccLimit = 'PerOccLimit',
  // WPVIndemPeriod = ' WPVIndemPeriod',
  Deductibles = 'Deductibles',
  AccidentalPerLocation = 'AccidentalPerLocation',
  TamperingPerLocation = 'TamperingPerLocation',
  SupContaminationPerLocation = 'SupContaminationPerLocation',
  AdversePubPerLocation = 'AdversePubPerLocation'
  //--
  // FillingResponsibility = 'FillingResponsibility',
  // TaxFeeDscr1 = 'TaxFeeDscr1',
  // TaxFeeAmnt1 = 'TaxFeeAmnt1',
  // TaxFeeDscr2 = 'TaxFeeDscr2',
  // TaxFeeAmnt2 = 'TaxFeeAmnt2',
  // TaxFeeDscr3 = 'TaxFeeDscr3',
  // TaxFeeAmnt3 = 'TaxFeeAmnt3',
  // TaxFeeDscr4 = 'TaxFeeDscr4',
  // TaxFeeAmnt4 = 'TaxFeeAmnt4',
  // TaxFeeDscr5 = 'TaxFeeDscr5',
  // TaxFeeAmnt5 = 'TaxFeeAmnt5'
}
export const enum RiskType {
  FCH = 'FCH',
  LOB = 'LOB'
}

export const enum QuoteNum {
  quoteOne = 1,
  quoteTwo = 2,
  quoteThree = 3,
  quoteFour = 4,
  quoteFive = 5
}

export const enum CoverageCodes {
  RRI = 'RRI',
  ARC = 'ARC',
  XRC = 'XRC',
  MRC = 'MRC',
  ARE = 'ARE',
  PRE = 'PRE',
  XRE = 'XRE',
  MRE = 'MRE',
  LGP = 'LGP',
  ICW = 'ICW',
  IV = 'IV',
  XXC = 'XXC',
  ACO = 'ACO',
  MCO = 'MCO',
  PCO = 'PCO',
  XCO = 'XCO',
  ACC = 'ACC',
  MTA = 'MTA',
  ASC = 'ASC',
  ADP = 'ADP',
  WVA = 'WVA',
  TER = 'TER',
  RCL = 'RCL',
  REL = 'REL',
  ACL = 'ACL',
  MTL = 'MTL',
  SCL = 'SCL',
  APL = 'APL',
  FPL = 'FPL',
  WRK = 'WRK'
}

//export enum ele ['dfdf']
export enum LOBElements {
  PolicyAggr = 'PolicyAggr',
  WaitingPeriod = 'WaitingPeriod',
  InsRetention = 'InsRetention',
  LGPIncRoyalties = 'LGPIncRoyalties',
  LGPIndPeriod = 'LGPIndPeriod',
  SupContamination = 'SupContamination',
  OverallAggr = 'OverallAggr',
  WPVIndemPeriod = 'WPVIndemPeriod',
  PremType = 'PremType',
  RNoLocs = 'RNoLocs',
  TwoYrPrepaidFactor = '2YrPrepaidFactor',
  BasePrem = 'BasePrem',
  WPVPrem = 'WPVPrem',
  TRIARate = 'TRIARate',
  OneYrPremium = 'OneYrPremium',
  TwoYrPremium = 'TwoYrPremium',
  RecallExpPerLoc = 'RecallExpPerLoc',
  RehabExpPerLoc = 'RehabExpPerLoc',
  AccidentalPerLocation = 'AccidentalPerLocation',
  TamperingPerLocation = 'TamperingPerLocation',
  SupContaminationPerLocation = 'SupContaminationPerLocation',
  AdversePubPerLocation = 'AdversePubPerLocation',
  IncresedCost = 'IncresedCost',
  PerOccLimit = 'PerOccLimit',
  Deductibles = 'Deductibles',
  Inoculations = 'Inoculations',
  ActofTerrorism = 'ActofTerrorism'
}

export enum FCHElements {
  LostGrossProfitLimit = 'LostGrossProfitLimit',
  IncresedCost = 'IncresedCost',
  Inoculations = 'Inoculations',
  ExtortionCost = 'ExtortionCost',
  CrisisConsultExp = 'CrisisConsultExp',
  Accidental = 'Accidental',
  AccidentalPremium = 'AccidentalPremium',
  Tampering = 'Tampering',
  AdversePub = 'AdversePub',
  RecallExp = 'RecallExp',
  RehabExp = 'RehabExp',
  PerLocationLimit = 'PerLocationLimit',
  PerOccLimit = 'PerOccLimit',
  Deductibles = 'Deductibles',
  FranchiseePolicyLimit = 'FranchiseePolicyLimit',
  WorkplaceViolencePerLocation = 'WorkplaceViolencePerLocation'
}
