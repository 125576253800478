import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ClaimSearchGridRow, CoverageTypes } from '@tmhcc-ng-lib/models';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import {
  DeleteQuote,
  PolicyFormBuilderResponse,
  Quotes,
  Formbuilder,
  QuoteAttributes,
  Quote,
  Lobs,
  Risks,
  RiskAttributes,
  Coverages,
  FluffyDataElement,
  QuoteResult,
  CopyQuote,
  FormbuilderLOBS,
  FormbuilderRisks,
  PolicyTxnAttributes
} from './def/coverage-policies';
import { has } from '@tmhcc/util';
import {
  QuoteNum,
  CoverageCodes,
  CoverageElement,
  RiskType
} from './def/coverage-limit-data-elements';
import { InitialDataElements } from '@tmhcc-product-contamination/framwork/data-element-builder';
import { ElementName } from 'libs/framwork/data-element-builder/src/lib/utility/deb-utility.def';
import { CoverageDataElementsService } from '@tmhcc/product-contamination';

@Injectable()
export class LocationsDetailsFormBuilderService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService,
    private requestConverterService: CoverageDataElementsService
  ) {
    super(http, configService);
  }

  policySearchData: ClaimSearchGridRow[];
  quoteFormBuilderCachedData: PolicyFormBuilderResponse;
  quoteCachedData: Quote[] = [];
  quoteFormBuilderCache: Formbuilder;
  quoteAttributeCache = [];
  QuoteResultCache: QuoteResult[] = [];
  quoteCacheCoverages: Coverages[] = [];
  quoteGeneralCache: PolicyTxnAttributes;
  saveAddDeleteLocations(payload: any) {
    const url = `${this.serviceBaseUrl}/FormBuilderData/Risks`;

    return this.put<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  get GetGenralBuilder() {
    if (!this.quoteGeneralCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }

    return this.quoteGeneralCache;
  }

  checkProcessStatusCode(processStatus) {
    if (processStatus == 'C') {
      return true;
    }
    return false;
  }

  checkProcessStatusCodeCancelled() {
    let data = this.GetGenralBuilder;
    if (has(data, 'ProcessStatusCode')) {
      if (
        data.ProcessStatusCode == 'C' ||
        data.ProcessStatusCode == '*' ||
        data.ProcessStatusCode == 'I'
      ) {
        return true;
      }
    }
    return false;
  }

  get GetFormBuilder() {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }

    return this.quoteFormBuilderCache;
  }

  saveAddDeleteFranchisee(payload: any) {
    const url = `${this.serviceBaseUrl}/FormBuilderData/Lobs`;

    return this.put<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getFormBuilderData(payload: any): Observable<PolicyFormBuilderResponse> {
    const url = `${this.serviceBaseUrl}/FormBuilderData/${payload.Form}/${payload.policyTxnId}`;
    return this.get<Formbuilder>(url).pipe(
      map(data => {
        this.quoteFormBuilderCache = data;
        let riskdata = this.mapToQuotePolicyDataStep3(data);
        return riskdata;
      }),
      catchError(this.handleError)
    );
  }

  getFormBuilderLOBSData(payload: any): Observable<PolicyFormBuilderResponse> {
    const url = `${this.serviceBaseUrl}/FormBuilderData/LOBs/${payload.uWLOBId}`;
    return this.get<PolicyFormBuilderResponse>(url).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getFormBuilderRisksData(payload: any): Observable<PolicyFormBuilderResponse> {
    const url = `${this.serviceBaseUrl}/FormBuilderData/Risks/${payload.UWRiskId}`;
    return this.get<PolicyFormBuilderResponse>(url).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private mapToQuotePolicyDataStep3(data: Formbuilder): any {
    if (data) {
      this.quoteAttributeCache = [];
      let quoteData: Quote[] = [];
      let lobsData: Lobs[] = [];
      let riskData: Risks[] = [];
      if (data) {
        if (has(data['Data'][0], 'Attributes')) {
          this.quoteGeneralCache =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes'];
          quoteData =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes']['Quotes'];

          quoteData.forEach((element: Quote) => {
            let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
            if (has(this.quoteGeneralCache, 'ProcessStatusCode')) {
              element.Attributes.ProcessStatusCode = this.quoteGeneralCache.ProcessStatusCode;
            }
            riskResult.forEach((riskElement: Risks) => {
              if (riskElement.Attributes.RiskTypeCode === RiskType.FCH) {
                riskData.push(riskElement);
              }
            });

            this.quoteCachedData = quoteData;
          });
        }
      }

      return quoteData;
    }
  }

  SetFormBuilderValue(
    data: Formbuilder,
    coveragesTypes: CoverageTypes[],
    dataTypeElements: InitialDataElements[]
  ) {
    if (data) {
      this.quoteAttributeCache = [];
      let quoteData: Quote[] = [];
      let lobsData: Lobs[] = [];
      let riskData: Risks[] = [];
      if (data) {
        if (has(data['Data'][0], 'Attributes')) {
          quoteData =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes']['Quotes'];
        }
        quoteData.forEach((element: Quote) => {
          let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
          let arrayQuote = [];
          let resultQuoteData = {
            PolicyAggr: null, //
            InsRetention: null, //
            ExtortionCost: null, //
            CrisisConsultExp: null, //
            LostGrossProfitLimit: null, //
            IncresedCost: null, //
            Inoculations: null, //
            Accidental: null, //
            Tampering: null, //
            SupContamination: null, //
            AdversePub: null, //
            OverallAggr: null, //
            PerOccLimit: null, //
            Deductibles: null, //
            OneYrPremium: null,
            TwoYrPremium: null,
            WaitingPeriod: null,
            RecallExp: null,
            RehabExp: null,
            LGPIncRoyalties: null,
            LGPIndPeriod: null,
            WPVIndemPeriod: null,
            PremType: null,
            RNoLocs: null,
            TwoYrPrepaidFactor: null,
            BasePrem: null,
            WPVPrem: null,
            TRIARate: null,
            PolicyTypeId: null,
            UWQuoteId: null,
            //--
            FR: null,
            TaxFeeDscr1: null,
            TaxFeeAmnt1: null,
            TaxFeeDscr2: null,
            TaxFeeAmnt2: null,
            TaxFeeDscr3: null,
            TaxFeeAmnt3: null,
            TaxFeeDscr4: null,
            TaxFeeAmnt4: null,
            TaxFeeDscr5: null,
            TaxFeeAmnt5: null
          };
          resultQuoteData.PolicyTypeId = element.Attributes.PolicyTxnId;
          resultQuoteData.UWQuoteId = element.Attributes.UWQuoteId;
          riskResult.forEach((riskElement: Risks) => {
            //arrayQuote.push();
            let riskNestcoverages = riskElement.Attributes.Coverages;
            let dataNestElement = riskElement.Attributes.DataElements;
            if (
              dataNestElement &&
              riskElement.Attributes.RiskTypeCode === RiskType.LOB
            ) {
              dataNestElement.forEach((dataElement: FluffyDataElement) => {
                let isPresent = dataTypeElements.find(function(el) {
                  return el.ElementCode === dataElement.Attributes.ElementCode;
                });
                if (isPresent && isPresent.ResponseType === ElementName.Text) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.TextResponse;
                }
                if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Integer
                ) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.IntegerResponse;
                }
                if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.LookupList
                ) {
                  let objData = isPresent.DataElementResponses.find(function(
                    el
                  ) {
                    return (
                      el.DataElementResponseId ===
                      dataElement.Attributes.DataElementResponseId
                    );
                  });
                  objData = isPresent.DataElementResponses[0];
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    objData !== undefined ? objData : {};
                  // resultQuoteData[dataElement.Attributes.ElementCode] =
                  //   dataElement.Attributes.DataElementResponseId;
                }
              });
            }
            if (
              riskNestcoverages &&
              riskElement.Attributes.RiskTypeCode === RiskType.LOB
            ) {
              riskNestcoverages.forEach((covergeElement: Coverages) => {
                var isPresent = coveragesTypes.find(function(el) {
                  return el.CovTypeId === covergeElement.Attributes.CovTypeId;
                });
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.RRI) {
                  resultQuoteData.PolicyAggr =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  resultQuoteData.InsRetention =
                    covergeElement.Attributes.PerOccurDed;
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.ASC) {
                  resultQuoteData.SupContamination =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.WVA) {
                  resultQuoteData.OverallAggr =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  resultQuoteData.PerOccLimit =
                    covergeElement.Attributes.PerOccurLimit;
                  resultQuoteData.Deductibles =
                    covergeElement.Attributes.PerOccurDed;
                }
              });
            } else if (
              riskNestcoverages &&
              riskElement.Attributes.RiskTypeCode === RiskType.FCH
            ) {
              riskNestcoverages.forEach((covergeElement: Coverages) => {
                var isPresent = coveragesTypes.find(function(el) {
                  return el.CovTypeId === covergeElement.Attributes.CovTypeId;
                });
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.LGP) {
                  resultQuoteData.LostGrossProfitLimit =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.ICW) {
                  resultQuoteData.IncresedCost =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.IV) {
                  resultQuoteData.Inoculations =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.XXC) {
                  resultQuoteData.ExtortionCost =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.ACO) {
                  resultQuoteData.CrisisConsultExp =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.ACC) {
                  resultQuoteData.Accidental =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.MTA) {
                  resultQuoteData.Tampering =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.ADP) {
                  resultQuoteData.AdversePub =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                }
              });
            }
          });
          this.quoteAttributeCache.push(resultQuoteData);
        });
      }
      this.quoteCachedData = quoteData;
      return quoteData;
    }
  }
  updateDataElement(elementKey, UWQuoteId) {
    let oldCDataElements = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    let isOldElementExist;
    //const oldCDataElements = this.GetPolicyLevelDataElement;
    if (oldCDataElements) {
      isOldElementExist = oldCDataElements.find(function(el) {
        return el.Attributes.ElementCode === elementKey.ElementCode;
      });
    }
    if (!isOldElementExist) {
      let objelement = {};
      oldCDataElements = [];
      //isNewCovergaeExist.ResponseType = ElementName.Integer;
      objelement = this.requestConverterService.createIntegerField(
        elementKey,
        UWQuoteId
      );
      // let  this.requestConverterService.createIntegerField();
      let objElement = {
        Id: null,
        Type: 'DataElements',
        Attributes: objelement
      };
      // objCoverage.Attributes[Field] = Value;
      oldCDataElements.push(objElement);
      // (result.type = 'new'), (result.covid = isNewCovergaeExist.CovTypeId);
    } else {
      const index = oldCDataElements.findIndex(
        item => item.Attributes.ElementCode === elementKey.ElementCode
      );
      if (index > -1) {
        if (elementKey.ResponseType === ElementName.Integer) {
          //isOldElementExist.Attributes.ResponseType = ElementName.Integer;
          (oldCDataElements[index]['ElementId'] =
            oldCDataElements[index].Attributes.ElementId),
            (oldCDataElements[index].Attributes['IntegerResponse'] = UWQuoteId);
        }
      }
      // result.covid = isOldCoverageExist.CovTypeId;
    }

    this.quoteAttributeCache = [];
    let quoteData: Quote[] = [];
    if (this.quoteFormBuilderCache) {
      if (has(this.quoteFormBuilderCache['Data'][0], 'Attributes')) {
        quoteData = this.quoteFormBuilderCache['Data'][0]['Attributes'][
          'PolicyTxn'
        ]['Attributes']['Quotes'];
      }
      //debugger;
      quoteData = quoteData.filter(function(obj) {
        return obj.Attributes.UWQuoteId === UWQuoteId;
      });
      this.quoteFormBuilderCache['Data'][0]['Attributes']['PolicyTxn'][
        'Attributes'
      ]['Quotes'] = quoteData;
      this.quoteFormBuilderCache['Data'][0]['Attributes']['PolicyTxn'][
        'Attributes'
      ]['DataElements'] = oldCDataElements;
    }
    return this.quoteFormBuilderCache.Data;
    //this.dataElements.forEach(element => {});
  }
  private handleError(err) {
    return throwError(err);
  }

  setRisks = {
    Id: null,
    Type: '',
    Attributes: {}
  };
}
