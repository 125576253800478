import { Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldInfo } from '@tmhcc-ng-lib/framwork/form-inputs';

export interface CoreInput {
  fieldInfo: FieldInfo;
  frmGroup: UntypedFormGroup;
  control: string;
  InputConfig?: any;
}

export interface InputTemplateService {
  getTemplate(fieldInfo: FieldInfo): Type<CoreInput>;
}
