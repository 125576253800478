export interface InitialDataElements {
  DecimalResponseName: string;
  TextResponseName: string;
  DateResponseName: string;
  DataGroupId: number;
  DataGroupName: string;
  GroupIsArray: boolean;
  ElementId: number;
  ElementCode: string;
  ElementName: string;
  ResponseType: string;
  isDisabled?: boolean;
  SortOrder: number;
  AllowDecimalResponse?: boolean;
  AllowDateResponse?: boolean;
  AllowTextResponse?: boolean;
  DataElementResponses?: ElementResponse[];
  RowNum?: number;
}

export interface ElementResponse {
  DataElementResponseId: number;
  ResponseCode: string;
  ResponseValue: string;
  ResponseName: string;
  ResponseText: string;
}

export interface ElementUpdateRequests {
  DataGroupId?: number;
  IsArray?: boolean;
  ElementId: number;
  ElementName?: string;
  ResponseType?: string;
  FreeTextValue?: string;
  SelectedDataElementResponseIds?: any[];
  DecimalValue?: number;
  MoneyValue?: number;
  IntegerValue?: number;
  DateValue?: string;
  BooleanValue?: boolean;
  TextValue?: string;
  DataGroupName?: string;
  RowNum?: number;
  ElementCode?: string;
  DataElementResponseId?: number;
  IntegerResponse?: number;
  DecimalResponse?: number;
  TextResponse?: string;
  MoneyResponse?: number;
  ChooseOneResponse?: boolean;
}

export interface ManualTaxAdndFeeUpdateRequest {
  UwFees: UwFee[];
}

export interface UwFee {
  Id?: number;
  Type?: string;
  Attributes?: UWAttributes;
}

export interface UWAttributes {
  UWFeeId: number;
  UWLOBId: number;
  TaxId: number;
  FeeAmt: number;
  IsCarriedForward: boolean;
  IsDelete: boolean;
}
