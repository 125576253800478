import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  InitialDataElements,
  ElementUpdateRequests
} from '../utility/data-element.def';
import {
  InputConfig,
  ComboBoxConfig
} from '@tmhcc-ng-lib/framwork/form-inputs';
import { has } from '@tmhcc/util';
import { LoaderService } from '@tmhcc-ng-lib/framwork/loader';
import { RequestElementConverterService } from '../services/request-element-converter.service';
import { ResponseElementConverterService } from '../services/response-element-converter.service';

@Component({
  selector: 'hcc-deb-group',
  templateUrl: './deb-group.component.html',
  styleUrls: ['./deb-group.component.scss']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebGroupComponent implements OnInit, OnChanges {
  constructor(
    private fieldConverterService: ResponseElementConverterService,
    private requestConverterService: RequestElementConverterService,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService
  ) {}
  //@Input() parentFg: FormGroup;
  parentFg: UntypedFormGroup;
  fields: InputConfig[];
  @Input() allGroupList: InitialDataElements[];
  @Input() dataElements: any = '';
  @Output() getElements = new EventEmitter<{
    value: any;
    // IsInvalid: boolean;
  }>();
  //@Output() getElemts :
  requestData: ElementUpdateRequests[];
  fieldConfig;
  controlSet = false;
  ngOnInit(): void {
    // if(this.allGroupList)
    // this.fieldConfig = this.fieldConverterService.setInitialField(this.allGroupList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loaderService.show();
    if (this.allGroupList) {
      this.fieldConfig = this.fieldConverterService.setInitialField(
        this.allGroupList,
        this.dataElements
      );
      this.setFormControl();
    } else {
      this.controlSet = false;
    }
    setTimeout(() => {
      this.loaderService.hide();
    }, 2000);
  }

  setFormControl() {
    const formControls = {};
    this.fieldConfig.forEach((element: InputConfig) => {
      // this.setFieldLabels(element, 'ClosedClaimInfo');
      // if(Object.keys(element).length !== 0 && element.constructor !== Object){
      if (has(element, 'fieldInfo')) {
        let validators;
        if (element.config && element.config.validation) {
          validators = element.config.validation;
        } else {
          validators = null;
        }
        formControls[element.fieldInfo.fieldName] = [
          has(element.config, 'value') && element.config.value !== undefined
            ? element.config.value
            : '',
          Validators.compose(validators)
        ];
      }
    });
    this.parentFg = this.fb.group(formControls);
    this.controlSet = true;
    this.buildComboElementRequest(this.parentFg.getRawValue());
    // this.fieldConfig.forEach((element: InputConfig) => {
    //  if (has(element, 'fieldInfo')) {
    this.parentFg.valueChanges //.get(element.fieldInfo.fieldName)
      .subscribe(formValue => {
        // alert('test');
        if (formValue) {
          this.buildComboElementRequest(formValue);
        }
      });
    //  }
    // });
  }

  buildComboElementRequest(formValue) {
    const test = this.parentFg.getRawValue();
    const result = this.requestConverterService.buildDataField(
      this.allGroupList,
      test
    );
    this.getElements.emit(result);
    //console.log(result, '=====DEB');
  }
}
