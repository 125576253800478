import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog-container/dialog.component';
import { InsertionDirective } from './insertion.directive';
import { DialogClickOutsideDirective } from './dialog-container/dialog-outside-click.directive';
@NgModule({
    imports: [CommonModule],
    declarations: [
        DialogComponent,
        InsertionDirective,
        DialogClickOutsideDirective
    ]
})
export class DialogModule {}
