import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import {
  DataElementRespons,
  PolicyMasterData,
  UserConfigurableDataElements
} from '@tmhcc-ng-lib/models';
import { has, isEmpty } from '@tmhcc/util';
import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { InitialDataElements } from '@tmhcc-product-contamination/framwork/data-element-builder';
import { QuoteDataElementName } from './def/coverage-limit-data-elements';
@Injectable()
export class ClaimMasterPolicyDataService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  policyMasterCached: PolicyMasterData;
  foodAndBeverageDetailsCached: any;
  nonFoodDetailsCached: any;

  private masterPolicyMapper(data: any): PolicyMasterData {
    let {
      Countries,
      StateOrProvinces,
      Underwriters,
      ClaimHandler,
      PolicyTypes,
      UnderwriterAssistants,
      SubmissionTypes,
      RenewalOptions,
      TermTypes,
      PaymentPlans,
      UserConfigurableDataElements,
      ConfigSubjectivities,
      WritingCompaniesByStates,
      FormsetTypes,
      TaxesAndFees,
      TransactionStatuses,
      TransactionTypes,
      CoverageTypes,
      CancelReasonCodes,
      ConfigRewriteTypes
    } = data;

    let entitiyserach: PolicyMasterData = {
      Countries,
      StateOrProvinces,
      Underwriters,
      ClaimHandler,
      PolicyTypes,
      UnderwriterAssistants,
      SubmissionTypes,
      RenewalOptions,
      TermTypes,
      PaymentPlans,
      UserConfigurableDataElements,
      ConfigSubjectivities,
      WritingCompaniesByStates,
      FormsetTypes,
      TaxesAndFees,
      TransactionStatuses,
      TransactionTypes,
      CoverageTypes,
      CancelReasonCodes,
      ConfigRewriteTypes
    };
    ////debugger;
    if (data.UserConfigurableDataElements) {
      entitiyserach.CovDataElements = this.getCoverageElements(
        data.UserConfigurableDataElements
      );
    }
    this.policyMasterCached = entitiyserach;
    return entitiyserach;
  }

  getCoverageElements(data) {
    let covElements: InitialDataElements[] = [];
    data.forEach((element: InitialDataElements) => {
      if (element.ElementCode in QuoteDataElementName) {
        covElements.push(element);
      }
    });
    return covElements;
  }

  get CoverageElement() {
    if (!this.policyMasterCached.CovDataElements) {
      throw new Error('Corrupt policy master data: Coverage Data Elements.');
    }
    return this.policyMasterCached.CovDataElements;
  }

  get TaxesAndFees() {
    if (!this.policyMasterCached.CovDataElements) {
      throw new Error(
        'Corrupt policy master data: TaxesAndFees Data Elements.'
      );
    }
    return this.policyMasterCached.TaxesAndFees;
  }
  get Coverges() {
    if (!this.policyMasterCached.CoverageTypes) {
      throw new Error('Corrupt policy master data: Coverages.');
    }
    return this.policyMasterCached.CoverageTypes;
  }

  getLocations() {
    const url = `${this.serviceBaseUrl}/api/v1.0/data/location`;
    return this.get<any>(url).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getAccountDetails() {
    const url = `${this.serviceBaseUrl}/api/v1.0/data/account`;
    return this.get<any>(url).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getBrokerDetails(payload) {
    const url = `${this.serviceBaseUrl}/api/v1.0/data/broker`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveAccountDetails(payload) {
    const url = `${this.serviceBaseUrl}/api/v1.0/submissions`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getFoodAndBeverageDetails(
    submissionId: string,
    doRefresh = false) {
    // if (this.foodAndBeverageDetailsCached === undefined && doRefresh === false) {
    const url = `${this.serviceBaseUrl}/api/v1.0/risk/food/${submissionId}`;
    return this.get<any>(url).pipe(
      map(data => this.mapFoodAndBeverageDetails(data)),
      catchError(this.handleError)
    );
    // }
    // else {
    //   return of(this.foodAndBeverageDetailsCached);
    // }
  }

  mapFoodAndBeverageDetails(data) {
    this.foodAndBeverageDetailsCached = data;
    return data;
  }

  getNonFoodDetails(
    submissionId: string,
    doRefresh = false) {
    // if (this.nonFoodDetailsCached === undefined && doRefresh === false) {
    const url = `${this.serviceBaseUrl}/api/v1.0/risk/nonfood/${submissionId}`;
    return this.get<any>(url).pipe(
      map(data => this.mapNonFoodDetails(data)),
      catchError(this.handleError)
    );
    // }
    // else {
    //   return of(this.nonFoodDetailsCached);
    // }
  }

  mapNonFoodDetails(data) {
    this.nonFoodDetailsCached = data;
    return data;
  }

  saveRiscCodes(payload) {
    const url = `${this.serviceBaseUrl}/api/v1.0/risk/food`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getAccountSetupBySubmissionId(submissionId) {
    const url = `${this.serviceBaseUrl}/api/v1.0/submissions/${submissionId}`;
    return this.get<any>(url).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getPolicyMasterData(
    subCompanyCode: string,
    policyTypeId: number,
    doRefresh = false
  ) {
    if (isEmpty(subCompanyCode)) {
      throw new Error('SubCompany must be set for policy master data.');
    }
    //if (!policyTypeId) {
    //throw new Error('Policy Type Id must be set for policy master data.');
    //}

    if (
      this.policyMasterCached &&
      this.policyMasterCached.UserConfigurableDataElements === undefined
    ) {
      this.setPolicyMaster();
    }
    if (
      this.policyMasterCached &&
      this.policyMasterCached.UserConfigurableDataElements.length &&
      !doRefresh
    ) {
      return of(this.policyMasterCached);
    }
    if (policyTypeId == 0) {
      const url = `${this.serviceBaseUrl}/PolicyMasterData/${subCompanyCode}?CollectionType=Countries`;
      return this.get<PolicyMasterData>(url).pipe(
        map(data => this.setPolicyMasterData(data)),
        catchError(this.handleError)
      );
    } else {
      const url = `${this.serviceBaseUrl}/PolicyMasterData/${policyTypeId}/${subCompanyCode}`;
      return this.get<PolicyMasterData>(url).pipe(
        map(data => this.setPolicyMasterData(data)),
        catchError(this.handleError)
      );
    }
  }

  setPolicyMaster() {
    this.policyMasterCached = null;
  }
  private handleError(err) {
    return throwError(err);
  }
  setPolicyMasterData(data) {
    return this.masterPolicyMapper(data);
  }

  get ConfigSubjective() {
    if (!this.policyMasterCached.ConfigSubjectivities) {
      throw new Error('Corrupt data: subjectivity.');
    }
    return this.policyMasterCached.ConfigSubjectivities;
  }

  getPolicyType(policyMaster: PolicyMasterData, dataElementResponseId: number) {
    const objIndex: UserConfigurableDataElements = policyMaster.UserConfigurableDataElements.find(
      obj => obj.ElementCode === 'RProductType'
    );
    let responseValueObject: DataElementRespons;
    if (has(objIndex, 'DataElementResponses') && dataElementResponseId) {
      responseValueObject = objIndex.DataElementResponses.find(
        obj => obj.DataElementResponseId === dataElementResponseId
      );
    } else {
      responseValueObject = null;
    }
    if (
      has(responseValueObject, 'ResponseCode') &&
      responseValueObject.ResponseCode
    ) {
      return responseValueObject.ResponseCode;
    } else {
      responseValueObject = null;
    }
  }
  get masterData() {
    if (!this.policyMasterCached) {
      throw new Error(
        'Policy Master data must be fetched before it can be used.'
      );
    }

    return this.policyMasterCached;
  }

  get TransactionTypes() {
    if (!this.policyMasterCached.TransactionTypes) {
      throw new Error('Corrupt policy master data: transaction types.');
    }

    return this.policyMasterCached.TransactionTypes;
  }

  get TransactionStatuses() {
    if (!this.policyMasterCached.TransactionStatuses) {
      throw new Error('Corrupt policy master data: transaction status.');
    }

    return this.policyMasterCached.TransactionStatuses;
  }
}
