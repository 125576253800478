export enum DateFormats {
  MMDDYYYY = 'MM/DD/YYYY',
  DDMMYYYY = 'DD/MM/YYYY',
  ISO = 'YYYY-MM-DD'
}

export enum LongDateFormats {
  MMDDYYYY = 'MM/DD/YYYY hh:mm A',
  DDMMYYYY = 'DD/MM/YYYY HH:mm A',
  ISO = 'YYYY-MM-DDTHH:mm:ss[Z]'
}

// export enum DateForms {
//     MMDDYYYY = "MM/DD/YYYY",
//     DDMMYYYY = "DD/MM/YYYY",
//     ISO_SHORT = "YYYY-MM-DD",
//     MMDDYYYY_LONG = "MM/DD/YYYY HH:mm A",
//     DDMMYYYY_LONG = "DD/MM/YYYY HH:mm A",
//     ISO_LONG = "YYYY-MM-DD HH:mm A",
//     ISO_UTC = "YYYY-MM-DDTHH:mm:ss[Z]"
// }
