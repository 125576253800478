import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import {
  GetPolicySubjectivitiesRequest,
  PolicySubjectivityResponse,
  SaveSubjectiviyRequest,
  SaveMiscellaneousChangesRequest,
  SaveMiscellaneousChangesResponse
} from './def/subjectivity';
import {
  SubjectivityGridRow,
  SubjectivityGrid,
  SaveMiscellaneousChangesModel
} from '@tmhcc-ng-lib/models';
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError,of } from 'rxjs';
import { PciSubjectivitiesDataResponse,PciSubjectivityGrid,PciSubjectivityGridRow } from "../models/classes/pci-subjectivity-data.model";
import {PciSaveSubjectiviyRequest,PciSubjectivityUpdateRequest} from "libs/data-service/def/pci-subjectivity";
@Injectable()
export class PciSubjectivityService extends BaseDataService {
  subjectivitiesDataResponseCache: PciSubjectivitiesDataResponse;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  getSubjectivityConfigData() {
    if( this.subjectivitiesDataResponseCache &&
      this.subjectivitiesDataResponseCache.subjectivities.length      
    ){
      return of(this.subjectivitiesDataResponseCache);
   }
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
     'Content-Type': 'application/json',
     'x-api-key': serviceApiKey
   }
   const requestOptions = {                                                                                                                                                                                 
     headers: new HttpHeaders(headerDict), 
   };         
    const url = `${baseURL}/api/v1.0/data/subjectivities`;
   return this.http.get<PciSubjectivitiesDataResponse>(url,requestOptions).pipe(
           map(data => this.SubjectivitiesSetupMapper(data)),
           catchError(this.handleError)
         );
  }
  private SubjectivitiesSetupMapper(data: any): PciSubjectivitiesDataResponse {    
    let datad = data.data;
    let objdata = new PciSubjectivitiesDataResponse(datad)
    this.subjectivitiesDataResponseCache = objdata;
    return objdata;
  }
  getSubjectivity(submissionid:any)  : Observable<PciSubjectivityGrid>
  {   
      let baseURL = this.serviceBaseUrl;   
      let serviceApiKey = this.config.dataServiceApiKey;
      const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };         
      const url = `${baseURL}/api/v1.0/subjectivities/${submissionid}`;
    return this.http.get<PciSubjectivityGrid>(url,requestOptions).pipe(
            map(data => this.mapToSubjectivityGridRow(data)),
            catchError(this.handleError)
          );
  }
  mapToSubjectivityGridRow(data: any): PciSubjectivityGrid {    
    let subjectivityGrid = new PciSubjectivityGrid();
    if (!data || !data.subjectivities) return;
    if (data.subjectivities) {
      let gridRows: PciSubjectivityGridRow[] = [];
      for (let index = 0; index < data.subjectivities.length; index++) {
        const element = data.subjectivities[index];

        let row = new PciSubjectivityGridRow();        
        row.description = element.text;        
        row.checked = element.status === 'Open' ? false : true;
        row.checkboxChecked=element.status === 'Open' ? false : true;
        row.type = element.type;
        row.subjectivityid = element.id;
        row.createdBy = element.createdBy;
        gridRows.push(row);
      }
      subjectivityGrid.subjectivityData = gridRows;
    }
    return subjectivityGrid;
  }
  deleteSubjectivity(submissionid:any,subjectivityid:any) : Observable<any>
  {   
    let status: any;
    let errorMessage: any;
    let baseURL = this.serviceBaseUrl;   
      let serviceApiKey = this.config.dataServiceApiKey;
      const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };         
    const url = `${baseURL}/api/v1.0/subjectivities/${submissionid}/${subjectivityid}`;
    return this.http.delete<any>(url,requestOptions).pipe(
      map(response => response),
      catchError(this.handleError)
    );    
  }
  saveSubjectivity(payload:any): Observable<string>  {
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;      
    const url = `${baseURL}/api/v1.0/subjectivities`;   
    return this.postv1<PciSaveSubjectiviyRequest>(url, { body: payload },serviceApiKey).pipe(
      map(data => this.mapSaveSubjectivity(data)) ,
      catchError(this.handleError)     
    );
  }
  updateSubjectivity(payload:any): Observable<string>  {
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;      
    const url = `${baseURL}/api/v1.0/subjectivities`;   
    return this.putv1<PciSubjectivityUpdateRequest>(url, { body: payload },serviceApiKey).pipe(
      map(data => this.mapUpdateSubjectivity(data)) ,
      catchError(this.handleError)     
    );
  }
  mapUpdateSubjectivity(data: any) {   
    return data;
  }
  mapSaveSubjectivity(data: any) {   
    return data;
  }
  protected handleError(err) {
    return throwError(err);
  }
}
