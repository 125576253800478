import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import {
  ClaimPolicySearchResponse,
  ClaimPolicySearchRequest,
  saveClaimResponse
} from './def/claim-policy-search';
import { ClaimSearchGridRow } from '@tmhcc-ng-lib/models';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import { SaveClaim, SaveRelatedClaims } from './def/save-claim';

@Injectable()
export class ClaimPolicySearchService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  policySearchData: ClaimSearchGridRow[];

  claimPolicySearchData(
    payload: ClaimPolicySearchRequest
  ): Observable<ClaimSearchGridRow[]> {
    const url = `${this.serviceBaseUrl}/ClaimPolicySearch`;

    return this.post<ClaimPolicySearchResponse>(url, { body: payload }).pipe(
      map(data => {
        this.policySearchData = this.policySearchDataMapper(data);
        return this.policySearchData;
      }),
      catchError(this.handleError)
    );
  }

  saveRelatedClaim(payload: SaveRelatedClaims) {
    const url = `${this.serviceBaseUrl}/SaveRelatedClaim`;
    return this.post<saveClaimResponse>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  saveClaimPolicy(payload: SaveClaim) {
    const url = `${this.serviceBaseUrl}/SaveClaim`;

    return this.post<saveClaimResponse>(url, { body: payload }, true).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private policySearchDataMapper(
    data: ClaimPolicySearchResponse
  ): ClaimSearchGridRow[] {
    if (!data || !data.claimPolicySearchResults) return;

    let claimSearchGridRows: ClaimSearchGridRow[] = [];

    for (let index = 0; index < data.claimPolicySearchResults.length; index++) {
      const element = data.claimPolicySearchResults[index];

      let row = new ClaimSearchGridRow();
      row.PolicyTxnId = element.PolicyTxnId;
      row.PolicyNum = element.PolicyNum;
      row.PolicyId = element.PolicyId;
      row.InsuredName = element.InsuredName;
      row.SubCompanyName = element.SubCompanyName;
      row.AgencyName = element.AgencyName;
      row.ClaimBasis = element.ClaimBasis;
      row.PolicyType = element.PolicyType;
      row.SubCompanyCode = element.SubCompanyCode;
      row.IsMultiLob = element.IsMultiLob;
      if (
        !this.dateService.isValidDate(element.TxnEffDate) ||
        !this.dateService.isValidDate(element.PolicyEffDate) ||
        !this.dateService.isValidDate(element.PolicyExpDate)
      ) {
        throw new Error(this.commonContentService.getErrors().InvalidLossDate);
      }
      row.TxnEffDate =
        this.dateService.momentFromLongISO(element.TxnEffDate) || null;
      row.PolicyEffDate = this.dateService.momentFromLongISO(
        element.PolicyEffDate
      );
      row.PolicyExpDate = this.dateService.momentFromLongISO(
        element.PolicyExpDate
      );
      claimSearchGridRows.push(row);
    }

    return claimSearchGridRows;
  }

  private handleError(err) {
    return throwError(err);
  }
}
