import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler,Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { GetProviders } from '@tmhcc-ng-lib/startup';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { LoadingModule, LoaderService } from '@tmhcc-ng-lib/framwork/loader';
import { environment } from '../environments/environment';
import {
  CustomPopupAlertModule,
  CustomPopupAlertService,
  ConfirmationDialogService
} from '@tmhcc-ng-lib/framwork/custom-popup-alert';

import { OktaAuthGuard } from '@tmhcc-ng-lib/framwork/okta-login';

import {
  ErrorHandlerService,
  ErrorInterceptor
} from '@tmhcc-ng-lib/framwork/services';
import { LoggingService } from '@tmhcc-ng-lib/framwork/logging';
import { AlertModule } from '@tmhcc-ng-lib/framwork/alert';
import { ToastrService } from 'ngx-toastr';
import { ClickOutsideDirective } from '@tmhcc-product-contamination/framwork/common-directive';
import { DataTablesModule } from 'angular-datatables';
import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import DataTables from 'datatables.net';

@NgModule({ declarations: [AppComponent, ClickOutsideDirective],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CustomPopupAlertModule,
        NgxsModule.forRoot([], {
            developmentMode: !environment.production
        }),
        NgxsDispatchPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        OktaAuthModule,
        LoadingModule,
        AlertModule,
        DataTablesModule], providers: [
        OktaAuthGuard,
        LoaderService,
        LoggingService,
        ToastrService,
        CustomPopupAlertService,
        ConfirmationDialogService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: OKTA_CONFIG,
            useFactory: () => {
                const oktaAuth = new OktaAuth(environment.oidc);
                return {
                    oktaAuth,
                    onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
                        // N.B. the following block is NOT used for authorization within this App.
                        // Authorization is handed via the OktaAuthGuard class within
                        // /libs/framwork/okta-login/src/lib/okta-authGaurd.ts
                        const triggerLogin = () => {
                            // Redirect the user to your custom login page
                            const router = injector.get(Router);
                            router.navigate(['/login']);
                        };
                        if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
                            // App initialization stage
                            triggerLogin();
                        }
                    }
                };
            }
        },
        ...GetProviders('TMHCC PCI Product Contamination'),
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule {}
