import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {
  Response,
  ApproveQuote,
  IssueNewBusiness,
  IssueNewBusinessResponse,
  CalculateQuoteRequest,
  Endorsement,
  Rewrite,
  Renewal
} from './def/bind-process';
import { throwError, Observable } from 'rxjs';
import { BaseDataService } from './base-data-service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { Formbuilder } from './def/coverage-policies';
import {
  CancelPolicy,
  IssueCancelPolicy,
  IssueEndrosePolicy
} from 'libs/models/classes/cancel-policy.model';

@Injectable()
export class BindProcessService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }
  quoteFormBuilderCache: Formbuilder;
  private handleError(err) {
    return throwError(err);
  }

  calculateQuote(payload: CalculateQuoteRequest) {
    const url = `${this.serviceBaseUrl}/CalculateQuote`;
    return this.post<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  ApproveQuote(payload: ApproveQuote) {
    const url = `${this.serviceBaseUrl}/ApproveQuote`;
    return this.put<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  IssueNewBusiness(payload: IssueNewBusiness) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/IssueNewBusiness`;
    return this.put<IssueNewBusinessResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  IssueRewrite(payload: Rewrite) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/IssueRewrite`;
    return this.put<Rewrite>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  IssueRenewal(payload: Renewal) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/IssueRenewal`;
    return this.put<Renewal>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  IssueEndorsement(payload: Endorsement) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/IssueEndorsement`;
    return this.put<Endorsement>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  UpdatePolicyDates(payload: any) {
    const url = `${this.serviceBaseUrl}/UpdatePolicyDates`;
    return this.put<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  createCancelPolicy(payload: CancelPolicy) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/CreateCancelPolicy`;
    return this.put<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  issueCancelPolicy(payload: IssueCancelPolicy) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/IssueCancel`;
    return this.put<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  deleteCancelPolicy(payload) {
    const url = `${this.serviceBaseUrl}/DeleteTransaction`;
    return this.put<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  createEndrosePolicy(payload) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/CreateEndorsementRRI`;
    return this.post<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  issueEndrosePolicy(payload: IssueEndrosePolicy) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/IssueEndorsement`;
    return this.put<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  RenewPolicy(payload) {
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/CreateRenewal`;
    return this.post<Response>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
}
