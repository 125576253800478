import { FieldInfo } from '@tmhcc-ng-lib/framwork/form-inputs';

export interface pageData {
  pageName: string;
  forms: Form[];
  grids: Grid[];
  accordians: any[];
}

export interface Form {
  formName: string;
  IsAdvanceSearch?: boolean;
  fields: Field[];
}

export interface Field {
  colClass?: string;
  isDisabled?: boolean;
  isVisible?: boolean;
  isAdvanceSearch?: boolean;
  orderId?: string;
  fieldInfo?: FieldInfo;
  config?: any;
}

export interface UCMForm {
  formID: string;
  formName?: string;
  elementDefaults: string;
  productType: string;
  tabName: string;
}
export interface Grid {
  gridName: string;
  rowText: string;
  deleteRow: boolean;
  paging: string;
  pageLength: string;
  searching: boolean;
  ordering: boolean;
  isCheckBoxRequired?: boolean;
  responsive: boolean;
  isVisibility: boolean;
  columnDefinitions: ColumnDefinition[];
}

export interface ColumnDefinition {
  fieldName: string;
  label: string;
  isVisible: boolean;
  isSortable: boolean;
  alignment: string;
  columnType: ColumnType;
  orderId: string;
}

export interface ColumnType {
  Anchor: string;
  Class: string;
  Text: string;
  Query: string;
  Title: string;
  Url: string;
  Target: string;
  TargetId: string;
  Type: number;
  Style: string;
}

export interface MappedPageData {
  gridData: any[];
  formData: any[];
  accordianData: any[];
  UCMforms?: any[];
}

export interface MappedGridData {
  gridName: string;
  data: {
    GirdDefination: object;
    ColumnDefinition: any;
    OtherDefination?: object;
  };
}

export interface Badge {
  displayItems: badgeItem[];
  heading: string;
}

export interface badgeItem {
  apiMappingKey: string;
  description: string;
  keyText: string;
  orderId: number;
}
