import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { map, catchError } from 'rxjs/operators';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { IssueNewBusinessModel } from '@tmhcc-ng-lib/models';
import {
  IssueNewBusinessRequest,
  IssueNewBusinessResponse
} from './def/issue-new-business';
import { throwError } from 'rxjs';

@Injectable()
export class IssueNewBusinessService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  issueNewBusiness(
    payload: IssueNewBusinessRequest
  ): Observable<IssueNewBusinessModel> {
    const url = `${this.serviceBaseUrl}/IssueNewBusinessECO`;

    return this.put<IssueNewBusinessResponse>(url, { body: payload }).pipe(
      map(data => {
        return this.mapResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  mapResponse(data: IssueNewBusinessResponse): IssueNewBusinessModel {
    if (!data) return;
    let issueNewBusinessModel = new IssueNewBusinessModel();
    if (data) {
      issueNewBusinessModel.PolicyTxnId = data.PolicyTxnId;
      issueNewBusinessModel.Status = data.Status;
      issueNewBusinessModel.SuspenseAmount = data.SuspenseAmount;
      issueNewBusinessModel.TransactionStatus = data.TransactionStatus;
      issueNewBusinessModel.Warnings = data.Warnings;
      issueNewBusinessModel.Errors = data.Errors;
      issueNewBusinessModel.BalanceDue = data.BalanceDue;
    }
    return issueNewBusinessModel;
  }
  private handleError(err) {
    return throwError(err);
  }
}
