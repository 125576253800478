import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
// import { ClaimMasterData, Country } from '@tmhcc-ng-lib/models';
import { isEmpty } from '@tmhcc/util';
import { ClaimsMasterData } from './claims-master.service';

@Injectable()
export class ClaimMasterDataService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  // masterDatCached: ClaimMasterData;
  policyData: any;

  // setPolicyMasterData(data) {
  //   return this.masterClaimMapper(data);
  // }

  // private masterClaimMapper(data: any): ClaimMasterData {
  //   let claimMasterData = new ClaimMasterData();

  //   if (data.Countries) {
  //     data.Countries.forEach(item => {
  //       claimMasterData.Countries.push(item);
  //     });
  //   }
  //   if (data.SubCompanies) {
  //     data.SubCompanies.forEach(item => {
  //       claimMasterData.SubCompanies.push(item);
  //     });
  //   }
  //   if (data.ClaimAttorney) {
  //     data.ClaimAttorney.forEach(item => {
  //       claimMasterData.ClaimAttorney.push(item);
  //     });
  //   }
  //   if (data.ClaimStatuses) {
  //     data.ClaimStatuses.forEach(item => {
  //       claimMasterData.ClaimStatuses.push(item);
  //     });
  //   }
  //   if (data.ClaimHandler) {
  //     data.ClaimHandler.forEach(item => {
  //       claimMasterData.ClaimHandler.push(item);
  //     });
  //   }
  //   if (data.CauseofLoss) {
  //     data.CauseofLoss.forEach(item => {
  //       claimMasterData.CauseofLoss.push(item);
  //     });
  //   }
  //   if (data.ClaimRecoveryTypes) {
  //     data.ClaimRecoveryTypes.forEach(item => {
  //       claimMasterData.ClaimRecoveryTypes.push(item);
  //     });
  //   }
  //   if (data.StateOrProvinces) {
  //     data.StateOrProvinces.forEach(item => {
  //       claimMasterData.StateOrProvinces.push(item);
  //     });
  //   }
  //   if (data.CashBatchTypes) {
  //     data.CashBatchTypes.forEach(item => {
  //       claimMasterData.CashBatchTypes.push(item);
  //     });
  //   }
  //   if (data.Professions) {
  //     data.Professions.forEach(item => {
  //       claimMasterData.Professions.push(item);
  //     });
  //   }
  //   if (data.CaseSubTypes) {
  //     data.CaseSubTypes.forEach(item => {
  //       claimMasterData.CaseSubTypes.push(item);
  //     });
  //   }

  //   if (data.CaseTypes) {
  //     data.CaseTypes.forEach(item => {
  //       claimMasterData.CaseTypes.push(item);
  //     });
  //   }
  //   if (data.ReserveTypes) {
  //     data.ReserveTypes.forEach(item => {
  //       claimMasterData.ReserveTypes.push(item);
  //     });
  //   }

  //   if (data.LinesOfBusiness) {
  //     data.LinesOfBusiness.forEach(item => {
  //       claimMasterData.LinesOfBusiness.push(item);
  //     });
  //   }

  //   if (data.ClaimEntryTypes) {
  //     data.ClaimEntryTypes.forEach(item => {
  //       claimMasterData.ClaimEntryTypes.push(item);
  //     });
  //   }
  //   if (data.ReportedBy) {
  //     data.ReportedBy.forEach(item => {
  //       claimMasterData.ReportedBy.push(item);
  //     });
  //   }
  //   if (data.EventTypes) {
  //     data.EventTypes.forEach(item => {
  //       claimMasterData.EventTypes.push(item);
  //     });
  //   }

  //   if (data.NatureOfDamages) {
  //     data.NatureOfDamages.forEach(item => {
  //       claimMasterData.NatureOfDamages.push(item);
  //     });
  //   }

  //   if (data.ClaimClosedReasons) {
  //     data.ClaimClosedReasons.forEach(item => {
  //       claimMasterData.ClaimClosedReasons.push(item);
  //     });
  //   }

  //   if (data.Counties) {
  //     data.Counties.forEach(item => {
  //       claimMasterData.Counties.push(item);
  //     });
  //   }

  //   if (data.ClaimSeverities) {
  //     data.ClaimSeverities.forEach(item => {
  //       claimMasterData.ClaimSeverities.push(item);
  //     });
  //   }

  //   if (data.NameTypes) {
  //     data.NameTypes.forEach(item => {
  //       claimMasterData.NameTypes.push(item);
  //     });
  //   }

  //   if (data.Currencies) {
  //     data.Currencies.forEach(item => {
  //       claimMasterData.Currencies.push(item);
  //     });
  //   }

  //   if (data.ClaimPaymentTypes) {
  //     data.ClaimPaymentTypes.forEach(item => {
  //       claimMasterData.ClaimPaymentTypes.push(item);
  //     });
  //   }

  //   if (data.PaymentTypes) {
  //     data.PaymentTypes.forEach(item => {
  //       claimMasterData.PaymentTypes.push(item);
  //     });
  //   }

  //   if (data.PaymentMethods) {
  //     data.PaymentMethods.forEach(item => {
  //       claimMasterData.PaymentMethods.push(item);
  //     });
  //   }
  //   if (data.ClaimPayeeEndorseeText) {
  //     data.ClaimPayeeEndorseeText.forEach(item => {
  //       claimMasterData.ClaimPayeeEndorseeText.push(item);
  //     });
  //   }
  //   if (data.GlobalClaimDataElement) {
  //     data.GlobalClaimDataElement.forEach(item => {
  //       claimMasterData.GlobalClaimDataElement.push(item);
  //     });
  //   }
  //   if (data.UserConfigurableDataElements) {
  //     data.UserConfigurableDataElements.forEach(item => {
  //       claimMasterData.UserConfigurableDataElements.push(item);
  //     });
  //   }
  //   if (data.ReserveCats) {
  //     data.ReserveCats.forEach(item => {
  //       claimMasterData.ReserveCats.push(item);
  //     });
  //   }
  //   if (data.ConfigClaimPDBISeverities) {
  //     data.ConfigClaimPDBISeverities.forEach(item => {
  //       claimMasterData.ConfigClaimPDBISeverities.push(item);
  //     });
  //   }
  //   if (data.issueOptions) {
  //     data.issueOptions.forEach(item => {
  //       claimMasterData.IssueOptions.push(item);
  //     });
  //   }
  //   //
  //   this.masterDatCached = claimMasterData;
  //   return this.masterDatCached;
  // }

  // async fetchMasterDataAsync(subCompanyCode: string) {
  //   if (isEmpty(subCompanyCode)) {
  //     throw new Error('SubCompany must be set for claim master data.');
  //   }

  //   const url = `${this.serviceBaseUrl}/ClaimMasterData/${subCompanyCode}`;

  //   await this.get<ClaimMasterData>(url)
  //     .toPromise()
  //     .then(data => {
  //       return this.setPolicyMasterData(data);
  //     });
  // }

  // get masterData() {
  //   if (!this.masterDatCached) {
  //     throw new Error(
  //       'Claim Master data must be fetched before it can be used.'
  //     );
  //   }

  //   return this.masterDatCached;
  // }

  // get countries() {
  //   if (!this.masterDatCached.Countries) {
  //     throw new Error('Corrupt claim master data: Countries.');
  //   }

  //   return this.masterDatCached.Countries;
  // }

  // get subCompanies() {
  //   if (!this.masterDatCached.SubCompanies) {
  //     throw new Error('Corrupt claim master data: SubCompanyCode.');
  //   }

  //   return this.masterDatCached.SubCompanies;
  // }

  // get claimHandler() {
  //   if (!this.masterDatCached.ClaimHandler) {
  //     throw new Error('Corrupt claim master data: ClaimHandler.');
  //   }

  //   return this.masterDatCached.ClaimHandler;
  // }

  // get issueOptions() {
  //   if (!this.masterDatCached.IssueOptions) {
  //     throw new Error('Corrupt claim master data: IssueOptions.');
  //   }

  //   return this.masterDatCached.IssueOptions;
  // }
}
