<form [formGroup]="parentFg" *ngIf="controlSet">
  <div class="form-row">
    <ng-container *ngFor="let items of fieldConfig">
      <div
        *ngIf="items?.fieldInfo; else comboTemplate"
        [ngClass]="items.colClass ? items.colClass : 'col-sm-6 col-lg-6'"
      >
        <hcc-input-generator
          [frmGroup]="parentFg"
          [fieldInfo]="items.fieldInfo"
          [control]="items.fieldInfo.fieldName"
          [InputConfig]="items?.config"
        ></hcc-input-generator>
      </div>
      <ng-template #comboTemplate>
        <div [ngClass]="items.colClass ? items.colClass : 'col-sm-4 col-lg-4'">
          <div class="row">
            <div class="col-12">
              <label for="">{{ items.label }}</label>
              <hcc-deb-combobox
                [comboConfig]="items"
                [frmGroup]="parentFg"
                [data]="data"
                (changeValue)="buildComboElementRequest($event)"
              >
              </hcc-deb-combobox>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</form>

<!-- *ngIf="InputConfig?.numericConfig?.type == 'currency'; else numberTemplate" -->
