import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CoreInputValidationService {
  private isFormSubmitted = new BehaviorSubject<boolean>(false);
  formSubmission = this.isFormSubmitted.asObservable();

  setFormSubmission(submitState) {
    this.isFormSubmitted.next(submitState);
  }

  postalFilter(postalCode) {
    if (!postalCode) {
      return null;
    }

    postalCode = postalCode.toString().trim();
    var us = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
    var ca = new RegExp(
      /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i
    );
    if (us.test(postalCode.toString())) {
      return true;
    }

    if (ca.test(postalCode.toString().replace(/\W+/g, ''))) {
      return true;
    }
    return false;
  }
}
