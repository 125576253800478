import { Component, Input } from '@angular/core';

@Component({
  selector: 'ac-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() color = 'primary';
  @Input() mode = 'indeterminate';
  @Input() value = 50;
  @Input() loaderMessage = '';
  constructor() {}
}
