<div class="modal-header">
  <h4 class="modal-title">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    {{ title }}
  </h4>
  <button type="button" class="close" data-dismiss="modal" (click)="decline()">
    &times;
  </button>
</div>
<div class="modal-body px-0">
  <div class="text-center">
    <p>{{ message }}</p>
  </div>
</div>
<div class="modal-footer justify-content-center px-0">
  <button class="btn btn-secondary btn-sm" (click)="decline()" type="button">
    {{ btnCancelText }}
  </button>
  <button class="btn btn-primary btn-sm" (click)="accept()" type="button">
    {{ btnOkText }}
  </button>
</div>
