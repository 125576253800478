import {
  GetClaimResponse,
  ClaimDataElementCollection
} from 'libs/data-service/def/get-claim';
import * as _ from 'lodash';
import { isEmpty } from '@tmhcc/util';
export class ClaimEventSearchData {
  Status: string;
  Validations: [];
  AssociateEventClaims: AssociatedClaimEvent[];
  Events: EventInfomation[];
  //summary: Summary;
  //claimAgencySummaryInfo: ClaimAgencyInfos[];;
}

export class EventInfomation {
  CatCode: string;
  ClaimEventDesc: string;
  ClaimEventID: number;
  ClaimEventName: string;
  ClaimEventType: string;
  EventDateFrom: string;
  EventDateTo: string;
  Location: string;
}

export class AssociatedClaimEvent {
  ClaimNum?: string;
  ClaimEntryType?: string;
  CauseOfLoss?: string;
  ClaimStatus?: string;
  LossDate: string;
  PolicyNum?: string;
  RiskDescription?: string;
  Claimant?: string;
  ClaimMadeDate?: string;
  Insured?: string;
  ClaimEventID?: number;
  FileAsName?: string;

  constructor(
    ClaimNum: string,
    ClaimEntryType: string,
    CauseOfLoss: string,
    ClaimStatus: string,
    LossDate: string,
    PolicyNum: string,
    RiskDescription: string,
    Claimant?: string,
    ClaimMadeDate?: string,
    Insured?: string,
    ClaimEventID?: number,
    FileAsName?: string
  ) {
    if (
      isEmpty(ClaimNum) ||
      isEmpty(ClaimEntryType) ||
      isEmpty(CauseOfLoss) ||
      isEmpty(ClaimStatus) ||
      isEmpty(LossDate) ||
      isEmpty(PolicyNum) ||
      isEmpty(RiskDescription) ||
      isEmpty(ClaimMadeDate)
    ) {
      throw new Error('Invalid Claim Event.');
    }
    this.ClaimNum = ClaimNum;
    this.ClaimEntryType = ClaimEntryType;
    this.CauseOfLoss = CauseOfLoss;
    this.ClaimStatus = ClaimStatus;
    this.LossDate = LossDate;
    this.PolicyNum = PolicyNum;
    this.RiskDescription = RiskDescription;
    this.Claimant = Claimant;
    this.ClaimMadeDate = ClaimMadeDate;
    this.Insured = Insured;
    this.ClaimEventID = ClaimEventID;
    this.FileAsName = FileAsName;
  }
}
