import {
  Component,
  forwardRef,
  ViewChild,
  HostBinding,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBase } from '../input-base';

@Component({
  selector: 'hcc-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchButtonComponent),
      multi: true
    }
  ]
})
export class SwitchButtonComponent extends InputBase<string> {
  @Input() switchConfig: string;
  constructor() {
    super();
  }
}
