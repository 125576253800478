import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import {
  ClaimSearchGridRow,
  CoverageTypes,
  ManualTaxAndFeeDataList
} from '@tmhcc-ng-lib/models';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import { PolicyTypes } from '../product-contamination/shared/enum-type';
import {
  ManualTaxAndFeeRequest,
  ManualTaxAndFeeResponse,
  QuoteStepService
} from '@tmhcc-ng-lib/data-service';

import {
  DeleteQuote,
  PolicyFormBuilderResponse,
  Quotes,
  Formbuilder,
  QuoteAttributes,
  Quote,
  Lobs,
  Risks,
  RiskAttributes,
  Coverages,
  FluffyDataElement,
  QuoteResult,
  CopyQuote,
  PolicyTxnAttributes,
  ApproveQuote
} from './def/coverage-policies';
import { has } from '@tmhcc/util';
import {
  CoverageCodes,
  RiskType,
  PolicyTxnDataElement
} from './def/coverage-limit-data-elements';
import {
  InitialDataElements,
  ElementUpdateRequests
} from '@tmhcc-product-contamination/framwork/data-element-builder';
import {
  ElementName,
  ElementRequestName
} from 'libs/framwork/data-element-builder/src/lib/utility/deb-utility.def';
import {
  genericObj,
  CoverageDataElementsService
} from '@tmhcc/product-contamination';
import { FranchiseFormBuilderService } from './franchise-formbuilder.service';
import { QouteProductType } from './def/rri-risk-product';

@Injectable()
export class CoverageLimitFormBuilderService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService,
    private requestConverterService: CoverageDataElementsService,
    private FranchiseFormBuilderService: FranchiseFormBuilderService,
    private policyService: QuoteStepService
  ) {
    super(http, configService);
  }

  policySearchData: ClaimSearchGridRow[];
  quoteFormBuilderCachedData: PolicyFormBuilderResponse;
  quoteCachedData: Quote[] = [];
  quoteFormBuilderCache: Formbuilder;
  quoteAttributeCache = [];
  QuoteResultCache: QuoteResult[] = [];
  quoteCacheCoverages: Coverages[] = [];
  quoteCacheDataElements: FluffyDataElement[] = [];
  UWquoteId: number = null;
  quoteGeneralCache: PolicyTxnAttributes;

  QuoteContainer: QouteProductType[] = [];

  deleteQuote(payload: DeleteQuote) {
    const url = `${this.serviceBaseUrl}/DeleteQuote`;
    return this.put<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  calculateQuote(payload: any) {
    const url = `${this.serviceBaseUrl}/CalculateQuote`;
    return this.post<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  UpdatePolicyDates() {}

  ApproveQuote(payload: ApproveQuote) {
    const url = `${this.serviceBaseUrl}/ApproveQuote`;
    return this.put<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  createDynamicRSXRequest(payload: any) {
    const url = `${this.serviceBaseUrl}/CreateDynamicRSXRequest`;
    return this.post<any>(url, { body: payload }, false).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  updateQuote(payload: any) {
    //debugger;
    console.log(
      payload.PolicyData.Data[0].Attributes.PolicyTxn.Attributes.Quotes,
      'request======='
    );
    sessionStorage.oldPolicyTxnId = null;
    const url = `${this.serviceBaseUrl}/UpdateQuoteRRI`;
    return this.put<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  copyQuote(payload: CopyQuote) {
    const url = `${this.serviceBaseUrl}/CopyQuote`;
    return this.post<any>(url, { body: payload }, false).pipe(
      map(data => {
        return data.Status;
      }),
      catchError(this.handleError)
    );
  }

  get FormBuilderQuotes() {
    if (!this.quoteCachedData) {
      throw new Error(
        'Form Builder data must be fetched before it can be used.'
      );
    }
    return this.quoteCachedData;
  }

  getFormBuilderData(payload: any): Observable<PolicyFormBuilderResponse> {
    // this.quoteFormBuilderCache.Data = [];
    const url = `${this.serviceBaseUrl}/FormBuilderData/${payload.Form}/${payload.policyTxnId}`;
    return this.get<Formbuilder>(url).pipe(
      map(data => {
        this.quoteFormBuilderCache = data;
        if (
          has(payload, 'Coverages') &&
          payload.Type === PolicyTypes.Corporate
        ) {
          return this.mapToQuotePolicyCorp(
            data,
            payload.Coverages,
            payload.DataElements,
            payload.Type
          );
        } else if (
          has(payload, 'Coverages') &&
          payload !== PolicyTypes.Corporate
        ) {
          return this.mapToQuotePolicyFranchiseMem(
            data,
            payload.Coverages,
            payload.DataElements
          );
        } else {
          let riskdata = this.mapToQuotePolicyDataStep3(data);

          return riskdata;
        }
      }),
      catchError(this.handleError)
    );
  }

  private mapToQuotePolicyCorp(
    data: Formbuilder,
    coveragesTypes: CoverageTypes[],
    dataTypeElements: InitialDataElements[],
    Type: string
  ): any {
    //if (!data) return;
    if (data) {
      this.quoteCacheCoverages = [];
      this.quoteAttributeCache = [];
      this.quoteCacheDataElements = [];
      this.quoteCachedData = [];
      let quoteData: Quote[] = [];
      let lobsData: Lobs[] = [];
      let riskData: Risks[] = [];
      this.QuoteContainer = [];
      if (data) {
        if (has(data['Data'][0], 'Attributes')) {
          this.quoteGeneralCache =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes'];
          quoteData =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes']['Quotes'];
          let isExistElements =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
              'DataElements'
            ];
          if (isExistElements) {
            isExistElements.forEach(el => {
              if (el.Attributes.ElementCode in PolicyTxnDataElement) {
                //covElements.push(element);
                this.UWquoteId = el.Attributes['IntegerResponse'];
              }
            });
          }
        }
        //debugger;
        quoteData.forEach((element: Quote) => {
          //debugger;
          let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
          let arrayQuote = [];
          let resultQuoteData: genericObj = {
            PolicyAggr: null, //
            InsRetention: null, //
            ExtortionCost: null, //
            CrisisConsultExp: null, //
            LostGrossProfitLimit: null, //
            IncresedCost: null, //
            Inoculations: null, //
            Accidental: null, //
            AccidentalPremium: null,
            Tampering: null, //
            SupContamination: null, //
            AdversePub: null, //
            OverallAggr: null, //
            PerOccLimit: null, //
            Deductibles: null, //
            OneYrPremium: null,
            TwoYrPremium: null,
            WaitingPeriod: null,
            RecallExp: null,
            RehabExp: null,
            LGPIncRoyalties: null,
            LGPIndPeriod: null,
            WPVIndemPeriod: null,
            PremType: null,
            RNoLocs: null, // Assigning value of FranLocations and CorpLocations after changing
            TwoYrPrepaidFactor: 1.75,
            BasePrem: null,
            WPVPrem: null,
            TRIARate: null,
            TRIAPrem: null,
            PolicyTypeId: null,
            UWQuoteId: null,
            QuoteDescription: '',
            QuoteNum: null,
            PerLocationLimit: null,
            RecallExpPerLoc: null,
            RehabExpPerLoc: null,
            AccidentalPerLocation: null,
            TamperingPerLocation: null,
            SupContaminationPerLocation: null,
            AdversePubPerLocation: null,
            QuoteStatusCode: '',
            ProcessStatusCode: '',
            Premium: null,
            Taxes: null,
            IsRated: false,
            PolicyEff: null,
            PolicyExp: null,
            TermLength: null,
            //--
            FR: null,
            TaxFeeDscr1: null,
            TaxFeeAmnt1: null,
            TaxFeeDscr2: null,
            TaxFeeAmnt2: null,
            TaxFeeDscr3: null,
            TaxFeeAmnt3: null,
            TaxFeeDscr4: null,
            TaxFeeAmnt4: null,
            TaxFeeDscr5: null,
            TaxFeeAmnt5: null
          };

          if (has(element.Attributes.Lobs[0].Attributes, 'UwFees')) {
            const payload: ManualTaxAndFeeRequest = {
              PolicyTxnId: sessionStorage.policyTxnId,
              SubCompanyCode: 'RRI',
              UWQuoteId: this.UWquoteId,
              LobId: element.Attributes.Lobs[0].Attributes.LOBId
            };
            this.policyService
              .getManualTaxesAndFees(payload)
              .subscribe(response => {
                // debugger;
                if (
                  element.Attributes.Lobs[0].Attributes.UwFees[0] != undefined
                ) {
                  resultQuoteData.TaxFeeAmnt1 =
                    element.Attributes.Lobs[0].Attributes.UwFees[0];
                  let TaxId =
                    element.Attributes.Lobs[0].Attributes.UwFees[0].Attributes
                      .TaxId;
                  let selectedTaxFee = response.data.find(
                    obj => obj.TaxId === TaxId
                  );
                  resultQuoteData.TaxFeeDscr1 = {
                    TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                  };
                }
                if (
                  element.Attributes.Lobs[0].Attributes.UwFees[1] != undefined
                ) {
                  resultQuoteData.TaxFeeAmnt2 =
                    element.Attributes.Lobs[0].Attributes.UwFees[1];
                  let TaxId =
                    element.Attributes.Lobs[0].Attributes.UwFees[1].Attributes
                      .TaxId;
                  let selectedTaxFee = response.data.find(
                    obj => obj.TaxId === TaxId
                  );
                  resultQuoteData.TaxFeeDscr2 = {
                    TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                  };
                }

                if (
                  element.Attributes.Lobs[0].Attributes.UwFees[2] != undefined
                ) {
                  resultQuoteData.TaxFeeAmnt3 =
                    element.Attributes.Lobs[0].Attributes.UwFees[2];
                  let TaxId =
                    element.Attributes.Lobs[0].Attributes.UwFees[2].Attributes
                      .TaxId;
                  let selectedTaxFee = response.data.find(
                    obj => obj.TaxId === TaxId
                  );
                  resultQuoteData.TaxFeeDscr3 = {
                    TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                  };
                }
                if (
                  element.Attributes.Lobs[0].Attributes.UwFees[3] != undefined
                ) {
                  resultQuoteData.TaxFeeAmnt4 =
                    element.Attributes.Lobs[0].Attributes.UwFees[3];
                  let TaxId =
                    element.Attributes.Lobs[0].Attributes.UwFees[3].Attributes
                      .TaxId;
                  let selectedTaxFee = response.data.find(
                    obj => obj.TaxId === TaxId
                  );
                  resultQuoteData.TaxFeeDscr4 = {
                    TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                  };
                }

                if (
                  element.Attributes.Lobs[0].Attributes.UwFees[4] != undefined
                ) {
                  resultQuoteData.TaxFeeAmnt5 =
                    element.Attributes.Lobs[0].Attributes.UwFees[4];
                  let TaxId =
                    element.Attributes.Lobs[0].Attributes.UwFees[4].Attributes
                      .TaxId;
                  let selectedTaxFee = response.data.find(
                    obj => obj.TaxId === TaxId
                  );
                  resultQuoteData.TaxFeeDscr5 = {
                    TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                  };
                }
              });
          }

          //debugger;
          resultQuoteData.PolicyEff = this.formatDate(
            this.quoteGeneralCache.PolicyEff
          );
          resultQuoteData.PolicyExp = this.formatDate(
            this.quoteGeneralCache.PolicyExp
          );
          let termObj = {};
          dataTypeElements.forEach(ele => {
            if (ele.ElementId === 29907) {
              if (this.quoteGeneralCache.DataElements) {
                let isExist = this.quoteGeneralCache.DataElements.find(
                  el => el.Attributes.ElementId === 29907
                );
                if (isExist) {
                  termObj = ele.DataElementResponses.find(
                    value =>
                      value.DataElementResponseId ==
                      isExist.Attributes.DataElementResponseId
                  );
                }
              }
            }
          });
          resultQuoteData.TermLength = termObj;
          if (has(this.quoteGeneralCache, 'ProcessStatusCode')) {
            resultQuoteData.ProcessStatusCode = this.quoteGeneralCache.ProcessStatusCode;
          }
          if (has(element.Attributes, 'WrittenPrem')) {
            resultQuoteData.Premium = element.Attributes.WrittenPrem;
          }
          if (has(element.Attributes, 'WrittenTaxes')) {
            resultQuoteData.Taxes = element.Attributes.WrittenTaxes;
          }
          if (has(element.Attributes, 'IsRated')) {
            resultQuoteData.IsRated = element.Attributes.IsRated;
          }
          resultQuoteData.PolicyTypeId = element.Attributes.PolicyTxnId;
          resultQuoteData.UWQuoteId = element.Attributes.UWQuoteId;
          resultQuoteData.QuoteDescription =
            element.Attributes.QuoteDescription;
          resultQuoteData.QuoteNum = element.Attributes.QuoteNum;
          if (has(element.Attributes, 'QuoteStatusCode')) {
            resultQuoteData.QuoteStatusCode =
              element.Attributes.QuoteStatusCode;
          }
          riskResult.forEach((riskElement: Risks) => {
            let QuoteContainerObj: QouteProductType = {
              QuoteId: null,
              RiskId: null,
              QuoteNum: null,
              DataElements: [],
              Coverages: [],
              WrittenPrem: null,
              WrittenTaxes: null
            };
            let coverageCache: Coverages;
            let elementcache: FluffyDataElement;
            let riskNestcoverages;
            let dataNestElement;
            //Added on Mar 28 2021 for adding container
            QuoteContainerObj.QuoteId = element.Attributes.UWQuoteId;
            QuoteContainerObj.QuoteNum = element.Attributes.QuoteNum;
            QuoteContainerObj.RiskCodeType =
              riskElement.Attributes.RiskTypeCode;
            if (has(element.Attributes, 'WrittenPrem')) {
              QuoteContainerObj.WrittenPrem = element.Attributes.WrittenPrem;
            }
            if (has(element.Attributes, 'WrittenTaxes')) {
              QuoteContainerObj.WrittenPrem = element.Attributes.WrittenTaxes;
            }
            if (has(riskElement.Attributes, 'Coverages')) {
              riskNestcoverages = riskElement.Attributes.Coverages;
            }
            if (has(riskElement.Attributes, 'DataElements')) {
              dataNestElement = riskElement.Attributes.DataElements;
            }

            QuoteContainerObj.RiskId = riskElement.Attributes.UWRiskId;
            QuoteContainerObj.DataElements = dataNestElement;
            QuoteContainerObj.Coverages = riskNestcoverages;
            //Ended on Mar 28 2021 for adding container
            //arrayQuote.push();
            if (
              dataNestElement &&
              riskElement.Attributes.RiskTypeCode === RiskType.LOB
            ) {
              //this.quoteCacheDataElements = dataNestElement;
              dataNestElement.forEach((dataElement: FluffyDataElement) => {
                let isPresent = dataTypeElements.find(function(el) {
                  return el.ElementCode === dataElement.Attributes.ElementCode;
                });
                if (isPresent && isPresent.ResponseType === ElementName.Text) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.TextResponse;
                  elementcache = dataElement;
                  // elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  // elementcache.QuoteNum = element.Attributes.QuoteNum;
                  // elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Integer &&
                  dataElement.Attributes.ElementCode !== 'RNoLocs'
                ) {
                  if (dataElement.Attributes.ElementCode === '1YrPrem') {
                    dataElement.Attributes.ElementCode = 'OneYrPremium';
                  }
                  if (dataElement.Attributes.ElementCode === '2YrPrem') {
                    dataElement.Attributes.ElementCode = 'TwoYrPremium';
                  }
                  if (dataElement.Attributes.ElementCode === 'CorpLocations') {
                    dataElement.Attributes.ElementCode = 'RNoLocs';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.IntegerResponse;
                  elementcache = dataElement;
                  // elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //  elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Decimal
                ) {
                  if (
                    dataElement.Attributes.ElementCode === '2YrPrepaidFactor'
                  ) {
                    dataElement.Attributes.ElementCode = 'TwoYrPrepaidFactor';
                  }
                  if (dataElement.Attributes.ElementCode === '1YrPrem') {
                    dataElement.Attributes.ElementCode = 'OneYrPremium';
                  }
                  if (dataElement.Attributes.ElementCode === '2YrPrem') {
                    dataElement.Attributes.ElementCode = 'TwoYrPremium';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.DecimalResponse;
                  elementcache = dataElement;
                  // elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //  elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Money
                ) {
                  if (dataElement.Attributes.ElementCode === 'FPLPerLocLimit') {
                    dataElement.Attributes.ElementCode = 'PerLocationLimit';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.MoneyResponse;
                  elementcache = dataElement;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.LookupList
                ) {
                  let objData = isPresent.DataElementResponses.find(function(
                    el
                  ) {
                    return (
                      el.DataElementResponseId ===
                      dataElement.Attributes.DataElementResponseId
                    );
                  });
                  // objData = isPresent.DataElementResponses[0];
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    objData !== undefined ? objData : {};
                  elementcache = dataElement;
                  // elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  // elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                  // resultQuoteData[dataElement.Attributes.ElementCode] =
                  //   dataElement.Attributes.DataElementResponseId;
                } else {
                  elementcache = dataElement;
                  //elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                  //this.quoteCacheDataElements
                }
              });
            }
            if (
              dataNestElement &&
              riskElement.Attributes.RiskTypeCode === RiskType.FCH
            ) {
              //this.quoteCacheDataElements = dataNestElement;
              dataNestElement.forEach((dataElement: FluffyDataElement) => {
                let isPresent = dataTypeElements.find(function(el) {
                  return el.ElementCode === dataElement.Attributes.ElementCode;
                });

                if (isPresent && isPresent.ResponseType === ElementName.Text) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.TextResponse;
                  elementcache = dataElement;
                  //  elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //  elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Integer &&
                  dataElement.Attributes.ElementCode !== 'RNoLocs'
                ) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.IntegerResponse;
                  elementcache = dataElement;
                  //  elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //  elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Decimal
                ) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.DecimalResponse;
                  elementcache = dataElement;
                  //  elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //  elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.LookupList
                ) {
                  let objData = isPresent.DataElementResponses.find(function(
                    el
                  ) {
                    return (
                      el.DataElementResponseId ===
                      dataElement.Attributes.DataElementResponseId
                    );
                  });
                  // objData = isPresent.DataElementResponses[0];
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    objData !== undefined ? objData : {};
                  elementcache = dataElement;
                  //  elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  //  elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                  // resultQuoteData[dataElement.Attributes.ElementCode] =
                  //   dataElement.Attributes.DataElementResponseId;
                } else {
                  elementcache = dataElement;
                  //  elementcache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  elementcache.QuoteNum = element.Attributes.QuoteNum;
                  // elementcache.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(elementcache);
                }
              });
            }
            if (
              riskNestcoverages &&
              riskElement.Attributes.RiskTypeCode === RiskType.LOB
            ) {
              riskNestcoverages.forEach((covergeElement: Coverages) => {
                var isPresent = coveragesTypes.find(function(el) {
                  return el.CovTypeId === covergeElement.Attributes.CovTypeId;
                });
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.RRI) {
                  resultQuoteData.PolicyAggr =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  resultQuoteData.InsRetention =
                    covergeElement.Attributes.PerOccurDed;
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.ASC
                ) {
                  resultQuoteData.SupContamination =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.WVA
                ) {
                  resultQuoteData.OverallAggr =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  resultQuoteData.PerOccLimit =
                    covergeElement.Attributes.PerOccurLimit;
                  resultQuoteData.Deductibles =
                    covergeElement.Attributes.PerOccurDed;
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.TER
                ) {
                  resultQuoteData.TRIAPrem =
                    covergeElement.Attributes.PremiumAmount;
                } else {
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                }
              });
            }
            if (
              riskNestcoverages &&
              riskElement.Attributes.RiskTypeCode === RiskType.FCH
            ) {
              riskNestcoverages.forEach((covergeElement: Coverages) => {
                var isPresent = coveragesTypes.find(function(el) {
                  return el.CovTypeId === covergeElement.Attributes.CovTypeId;
                });
                if (isPresent && isPresent.CovTypeCode === CoverageCodes.LGP) {
                  resultQuoteData.LostGrossProfitLimit =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.ICW
                ) {
                  resultQuoteData.IncresedCost =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.IV
                ) {
                  resultQuoteData.Inoculations =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.XXC
                ) {
                  resultQuoteData.ExtortionCost =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.ACO
                ) {
                  resultQuoteData.CrisisConsultExp =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  //resultQuoteData.Deductibles =
                  //covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.ACC
                ) {
                  resultQuoteData.Accidental =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  resultQuoteData.AccidentalPremium =
                    covergeElement.Attributes.PremiumAmount;
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.MTA
                ) {
                  resultQuoteData.Tampering =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.ADP
                ) {
                  resultQuoteData.AdversePub =
                    covergeElement.Attributes.AggregateLimit; //or else per occ
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.FPL
                ) {
                  resultQuoteData.FranchiseePolicyLimit =
                    covergeElement.Attributes.PremiumAmount;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else if (
                  isPresent &&
                  isPresent.CovTypeCode === CoverageCodes.WRK
                ) {
                  resultQuoteData.WorkplaceViolencePerLocation =
                    covergeElement.Attributes.PremiumAmount;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                } else {
                  coverageCache = covergeElement;
                  //  coverageCache.RiskType = riskElement.Attributes.RiskTypeCode;
                  //  coverageCache.QuoteNum = element.Attributes.QuoteNum;
                  this.quoteCacheCoverages.push(covergeElement);
                }
              });
            }
            this.QuoteContainer.push(QuoteContainerObj);
          });
          this.quoteAttributeCache.push(resultQuoteData);
        });
      }
      this.quoteCachedData = quoteData;
      return quoteData;
    }
  }

  getManualTaxesAndFees(
    payload: ManualTaxAndFeeRequest,
    doRefresh = false
  ): Observable<ManualTaxAndFeeDataList> {
    // if (!payload.PolicyTxnId) {
    //   return of(null);
    // }
    //debugger;
    const url = `${this.serviceBaseUrl}/ManualTaxAndFeeList/${payload.SubCompanyCode}/${payload.PolicyTxnId}/${payload.UWQuoteId}/${payload.LobId}`;

    return this.get<ManualTaxAndFeeResponse>(url).pipe(
      map(data => this.mapToGetManualTaxAndFeeData(data)),
      catchError(this.handleError)
    );
  }

  private mapToGetManualTaxAndFeeData(
    data: ManualTaxAndFeeResponse
  ): ManualTaxAndFeeDataList {
    //debugger;
    if (!data) return;
    if (data) {
      let TaxAndFeeDataList = new ManualTaxAndFeeDataList();
      TaxAndFeeDataList.data = data.TaxFeeList;

      //this.policySubmissionRRICached = policyTxData;
      return TaxAndFeeDataList;
    }
  }

  public mapToQuotePolicyFranchiseMem(
    data: Formbuilder,
    coveragesTypes: CoverageTypes[],
    dataTypeElements: InitialDataElements[]
  ): any {
    //if (!data) return;
    if (data) {
      this.quoteCacheCoverages = [];
      this.quoteCacheDataElements = [];
      // this.quoteCacheCoverages = [];
      //this.lobLevelRisks = [];
      this.quoteAttributeCache = [];
      //this.quoteCacheDataElements = [];
      this.quoteCachedData = [];
      let quoteData: Quote[] = [];
      let lobsData: Lobs[] = [];
      let riskData: Risks[] = [];
      this.QuoteContainer = [];
      this.quoteFormBuilderCache = data;
      // if (data) {
      if (has(data['Data'][0], 'Attributes')) {
        this.quoteGeneralCache =
          data['Data'][0]['Attributes']['PolicyTxn']['Attributes'];
        quoteData =
          data['Data'][0]['Attributes']['PolicyTxn']['Attributes']['Quotes'];
        let isExistElements =
          data['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
            'DataElements'
          ];
        if (isExistElements) {
          isExistElements.forEach(el => {
            if (el.Attributes.ElementCode in PolicyTxnDataElement) {
              //covElements.push(element);
              this.UWquoteId = el.Attributes['IntegerResponse'];
            }
          });
        }
      }
      quoteData.forEach((element: Quote) => {
        let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
        let resultQuoteData: genericObj = {
          PolicyAggr: null, //
          InsRetention: null, //
          ExtortionCost: null, //
          CrisisConsultExp: null, //
          LostGrossProfitLimit: null, //
          IncresedCost: null, //
          Inoculations: null, //
          Accidental: null, //
          AccidentalPremium: null,
          Tampering: null, //
          SupContamination: null, //
          AdversePub: null, //
          OverallAggr: null, //
          PerOccLimit: null, //
          Deductibles: null, //
          OneYrPremium: null,
          TwoYrPremium: null,
          WaitingPeriod: null,
          RecallExp: null,
          RehabExp: null,
          LGPIncRoyalties: null,
          LGPIndPeriod: null,
          WPVIndemPeriod: null,
          PremType: null,
          RNoLocs: null,
          TwoYrPrepaidFactor: 1.75,
          BasePrem: null,
          WPVPrem: null,
          TRIARate: null,
          TRIAPrem: null,
          PolicyTypeId: null,
          UWQuoteId: null,
          QuoteDescription: '',
          QuoteNum: null,
          PerLocationLimit: null,
          RecallExpPerLoc: null,
          RehabExpPerLoc: null,
          AccidentalPerLocation: null,
          TamperingPerLocation: null,
          SupContaminationPerLocation: null,
          AdversePubPerLocation: null,
          QuoteStatusCode: '',
          ProcessStatusCode: '',
          Premium: null,
          Taxes: null,
          IsRated: false,
          TermLength: null,
          PolicyEff: null,
          PolicyExp: null,
          //--
          FR: null,
          TaxFeeDscr1: null,
          TaxFeeAmnt1: null,
          TaxFeeDscr2: null,
          TaxFeeAmnt2: null,
          TaxFeeDscr3: null,
          TaxFeeAmnt3: null,
          TaxFeeDscr4: null,
          TaxFeeAmnt4: null,
          TaxFeeDscr5: null,
          TaxFeeAmnt5: null
        };

        if (has(element.Attributes.Lobs[0].Attributes, 'UwFees')) {
          const payload: ManualTaxAndFeeRequest = {
            PolicyTxnId: sessionStorage.policyTxnId,
            SubCompanyCode: 'RRI',
            UWQuoteId: this.UWquoteId,
            LobId: element.Attributes.Lobs[0].Attributes.LOBId
          };
          this.policyService
            .getManualTaxesAndFees(payload,false)
            .subscribe(response => {
              //  this.surplusLinesResponse = data.data;
               //debugger;
              if (
                element.Attributes.Lobs[0].Attributes.UwFees[0] != undefined
              ) {
                resultQuoteData.TaxFeeAmnt1 =
                  element.Attributes.Lobs[0].Attributes.UwFees[0];
                let TaxId =
                  element.Attributes.Lobs[0].Attributes.UwFees[0].Attributes
                    .TaxId;
                let selectedTaxFee = response.data.find(
                  obj => obj.TaxId === TaxId
                );
                resultQuoteData.TaxFeeDscr1 = {
                  TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                };
              }
              if (
                element.Attributes.Lobs[0].Attributes.UwFees[1] != undefined
              ) {
                resultQuoteData.TaxFeeAmnt2 =
                  element.Attributes.Lobs[0].Attributes.UwFees[1];
                let TaxId =
                  element.Attributes.Lobs[0].Attributes.UwFees[1].Attributes
                    .TaxId;
                let selectedTaxFee = response.data.find(
                  obj => obj.TaxId === TaxId
                );
                resultQuoteData.TaxFeeDscr2 = {
                  TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                };
              }

              if (
                element.Attributes.Lobs[0].Attributes.UwFees[2] != undefined
              ) {
                resultQuoteData.TaxFeeAmnt3 =
                  element.Attributes.Lobs[0].Attributes.UwFees[2];
                let TaxId =
                  element.Attributes.Lobs[0].Attributes.UwFees[2].Attributes
                    .TaxId;
                let selectedTaxFee = response.data.find(
                  obj => obj.TaxId === TaxId
                );
                resultQuoteData.TaxFeeDscr3 = {
                  TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                };
              }
              if (
                element.Attributes.Lobs[0].Attributes.UwFees[3] != undefined
              ) {
                resultQuoteData.TaxFeeAmnt4 =
                  element.Attributes.Lobs[0].Attributes.UwFees[3];
                let TaxId =
                  element.Attributes.Lobs[0].Attributes.UwFees[3].Attributes
                    .TaxId;
                let selectedTaxFee = response.data.find(
                  obj => obj.TaxId === TaxId
                );
                resultQuoteData.TaxFeeDscr4 = {
                  TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                };
              }

              if (
                element.Attributes.Lobs[0].Attributes.UwFees[4] != undefined
              ) {
                resultQuoteData.TaxFeeAmnt5 =
                  element.Attributes.Lobs[0].Attributes.UwFees[4];
                let TaxId =
                  element.Attributes.Lobs[0].Attributes.UwFees[4].Attributes
                    .TaxId;
                let selectedTaxFee = response.data.find(
                  obj => obj.TaxId === TaxId
                );
                resultQuoteData.TaxFeeDscr5 = {
                  TaxTypeDescription: selectedTaxFee.TaxTypeDescription
                };
              }
            });
        }

        resultQuoteData.PolicyEff = this.formatDate(
          this.quoteGeneralCache.PolicyEff
        );
        resultQuoteData.PolicyExp = this.formatDate(
          this.quoteGeneralCache.PolicyExp
        );
        let termObj = {};
        dataTypeElements.forEach(ele => {
          if (ele.ElementId === 29907) {
            if (this.quoteGeneralCache.DataElements) {
              let isExist = this.quoteGeneralCache.DataElements.find(
                el => el.Attributes.ElementId === 29907
              );
              if (isExist) {
                termObj = ele.DataElementResponses.find(
                  value =>
                    value.DataElementResponseId ==
                    isExist.Attributes.DataElementResponseId
                );
              }
            }
          }
        });
        resultQuoteData.TermLength = termObj;
        if (has(this.quoteGeneralCache, 'ProcessStatusCode')) {
          resultQuoteData.ProcessStatusCode = this.quoteGeneralCache.ProcessStatusCode;
        }
        if (has(element.Attributes, 'WrittenPrem')) {
          resultQuoteData.Premium = element.Attributes.WrittenPrem;
        }
        if (has(element.Attributes, 'WrittenTaxes')) {
          resultQuoteData.Taxes = element.Attributes.WrittenTaxes;
        }
        if (has(element.Attributes, 'IsRated')) {
          resultQuoteData.IsRated = element.Attributes.IsRated;
        }
        resultQuoteData.PolicyTypeId = element.Attributes.PolicyTxnId;
        resultQuoteData.UWQuoteId = element.Attributes.UWQuoteId;
        resultQuoteData.QuoteDescription = element.Attributes.QuoteDescription;
        resultQuoteData.QuoteNum = element.Attributes.QuoteNum;
        if (has(element.Attributes, 'QuoteStatusCode')) {
          resultQuoteData.QuoteStatusCode = element.Attributes.QuoteStatusCode;
        }
        // this.lobLevelRisks = riskResult;
        riskResult.forEach((riskElement: Risks) => {
          let QuoteContainerObj: QouteProductType = {
            QuoteId: null,
            RiskId: null,
            QuoteNum: null,
            DataElements: [],
            Coverages: [],
            WrittenPrem: null,
            WrittenTaxes: null
          };
          //Added on Mar 28 2021 for adding container
          QuoteContainerObj.QuoteId = element.Attributes.UWQuoteId;
          QuoteContainerObj.QuoteNum = element.Attributes.QuoteNum;
          if (has(element.Attributes, 'WrittenPrem')) {
            QuoteContainerObj.WrittenPrem = element.Attributes.WrittenPrem;
          }
          if (has(element.Attributes, 'WrittenTaxes')) {
            QuoteContainerObj.WrittenTaxes = element.Attributes.WrittenTaxes;
          }
          //QuoteContainerObj.RiskCodeType = riskElement.Attributes.RiskTypeCode;
          QuoteContainerObj.RiskId = riskElement.Attributes.UWRiskId;
          QuoteContainerObj.RiskCodeType = riskElement.Attributes.RiskTypeCode;
          QuoteContainerObj.DataElements = riskElement.Attributes.DataElements;
          QuoteContainerObj.Coverages = riskElement.Attributes.Coverages;
          //Ended on Mar 28 2021 for adding container
          // let riskNestcoverages = riskElement.Attributes.Coverages;
          // let dataNestElement = riskElement.Attributes.DataElements;
          if (
            QuoteContainerObj.DataElements &&
            riskElement.Attributes.RiskTypeCode === RiskType.LOB
          ) {
            // this.quoteCacheDataElements = dataNestElement;

            QuoteContainerObj.DataElements.forEach(
              (dataElement: FluffyDataElement) => {
                let objElement: FluffyDataElement;
                let isPresent = dataTypeElements.find(function(el) {
                  return el.ElementCode === dataElement.Attributes.ElementCode;
                });
                if (isPresent && isPresent.ResponseType === ElementName.Text) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.TextResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Integer
                ) {
                  if (dataElement.Attributes.ElementCode === '1YrPrem') {
                    dataElement.Attributes.ElementCode = 'OneYrPremium';
                  }
                  if (dataElement.Attributes.ElementCode === '2YrPrem') {
                    dataElement.Attributes.ElementCode = 'TwoYrPremium';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.IntegerResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Decimal
                ) {
                  if (
                    dataElement.Attributes.ElementCode === '2YrPrepaidFactor'
                  ) {
                    dataElement.Attributes.ElementCode = 'TwoYrPrepaidFactor';
                  }
                  if (dataElement.Attributes.ElementCode === '1YrPrem') {
                    dataElement.Attributes.ElementCode = 'OneYrPremium';
                  }
                  if (dataElement.Attributes.ElementCode === '2YrPrem') {
                    dataElement.Attributes.ElementCode = 'TwoYrPremium';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.DecimalResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Money
                ) {
                  if (dataElement.Attributes.ElementCode === 'FPLPerLocLimit') {
                    dataElement.Attributes.ElementCode = 'PerLocationLimit';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.MoneyResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.LookupList
                ) {
                  let objData = isPresent.DataElementResponses.find(function(
                    el
                  ) {
                    return (
                      el.DataElementResponseId ===
                      dataElement.Attributes.DataElementResponseId
                    );
                  });
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    objData !== undefined ? objData : {};
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(objElement);
                } else {
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.quoteCacheDataElements.push(objElement);
                  //this.quoteCacheDataElements
                }
              }
            );
          }
          if (
            QuoteContainerObj.Coverages &&
            riskElement.Attributes.RiskTypeCode === RiskType.LOB
          ) {
            //this.quoteCacheCoverages = riskNestcoverages;
            QuoteContainerObj.Coverages.forEach((covergeElement: Coverages) => {
              let objCoverage: Coverages;
              var isPresent = coveragesTypes.find(function(el) {
                return el.CovTypeId === covergeElement.Attributes.CovTypeId;
              });
              //---
              if (isPresent && isPresent.CovTypeCode === CoverageCodes.RRI) {
                resultQuoteData.PolicyAggr =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                resultQuoteData.InsRetention =
                  covergeElement.Attributes.PerOccurDed;
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              }
              //---
              else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACL
              ) {
                resultQuoteData.AccidentalPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.MTL
              ) {
                resultQuoteData.TamperingPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.SCL
              ) {
                resultQuoteData.SupContaminationPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.APL
              ) {
                resultQuoteData.AdversePubPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ASC
              ) {
                resultQuoteData.SupContamination =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.WVA
              ) {
                resultQuoteData.OverallAggr =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                resultQuoteData.Deductibles =
                  covergeElement.Attributes.PerOccurDed; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ICW
              ) {
                resultQuoteData.IncresedCost =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.LGP
              ) {
                resultQuoteData.LostGrossProfitLimit =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.IV
              ) {
                resultQuoteData.Inoculations =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.XXC
              ) {
                resultQuoteData.ExtortionCost =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACO
              ) {
                resultQuoteData.CrisisConsultExp =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACC
              ) {
                resultQuoteData.Accidental =
                  covergeElement.Attributes.AggregateLimit;
                resultQuoteData.AccidentalPremium =
                  covergeElement.Attributes.PremiumAmount; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.MTA
              ) {
                resultQuoteData.Tampering =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ADP
              ) {
                resultQuoteData.AdversePub =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACL
              ) {
                resultQuoteData.AccidentalPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.MTL
              ) {
                resultQuoteData.TamperingPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.SCL
              ) {
                resultQuoteData.SupContaminationPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.APL
              ) {
                resultQuoteData.AdversePubPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.WRK
              ) {
                resultQuoteData.PerOccLimit =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              } else {
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.quoteCacheCoverages.push(covergeElement);
              }
            });
          }
          this.QuoteContainer.push(QuoteContainerObj);
        });
        this.quoteAttributeCache.push(resultQuoteData);
      });
      // }
      this.quoteCachedData = quoteData;
      return quoteData;
    }
  }
  get GetQuoteContainer() {
    if (!this.QuoteContainer) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }

    return this.QuoteContainer;
  }

  private mapToQuotePolicyDataStep3(data: Formbuilder): any {
    if (data) {
      this.quoteAttributeCache = [];
      let quoteData: Quote[] = [];
      let lobsData: Lobs[] = [];
      let riskData: Risks[] = [];
      if (data) {
        if (has(data['Data'][0], 'Attributes')) {
          quoteData =
            data['Data'][0]['Attributes']['PolicyTxn']['Attributes']['Quotes'];
          quoteData.forEach((element: Quote) => {
            let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
            riskResult.forEach((riskElement: Risks) => {
              if (riskElement.Attributes.RiskTypeCode === RiskType.FCH) {
                riskData.push(riskElement);
              }
            });
            this.quoteCachedData = quoteData;
          });
        }
      }
      return riskData;
    }
  }

  get GetFormBuilder() {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }

    return this.quoteFormBuilderCache;
  }

  get GetPolicyLevelDataElement() {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    let PolicyDataElement = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    return PolicyDataElement;
  }

  updateDataElement(elementKey, UWQuoteId) {
    let oldCDataElements = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    let isOldElementExist;
    //const oldCDataElements = this.GetPolicyLevelDataElement;
    if (oldCDataElements) {
      isOldElementExist = oldCDataElements.find(function(el) {
        return el.Attributes.ElementCode === elementKey.ElementCode;
      });
    }
    if (!isOldElementExist) {
      let objelement = {};
      oldCDataElements = [];
      //isNewCovergaeExist.ResponseType = ElementName.Integer;
      objelement = this.requestConverterService.createIntegerField(
        elementKey,
        UWQuoteId
      );
      // let  this.requestConverterService.createIntegerField();
      let objElement = {
        Id: null,
        Type: 'DataElements',
        Attributes: objelement
      };
      // objCoverage.Attributes[Field] = Value;
      oldCDataElements.push(objElement);
      // (result.type = 'new'), (result.covid = isNewCovergaeExist.CovTypeId);
    } else {
      const index = oldCDataElements.findIndex(
        item => item.Attributes.ElementCode === elementKey.ElementCode
      );
      if (index > -1) {
        if (elementKey.ResponseType === ElementName.Integer) {
          //isOldElementExist.Attributes.ResponseType = ElementName.Integer;
          (oldCDataElements[index]['ElementId'] =
            oldCDataElements[index].Attributes.ElementId),
            (oldCDataElements[index].Attributes['IntegerResponse'] = UWQuoteId);
        }
      }
      // result.covid = isOldCoverageExist.CovTypeId;
    }

    this.quoteAttributeCache = [];
    let quoteData: Quote[] = [];
    if (this.quoteFormBuilderCache) {
      if (has(this.quoteFormBuilderCache['Data'][0], 'Attributes')) {
        quoteData = this.quoteFormBuilderCache['Data'][0]['Attributes'][
          'PolicyTxn'
        ]['Attributes']['Quotes'];
      }
      //debugger;
      quoteData = quoteData.filter(function(obj) {
        return obj.Attributes.UWQuoteId === UWQuoteId;
      });
      this.quoteFormBuilderCache['Data'][0]['Attributes']['PolicyTxn'][
        'Attributes'
      ]['Quotes'] = quoteData;
      this.quoteFormBuilderCache['Data'][0]['Attributes']['PolicyTxn'][
        'Attributes'
      ]['DataElements'] = oldCDataElements;
    }
    return this.quoteFormBuilderCache.Data;
    //this.dataElements.forEach(element => {});
  }

  UpdateFormBuilder(QuoteId, DataElement) {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    // let objElement = {
    //   Id: null,
    //   Type: 'DataElements',
    //   Attributes: objelement,
    //   ElementId: objelement['ElementId'],
    //   RiskType: Type,
    //   QuoteNum: QuoteNum
    // };
    let PolicyDataElement = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    //PolicyDataElement =
    let data = this.quoteFormBuilderCache.Data[0].Attributes.PolicyTxn
      .Attributes.Quotes;
    let data1 = data.filter(el => el.Attributes.UWQuoteId == QuoteId);
    // data.forEach(element => {
    //   delete element.QuoteNum;
    // });
    return this.quoteFormBuilderCache;
  }

  GetBindSummary(DataElements, Premium, Taxes, policytype) {
    if (!this.quoteGeneralCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    let termObj = [];
    let wpvSelect;
    let triaSelect;

    //GR Sept 2021 WI 19593
    let selectedQuote;
    //DataElementResponseId 46530 is "SelectedQuote"
    this.quoteGeneralCache.DataElements.forEach(ele => {
      if (ele.Attributes.DataElementResponseId === 46530) {
        selectedQuote = ele.Attributes['IntegerResponse'];
      }
    });

    //GR Sept 2021 WI 19593
    this.QuoteContainer.forEach(ele => {
      if (ele.QuoteId === selectedQuote) {

        if (policytype === PolicyTypes.Corporate) {
          ele.DataElements.forEach(el => {
              if (el.Attributes.ElementCode === 'TRIASelect') {
                triaSelect = el.Attributes.DataElementResponseId;
              }
              if (el.Attributes.ElementCode === 'WPVSelect') {
                wpvSelect = el.Attributes.DataElementResponseId;
              }
          });
        }

      }
    });

    DataElements.forEach(ele => {
      if (ele.ElementId === 29907) {
        let isExist = this.quoteGeneralCache.DataElements.find(
          el => el.Attributes.ElementId === 29907
        );
        if (isExist) {
          termObj = ele.DataElementResponses.filter(
            value =>
              value.DataElementResponseId ==
              isExist.Attributes.DataElementResponseId
          );
        }
      }
      if (ele.ElementCode === 'TRIASelect' && triaSelect) {
        // if (el && value) {
        let filterId = ele['DataElementResponses'].filter(
          el => el.DataElementResponseId === triaSelect
        );
        triaSelect = filterId[0]['ResponseName'];
      }
      if (ele.ElementCode === 'WPVSelect' && wpvSelect) {
        let filterId = ele['DataElementResponses'].filter(
          el => el.DataElementResponseId === wpvSelect
        );
        wpvSelect = filterId[0]['ResponseName'];
      }
    });
    let policyBindDetails = {
      TermLength: termObj,
      PolicyEff: this.formatDate(this.quoteGeneralCache.PolicyEff),
      PolicyExp: this.formatDate(this.quoteGeneralCache.PolicyExp),
      WpvSelect: wpvSelect === undefined ? 'Yes' : wpvSelect,
      TriaSelect: triaSelect === undefined ? 'Yes' : triaSelect,
      WrittenPrem: Premium === undefined ? 0 : Premium,
      WrittenTaxes: Taxes === undefined ? 0 : Taxes,
      WriitenTotal: Premium + Taxes
    };
    return policyBindDetails;
  }

  formatDate(element) {
    let date;
    if (element) {
      // let calDate = this.dateService.toLongISO(
      //   this.dateService.moment(element)
      // );
      date = new Date(element);
    } else {
      date = '';
    }
    return date;
  }

  get GetPolicyDetails() {
    if (!this.quoteGeneralCache) {
      throw new Error(
        'quote policy details must be fetched before it can be used.'
      );
    }
    return this.quoteGeneralCache;
  }

  setUWQuoteId(Id) {
    this.UWquoteId = Id;
  }

  getUWQuoteId() {
    return this.UWquoteId;
  }

  get GetFormBuilderCoverages() {
    if (!this.quoteCacheCoverages) {
      throw new Error(
        'coverages form builder data data must be fetched before it can be used.'
      );
    }

    return this.quoteCacheCoverages;
  }

  get GetFormBuilderDataElements() {
    if (!this.quoteCacheDataElements) {
      throw new Error(
        'Data Elements form builder data data must be fetched before it can be used.'
      );
    }

    return this.quoteCacheDataElements;
  }

  get GetFormBuilderQuote() {
    if (!this.quoteAttributeCache) {
      throw new Error('quote data must be fetched before it can be used.');
    }

    return this.quoteAttributeCache;
  }

  private handleError(err) {
    return throwError(err);
  }

  setRisks = {
    Id: null,
    Type: '',
    Attributes: {}
  };

  downloadTemplate(payload: any): Observable<any> {
    const url = `${this.serviceBaseUrl}/IssueQuote`;
    return this.put<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
}

export const TaxFeeModel = {
  taxFeeAmount1: {
    name: 'TaxFeeAmnt',
    type: ElementName.Money,
    freetextvalue: null,
    UWFeeId: 0,
    UWLOBId: 0,
    TaxId: 0,
    FeeAmt: 0,
    IsCarriedForward: false,
    IsDelete: false,
    moneyvalue: 0,
    rowNum: 1
  },
  taxFeeAmount2: {
    name: 'TaxFeeAmnt',
    type: ElementName.Money,
    freetextvalue: null,
    UWFeeId: 0,
    UWLOBId: 0,
    TaxId: 0,
    FeeAmt: 0,
    IsCarriedForward: false,
    IsDelete: false,
    moneyvalue: 0,
    rowNum: 2
  },
  taxFeeAmount3: {
    name: 'TaxFeeAmnt',
    type: ElementName.Money,
    freetextvalue: null,
    UWFeeId: 0,
    UWLOBId: 0,
    TaxId: 0,
    FeeAmt: 0,
    IsCarriedForward: false,
    IsDelete: false,
    moneyvalue: 0,
    rowNum: 3
  },
  taxFeeAmount4: {
    name: 'TaxFeeAmnt',
    type: ElementName.Money,
    freetextvalue: null,
    UWFeeId: 0,
    UWLOBId: 0,
    TaxId: 0,
    FeeAmt: 0,
    IsCarriedForward: false,
    IsDelete: false,
    moneyvalue: 0,
    rowNum: 4
  },
  taxFeeAmount5: {
    name: 'TaxFeeAmnt',
    type: ElementName.Money,
    freetextvalue: null,
    UWFeeId: 0,
    UWLOBId: 0,
    TaxId: 0,
    FeeAmt: 0,
    IsCarriedForward: false,
    IsDelete: false,
    moneyvalue: 0,
    rowNum: 5
  }
};
