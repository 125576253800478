export interface UpdateClaimRequest {
  ClaimId: number;
  ClaimTitle: string;
  CauseofLossId?: number;
  LossCountry: string;
  LossState?: number;
  LossCity: string;
  LossCountyId?: number;
  AltClaimNumber: string;
  NatureOfDamageId?: number;
  ReportedBy?: number;
  LossDescription: string;
  CaseTypeId?: number;
  CaseSubTypeId?: number;
  SeverityId?: number;
  IsClosedClaim: boolean;
  ClaimCloseReasonId?: number;
  SubCompanyCode: string;
  DataGroupId: number;
  ClaimHandler: number;
  ClaimsAttorney: number;
  InternalAdjuster?: number;
  ClaimEventInformation: ClaimEventInformation;
  ClaimDataElements: Object[];
}

export interface UpdateClaimResponse {
  IsUpdated?: boolean;
  Validations?: Array<any>;
  Exception?: string;
  Status?: string;
  Message?: string;
  ClaimEventId?: number;
}

export interface ClaimEventInformation {
  EventId?: any;
  EventDescription?: string;
  EventName: string;
  EventTypeId?: number;
  EventDateFrom?: string;
  EventDateTo?: string;
  CataStropheCode: string;
  AssociatedClaimIds?: any;
}

export interface SaveClaimEvent {
  ClaimEventInformation: ClaimEventInformation;
  SubCompanyCode: string;
}
