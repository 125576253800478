<form [formGroup]="frmGroup">
  <label
    [ngbTooltip]="toolTipText"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label }}
    <!-- <i
    *ngIf="fieldInfo.label.toString().length > 20"
    class="fa fa-info-circle label-mark"
    aria-hidden="true"
    ></i> -->
    <span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <hcc-custom-validation
    [validations]="fieldInfo?.validations"
    *ngIf="InputConfig?.selectConfig?.type != 'addon'; else addOnTemp"
  >
    <!-- <ngx-select-dropdown
      tabindex=""
      inputValidateRef
      [control]="control"
      [formGroup]="frmGroup"
      [multiple]="InputConfig.multiple"
      [formControlName]="control"
      [options]="InputConfig.selectConfig.options"
      [config]="InputConfig.selectConfig.optionConfig"
      [(_value)]="InputConfig.selectConfig.selectedOption"
      (change)="change($event.value)"
      [disabled]="InputConfig?.isDisabled || isDependentDropdown"
    ></ngx-select-dropdown> -->
    <ng-select
      [id]="fieldInfo.fieldName"
      [readonly]="InputConfig?.isDisabled || isDependentDropdown"
      [items]="InputConfig.selectConfig.options"
      inputValidateRef
      [appendTo]="InputConfig?.selectConfig?.appendTo"
      [bindLabel]="InputConfig?.selectConfig?.optionConfig?.displayKey"
      [control]="control"
      [formGroup]="frmGroup"
      [formControlName]="control"
      [multiple]="InputConfig.selectConfig?.multiple"
      [placeholder]="getPlaceHolder()"
      tabindex="0"
      [clearable]="true"
      [selectOnTab]="true"
      [virtualScroll]="true"
    ></ng-select>
  </hcc-custom-validation>

  <ng-template #addOnTemp>
    <hcc-custom-validation [validations]="fieldInfo?.validations">
      <input
        type="text"
        inputValidateRef
        list="one"
        [value]="value"
        acInputText
        [ngClass]="fieldInfo.class"
        [control]="control"
        [fieldInfo]="fieldInfo"
        [formControlName]="control"
        maxlength="350"
        class="form-control"
        [placeholder]="getPlaceHolder()"
      />
      <datalist id="one">
        <option
          class="data-list-opt"
          *ngFor="let data of InputConfig.selectConfig.options; let i = index"
        >
          {{ data[InputConfig?.selectConfig?.optionConfig?.displayKey] }}
        </option>
      </datalist>
    </hcc-custom-validation>
  </ng-template>
</form>
