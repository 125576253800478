export interface SubCompany {
  SubCompanyCode: string;
  SubCompanyName: string;
}

export interface SecurityUserGroup {
  SubCompany: SubCompany[];
}

export class SecurityUserGroupRequest {
  EmailId: string;
  SubCompanyCode: string;
  Components: string[];
}
export interface SecurityUserGroupResponse {
  MemberOfGroupCollection: MemberOfGroup[];
}
export interface MemberOfGroup {
  GroupId: number;
  GroupName: string;
  GrantedMenuCollection: GrantedMenu[];
}
export interface GrantedMenu {
  MenuId: number;
  GroupId: string;
  MenuName: string;
  Limit?: number;
  IsLimitValueAvailable: boolean;
  SubCompaniesCollection: SubCompany[];
}
