import { Injectable } from '@angular/core';
import { SeverityLevel } from './severity-level.model';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '../../../../global-services/app-config.service';

@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(private appConfig: AppConfigService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.appConfig.config.logging.appInsights
          .instrumentationKey,
        enableAutoRouteTracking: this.appConfig.config.logging.appInsights
          .enableAutoRouteTracking // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }
  logPageView(name?: string, url?: string) {
    if (
      this.appConfig.config &&
      this.appConfig.config.logging &&
      this.appConfig.config.logging.appInsightEnabled
    ) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  // Log non-exception type errors, e.g. invalid API request
  logError(error: any) {
    this.log(error, SeverityLevel.Error);
  }

  logWarning(error: any) {
    this.log(error, SeverityLevel.Warning);
  }

  logInfo(error: any) {
    this.log(error, SeverityLevel.Information);
  }

  logVerbose(error: any) {
    this.log(error, SeverityLevel.Verbose);
  }

  logCritical(error: any) {
    this.log(error, SeverityLevel.Critical);
  }
  private log(error: any, severityLevel?: SeverityLevel) {
    /// Need to remove dependency of SeverityLevel
    if (
      this.appConfig.config &&
      this.appConfig.config.logging &&
      this.appConfig.config.logging.console
    ) {
      this.sendToConsole(error, severityLevel);
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (
      this.appConfig.config &&
      this.appConfig.config.logging &&
      this.appConfig.config.logging.appInsightEnabled
    ) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  // Need to comment it out
  logException(exception: Error, properties?: { [key: string]: any }) {
    this.log(exception, SeverityLevel.Error);
    if (
      this.appConfig.config &&
      this.appConfig.config.logging &&
      this.appConfig.config.logging.appInsightEnabled
    ) {
      this.appInsights.trackException({
        exception: exception,
        properties: properties
      });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (
      this.appConfig.config &&
      this.appConfig.config.logging &&
      this.appConfig.config.logging.appInsightEnabled
    ) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }

  private sendToConsole(
    error: any,
    severityLevel: SeverityLevel = SeverityLevel.Error
  ) {
    switch (severityLevel) {
      case SeverityLevel.Critical:
      case SeverityLevel.Error:
        (<any>console).group(this.appConfig.config.appName + ' Error: ');
        if (error.message) {
          console.error(error.message);
        } else if (error.stack) {
          console.error(error.stack);
        } else {
          console.error(error);
        }
        (<any>console).groupEnd();
        break;
      case SeverityLevel.Warning:
        (<any>console).group(this.appConfig.config.appName + ' Error: ');
        console.warn(error);
        (<any>console).groupEnd();
        break;
      case SeverityLevel.Information:
        (<any>console).group(this.appConfig.config.appName + ' Error: ');
        console.log(error);
        (<any>console).groupEnd();
        break;
    }
  }
}
