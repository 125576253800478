import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import {
  GetPolicySubjectivitiesRequest,
  PolicySubjectivityResponse,
  SaveSubjectiviyRequest,
  SaveMiscellaneousChangesRequest,
  SaveMiscellaneousChangesResponse
} from './def/subjectivity';
import {
  SubjectivityGridRow,
  SubjectivityGrid,
  SaveMiscellaneousChangesModel
} from '@tmhcc-ng-lib/models';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class SubjectivityService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  saveSubjectivity(payload: SaveSubjectiviyRequest) {
    const url = `${this.serviceBaseUrl}/SaveSubjectivities`;

    return this.post<any>(url, { body: payload }).pipe();
  }

  getSubjectivity(
    payload: GetPolicySubjectivitiesRequest
  ): Observable<SubjectivityGrid> {
    const url = `${this.serviceBaseUrl}/GetPolicySubjectivities/${payload.PolicyTxnId}`;
    return this.get<PolicySubjectivityResponse>(url).pipe(
      map(data => this.mapToQuoteGridRow(data)),
      catchError(this.handleError)
    );
  }

  saveMiscellaneousChangesECO(
    payload: SaveMiscellaneousChangesRequest
  ): Observable<SaveMiscellaneousChangesModel> {
    const url = `${this.serviceBaseUrl}/SaveMiscellaneousChanges`;
    return this.post<SaveMiscellaneousChangesResponse>(url, {
      body: payload
    }).pipe(
      map(data => {
        return this.mapToSaveMiscellaneous(data);
      }),
      catchError(this.handleError)
    );
  }

  mapToQuoteGridRow(data: PolicySubjectivityResponse): SubjectivityGrid {
    let subjectivityGrid = new SubjectivityGrid();
    if (!data || !data.Subjectivities) return;
    if (data.Subjectivities) {
      let gridRows: SubjectivityGridRow[] = [];
      for (let index = 0; index < data.Subjectivities.length; index++) {
        const element = data.Subjectivities[index];

        let row = new SubjectivityGridRow();
        row.description = element.SubjectivityDescription;
        row.checked = element.IsResolved;
        row.policySubjectivityId = element.PolicySubjectivityId;
        //if(element.IsResolved){
        row.checkboxChecked = element.IsResolved;
        //}
        gridRows.push(row);
      }
      subjectivityGrid.subjectivityData = gridRows;
    }
    return subjectivityGrid;
  }

  mapToSaveMiscellaneous(
    data: SaveMiscellaneousChangesResponse
  ): SaveMiscellaneousChangesModel {
    let saveMiscellaneousChangesModel = new SaveMiscellaneousChangesModel();
    if (!data) return;
    if (data) {
      saveMiscellaneousChangesModel.PolicyId = data.PolicyId;
      saveMiscellaneousChangesModel.PolicyNum = data.PolicyNum;
      saveMiscellaneousChangesModel.PolicyTxnId = data.PolicyTxnId;
      saveMiscellaneousChangesModel.PolicyTxnNum = data.PolicyTxnNum;
      saveMiscellaneousChangesModel.TxnStatusCode = data.TxnStatusCode;
    }
    return saveMiscellaneousChangesModel;
  }

  private handleError(err) {
    return throwError(err);
  }
}
