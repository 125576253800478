import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ClaimContact, CoverageStatus } from '@tmhcc-ng-lib/models';
import { isEmpty } from 'lodash';
import { catchError, map } from 'rxjs/operators';
import {
  RewritePolicy,
  RewriteResponsePolicy
} from 'libs/models/classes/rewrite-policy.model';
import { CreateActivityLog } from './def/coverage-policies';

@Injectable()
export class RriCoverageStatusService extends BaseDataService {
  private contactCached: ClaimContact[];
  private isContactAvailable: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  isclaimDataReady = this.isContactAvailable.asObservable();
  rewriteResponsePolicy: RewriteResponsePolicy;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  activatePolicy(payload: CoverageStatus): Observable<any> {
    const url = `${this.serviceBaseUrl}/ReactivatePolicy`;
    return this.put<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  deactivatePolicy(payload: CoverageStatus): Observable<any> {
    const url = `${this.serviceBaseUrl}/DeclinePolicy`;
    return this.put<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  reWritePolicy(payload: RewritePolicy): Observable<any> {
    const url = `${this.serviceBaseUrl}/CreateRewrite`;

    return this.post<RewriteResponsePolicy>(url, { body: payload }).pipe(
      map(data => {
        this.rewriteResponsePolicy = data;
        return this.rewriteResponsePolicy;
      }),
      catchError(this.handleError)
    );
  }

  activitylogitem(payload: CreateActivityLog): Observable<any> {
    const url = `${this.serviceBaseUrl}/CreateActivityLogItem`;
    return this.post<any>(url, { body: payload }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  activitylogitemV2(payload: CreateActivityLog): Promise<any> {
    const url = `${this.serviceBaseUrl}/CreateActivityLogItem`;
    return this.post<any>(url, { body: payload }).toPromise();
  }
  private handleError(err) {
    return throwError(err);
  }
}
