<!--Header Start Here-->
<header class="header">
  <div id="rri-header" >
    <div class="top-header">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-end">
            <!-- Example single danger button -->
            <div class="">
              <div class="btn-group profile-dropdown">
                <a
                  href="javascript:void(0);"
                  class="btn dropdown-toggle header-user-name upperCase"
                  aria-haspopup="true"
                  aria-expanded="false"
                  (click)="showLogout = true; popupLogoutOnClick = true"
                >
                  Welcome
                  <span id="headerUserName">{{ loggedInUser }}</span>
                  <i
                    aria-hidden="true"
                    class="fa fa-chevron-down custom-icon"
                  ></i>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  [ngClass]="{ show: showLogout }"
                  (clickOutside)="closeLogoutPopup($event)"
                >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    (click)="logout()"
                  >
                    <em class="fa fa-sign-out"></em>
                    <span>
                      Logout
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light custom-navbar">
        <a href="#" class="navbar-brand"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarCollapse"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                href="javascript:void(0);"
                (click)="redirectToDashboard()"
                class="nav-link active"
                >Dashboard</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

  <section class="login-label">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h1>Product Contamination</h1>
        </div>
      </div>
    </div>
  </section>
</header>
<!--/. Header End Here-->
<ac-loader *ngIf="isLoading" [loaderMessage]="'Loading..'"></ac-loader>
<!-- <ac-custom-popup-alert></ac-custom-popup-alert> -->
<router-outlet></router-outlet>
