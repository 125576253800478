import { Moment } from '@tmhcc-ng-lib/framwork/services';
export class CreateNewQuoteModel {
  'InsuredContact': InsuredContact;
  'NewBusinessSub': NewBusinessSub;
}

export class QuoteCreateResult {
  'InsuredContact': InsuredContact;
  'NewBusinessSub': NewBusinessSub;
}

class QuoteCreateResponse {
  quoteCreateResults: QuoteCreateResult[];
}

export class InsuredContact {
  'ContactId': string;
  'NameTypeId': number;
  'OrgFullName': string;
  'EntityTypeId': number;
  'Addresses': Address;
}
export class NewBusinessSub {
  'PolicyId': number;
  'PolicyTxnId': number;
  'PolicyTxnNum': number;
  'PolicyTermNum': number;
  'InsuredContactId': number;
  'PolicyProducerContactId': number;
  'SubCompanyCode': string;
  'PolicyTypeId': number;
  'TermTypeId': number;
  'PolicyDescription': string;
  'User1Id': number;
  'User2Id': number;
  'NextRenewalOptionId': number;
  'SubmissionTypeId': number;
  'PolicyYear': number;
  'CountryCode': string;
  'StateId': number;
  'CurrencyCode': string;
  'TxnPayPlanId': number;
  'TxnBillToContactId': number;
  'PolicyEff': string;
  'CreateActivityLogItem': CreateActivityLogItem;
}
export class CreateActivityLogItem {
  'ActivityType': string;
  'CreationDate': Date;
  'Description': string;
  'ActivityDate': Date;
  'LoggedInUser': string;
  'PolicyTxnId': number;
}

export class Address {
  'AddressId': number;
  'CountryCode': string;
  'ZipCode': string;
  'StreetAddress': string;
  'StateCode': string;
  'City': string;
  'AddressTypes': any[];
}
export class GeneralInfo {
  'quoteNumber': string;
  'PolicyId': number;
  'PolicyTxnId': number;
  'PolicyTxnNum': number;
  'PolicyTermNum': number;
  'InsuredContactId': number;
  'PolicyProducerContactId': number;
  'SubCompanyCode': string;
  'PolicyTypeId': number;
  'TermTypeId': number;
  'PolicyDescription': string;
  'User1Id': number;
  'User2Id': number;
  'NextRenewalOptionId': number;
  'SubmissionTypeId': number;
  'PolicyYear': number;
  'CountryCode': string;
  'StateId': number;
  'CurrencyCode': string;
  'TxnPayPlanId': number;
  'TxnBillToContactId': number;
  'PolicyEff': Date;
  'insuredAddressId': number;
  'InsuredName': string;
}
