import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  Formbuilder,
  Coverages,
  FluffyDataElement,
  Quote,
  Risks,
  RiskType,
  QouteProductType,
  PolicyTxnAttributes,
  Lobs,
  LobAttributes
} from '@tmhcc-ng-lib/data-service';
import { has } from '@tmhcc/util';
import { UwFee } from '@tmhcc-product-contamination/framwork/data-element-builder';
@Injectable()
export class CoverageMappingService {
  //labels: any;
  constructor() {}
  SetFranchiseFormBuilderValue(
    result: Formbuilder,
    quoteElements: QouteProductType[],
    QuoteNum: number,
    quoteInfoSummaryData: any = null,
    taxAndFeeData: any = null
  ) {
    //debugger;
    if (result) {
      let quoteData: Quote[] = [];
      let lobsAttributesData: LobAttributes;
      if (result) {
        if (has(result['Data'][0], 'Attributes')) {
          quoteData =
            result['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
              'Quotes'
            ];
          let policyTxnAttributes: PolicyTxnAttributes =
            result['Data'][0]['Attributes']['PolicyTxn']['Attributes'];
          result['Data'][0]['Attributes']['PolicyTxn'][
            'Attributes'
          ] = this.updateExpEffDataAndTermLength(
            policyTxnAttributes,
            quoteInfoSummaryData
          );
        }
        //debugger;
        quoteData = quoteData.filter(function(obj) {
          return obj.Attributes.QuoteNum === QuoteNum;
        });

        lobsAttributesData =
          quoteData[0]['Attributes']['Lobs'][0]['Attributes'];

        let lobsDataWithUvFee: LobAttributes = {
          ActualPrem: lobsAttributesData.ActualPrem,
          AnnualPrem: lobsAttributesData.AnnualPrem,
          ContractMasterId: lobsAttributesData.ContractMasterId,
          CountryCode: lobsAttributesData.CountryCode,
          CurrencyCode: lobsAttributesData.CurrencyCode,
          IsDefaultFeesComplete: lobsAttributesData.IsDefaultFeesComplete,
          IsDefaultFormsComplete: lobsAttributesData.IsDefaultFormsComplete,
          IsSubjectToAudit: lobsAttributesData.IsSubjectToAudit,
          IsUserSeqENForms: lobsAttributesData.IsUserSeqENForms,
          LOBId: lobsAttributesData.LOBId,
          LOBTrackingNum: lobsAttributesData.LOBTrackingNum,
          PolicyProducerComms: lobsAttributesData.PolicyProducerComms,
          Risks: lobsAttributesData.Risks,
          StateId: lobsAttributesData.StateId,
          UWLOBId: lobsAttributesData.UWLOBId,
          WrittenAccretion: lobsAttributesData.WrittenAccretion,
          WrittenPrem: lobsAttributesData.WrittenAccretion,
          WrittenTaxes: lobsAttributesData.WrittenTaxes,
          UwFees: taxAndFeeData
        };
        // lobsData.push(taxAndFeeData);
        //--taxAndFeeData
        quoteData[0]['Attributes']['Lobs'][0]['Attributes'] = lobsDataWithUvFee;
        //--

        result['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
          'Quotes'
        ] = quoteData;
        quoteData.forEach((element: Quote) => {
          let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
          riskResult.forEach((riskElement: Risks) => {
            let index = quoteElements.findIndex(function(el: QouteProductType) {
              return el.QuoteNum === QuoteNum && el.RiskId === riskElement.Id;
            });
            let dataElement = [];
            let dataCoverage = [];
            if (index > -1) {
              dataElement = quoteElements[index].DataElements;
              dataCoverage = quoteElements[index].Coverages;
            }
            if (!riskElement.Attributes.DataElements) {
              riskElement.Attributes['DataElements'] = [];
            }
            if (riskElement.Attributes.RiskTypeCode === RiskType.LOB) {
              if (dataElement) {
                riskElement.Attributes.DataElements = dataElement;
              }
            }
            if (riskElement.Attributes.RiskTypeCode === RiskType.LOB) {
              if (dataCoverage) {
                riskElement.Attributes.Coverages = dataCoverage;
              }
            }
          });
        });
      }
      return result.Data;
    }
  }
  updateExpEffDataAndTermLength(
    policyTxnAttributes: PolicyTxnAttributes,
    quoteInfoSummaryData
  ) {
    if (quoteInfoSummaryData) {
      if (has(policyTxnAttributes, 'PolicyEff')) {
        policyTxnAttributes.PolicyEff = quoteInfoSummaryData.PolicyEff;
        policyTxnAttributes.PolicyExp = quoteInfoSummaryData.PolicyExp;
      }

      if (
        policyTxnAttributes.TxnTypeCode == 'EN' &&
        policyTxnAttributes.TxnStatusCode == 'PE'
      ) {
        if (has(policyTxnAttributes, 'TxnEff')) {
          policyTxnAttributes.TxnEff = quoteInfoSummaryData.TxnEff;
        }
      }
      if (
        has(policyTxnAttributes, 'DataElements') &&
        policyTxnAttributes.DataElements.length > 0
      ) {
        policyTxnAttributes.DataElements = [
          ...policyTxnAttributes.DataElements
        ].map(data => {
          const element: any = data.Attributes;
          if (element.ElementCode === 'TermLength') {
            data.Attributes.DataElementResponseId =
              quoteInfoSummaryData.TermLength.DataElementResponseId;
            data.Attributes.TextResponse =
              quoteInfoSummaryData.TermLength.ResponseValue;
          }
          return data;
        });
      }
    }
    return policyTxnAttributes;
  }
  SetCorpFormBuilderValue(
    data: Formbuilder,
    quoteElements: QouteProductType[],
    QuoteNum: number,
    quoteInfoSummaryData: any = null,
    taxAndFeeData: any = null
  ) {
    // debugger;
    let result = data;
    if (result) {
      let quoteData: Quote[] = [];
      let lobsAttributesData: LobAttributes;
      if (result) {
        if (has(result['Data'][0], 'Attributes')) {
          quoteData =
            result['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
              'Quotes'
            ];
          let policyTxnAttributes: PolicyTxnAttributes =
            result['Data'][0]['Attributes']['PolicyTxn']['Attributes'];
          result['Data'][0]['Attributes']['PolicyTxn'][
            'Attributes'
          ] = this.updateExpEffDataAndTermLength(
            policyTxnAttributes,
            quoteInfoSummaryData
          );
        }

        quoteData = quoteData.filter(function(obj) {
          return obj.Attributes.QuoteNum === QuoteNum;
        });
        //--
        lobsAttributesData =
          quoteData[0]['Attributes']['Lobs'][0]['Attributes'];

        let lobsDataWithUvFee: LobAttributes = {
          ActualPrem: lobsAttributesData.ActualPrem,
          AnnualPrem: lobsAttributesData.AnnualPrem,
          ContractMasterId: lobsAttributesData.ContractMasterId,
          CountryCode: lobsAttributesData.CountryCode,
          CurrencyCode: lobsAttributesData.CurrencyCode,
          IsDefaultFeesComplete: lobsAttributesData.IsDefaultFeesComplete,
          IsDefaultFormsComplete: lobsAttributesData.IsDefaultFormsComplete,
          IsSubjectToAudit: lobsAttributesData.IsSubjectToAudit,
          IsUserSeqENForms: lobsAttributesData.IsUserSeqENForms,
          LOBId: lobsAttributesData.LOBId,
          LOBTrackingNum: lobsAttributesData.LOBTrackingNum,
          PolicyProducerComms: lobsAttributesData.PolicyProducerComms,
          Risks: lobsAttributesData.Risks,
          StateId: lobsAttributesData.StateId,
          UWLOBId: lobsAttributesData.UWLOBId,
          WrittenAccretion: lobsAttributesData.WrittenAccretion,
          WrittenPrem: lobsAttributesData.WrittenAccretion,
          WrittenTaxes: lobsAttributesData.WrittenTaxes,
          UwFees: taxAndFeeData
        };
        // lobsData.push(taxAndFeeData);
        //--taxAndFeeData
        quoteData[0]['Attributes']['Lobs'][0]['Attributes'] = lobsDataWithUvFee;
        //--
        result['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
          'Quotes'
        ] = quoteData;
        quoteData.forEach((element: Quote) => {
          let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
          riskResult.forEach((riskElement: Risks) => {
            let index = quoteElements.findIndex(function(el: QouteProductType) {
              return el.QuoteNum === QuoteNum && el.RiskId === riskElement.Id;
            });
            let dataElement = [];
            let dataCoverage = [];
            if (index > -1) {
              dataElement = quoteElements[index].DataElements;
              dataCoverage = quoteElements[index].Coverages;
            }
            if (riskElement.Attributes.RiskTypeCode === RiskType.LOB) {
              if (dataElement) {
                riskElement.Attributes.DataElements = dataElement;
              }
              if (dataCoverage) {
                riskElement.Attributes.Coverages = dataCoverage;
              }
            }
            if (riskElement.Attributes.RiskTypeCode === RiskType.FCH) {
              if (dataElement) {
                riskElement.Attributes.DataElements = dataElement;
              }
              if (dataCoverage) {
                riskElement.Attributes.Coverages = dataCoverage;
              }
            }
          });
        });
      }
      return result.Data;
    }
  }
}
