import {
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { InputBase } from '../input-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { has } from '@tmhcc/util';
@Component({
  selector: 'hcc-select',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectListComponent),
      multi: true
    }
  ]
})
export class SelectListComponent extends InputBase<string>
  implements OnInit, AfterViewInit {
  constructor() {
    super();
  }

  @ViewChild('ngSelect', { static: true }) NgSelect: NgSelectComponent;

  isDependentDropdown = false;
  toolTipText = '';
  ngOnInit() {
    // make value null when value is empty string
    // if (this.frmGroup.get(this.control).value === '') {
    //   this.frmGroup.get(this.control).setValue('');
    // }
    if (has(this.fieldInfo, 'label') && this.fieldInfo.label.length > 200) {
      this.toolTipText = this.fieldInfo.label;
    }
    if (this.InputConfig.selectConfig.hasOwnProperty('optionConfig')) {
      this.InputConfig.selectConfig.optionConfig['height'] = '250px';
    }
    if (
      this.InputConfig.selectConfig.hasOwnProperty('options') &&
      this.InputConfig.selectConfig.options &&
      this.InputConfig.selectConfig.options.length === 1
    ) {
      if (this.InputConfig.selectConfig.options[0]) {
        setTimeout(() => {
          this.frmGroup
            .get(this.fieldInfo.fieldName)
            .setValue(this.InputConfig.selectConfig.options[0]);
        }, 10);
      }
    }
    if (this.InputConfig.selectConfig.hasOwnProperty('dependent')) {
      const dependentMapper = this.InputConfig.selectConfig.dependent.mapper;
      this.isDependentDropdown = false;
      const mapperValue = this.frmGroup.get(
        this.InputConfig.selectConfig.dependent.field
      ).value;
      this.dependentSelectBoxMapper(mapperValue, dependentMapper);
      if (this.frmGroup.value) {
        this.frmGroup
          .get(this.InputConfig.selectConfig.dependent.field)
          .valueChanges.subscribe(data => {
            this.dependentSelectBoxMapper(data, dependentMapper);
          });
      }
    }
  }

  dependentSelectBoxMapper(data, dependentMapper) {
    if (data && dependentMapper) {
      for (let item = 0; item <= dependentMapper.value.length; item++) {
        if (data[dependentMapper.key] === dependentMapper.value[item]) {
          this.isDependentDropdown = false;
          break;
        } else {
          // this.frmGroup.get(this.fieldInfo.fieldName).setValue('');
          this.InputConfig.selectConfig.selectedOption = [];
          this.isDependentDropdown = false;
        }
      }
    }
  }

  getPlaceHolder(): string {
    if (this.InputConfig.selectConfig.placeholder) {
      return this.InputConfig.selectConfig.placeholder;
    } else {
      return 'Please select';
    }
  }
}
