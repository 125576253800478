import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import {
  ClaimSearchRequest,
  ClaimSearchResponse,
  ClaimEventSearch,
  ClaimEventSearchResponse,
  AddEventRequest,
  AddEventResponse
} from './def/claim-search';
import { ClaimGridRow, ClaimEventSearchData } from '@tmhcc-ng-lib/models';
import { map, catchError } from 'rxjs/operators';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import { of } from 'rxjs';
import { has } from '@tmhcc/util';

@Injectable()
export class ClaimSearchService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  searchClaim(payload: ClaimSearchRequest): Observable<ClaimGridRow[]> {
    const url = `${this.serviceBaseUrl}/ClaimSearch`;

    return this.post<ClaimSearchResponse>(url, { body: payload }).pipe(
      map(data => this.mapToClaimGridRow(data)),
      catchError(this.handleError)
    );
  }

  searchForClaimEvent(payload: ClaimEventSearch): Observable<any> {
    const url = `${this.serviceBaseUrl}/ClaimEventSearch`;
    // return this.post<ClaimEventSearchResponse>(url, { body: payload }).pipe(
    //   map(data => this.mapToClaimEventSearch(data)),
    //   catchError(this.handleError)
    // );
    return this.post<ClaimEventSearchResponse>(url, { body: payload });
  }

  saveEvent(payload: AddEventRequest): Observable<AddEventResponse> {
    const url = `${this.serviceBaseUrl}/ClaimAddEvent`;

    return this.post<AddEventResponse>(url, { body: payload }).pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }
  // searchForClaimEvent(payload: ClaimEventSearch): Observable<any> {
  //   const url = `${this.serviceBaseUrl}/ClaimEventSearch`;
  //   return this.post<ClaimEventSearchResponse>(url, { body: payload });
  // }

  private mapToClaimGridRow(data: ClaimSearchResponse): ClaimGridRow[] {
    if (!data || !data.claimSearchResults) return [];

    let claimGridRows: ClaimGridRow[] = [];

    for (let index = 0; index < data.claimSearchResults.length; index++) {
      const element = data.claimSearchResults[index];

      let row = new ClaimGridRow();
      row.ClaimId = element.ClaimId;
      row.ClaimNum = element.ClaimNum;
      row.PolicyNum = element.PolicyNum;
      row.ClaimStatus = element.ClaimStatus;
      row.ClaimHandler = element.ClaimHandler;
      row.ClaimantName = element.ClaimantName;
      row.ClaimTitle = element.ClaimTitle || '';
      row.InsuredName = element.InsuredName;
      row.ClaimAttorney = element.ClaimAttorney;
      row.RelatedClaimCount = element.RelatedClaimCount;

      if (!this.dateService.isValidDate(element.LossDate)) {
        throw new Error(this.commonContentService.getErrors().InvalidLossDate);
      }
      row.LossDate = this.dateService.momentFromLongISO(element.LossDate);

      claimGridRows.push(row);
    }

    return claimGridRows;
  }

  mapToClaimEventSearch(data: ClaimEventSearchResponse): ClaimEventSearchData {
    let claimData: ClaimEventSearchData = {
      Status: '',
      Validations: [],
      AssociateEventClaims: [],
      Events: []
    };
    if (data) {
      if (has(data, 'claimEventMasterData')) {
        data['claimEventMasterData'].forEach(element => {
          claimData.AssociateEventClaims.push({
            ClaimNum: element.ClaimNum,
            ClaimEntryType: element.ClaimEntryType,
            CauseOfLoss: has(element, 'CauseOfLoss') ? element.CauseOfLoss : '',
            ClaimStatus: has(element, 'ClaimStatus') ? element.ClaimStatus : '',
            LossDate: element.LossDate,
            PolicyNum: has(element, 'PolicyNum') ? element.PolicyNum : '',
            RiskDescription: has(element, 'RiskDescription')
              ? element.RiskDescription
              : '',
            Claimant: has(element, 'Claimant') ? element.Claimant : '',
            ClaimMadeDate: has(element, 'ClaimMadeDate')
              ? element.ClaimMadeDate
              : '',
            Insured: has(element, 'InsuredFileAsName')
              ? element.InsuredFileAsName
              : '',
            ClaimEventID: has(element, 'ClaimEventID')
              ? element.ClaimEventID
              : 0,
            FileAsName: has(element, 'FileAsName') ? element.FileAsName : ''
          });
        });
      }

      if (has(data, 'eventSearchResult')) {
        data['eventSearchResult'].forEach(element => {
          claimData.Events.push({
            CatCode: element.CatCode,
            ClaimEventDesc: element.ClaimEventDesc,
            ClaimEventID: element.ClaimEventID,
            ClaimEventName: element.ClaimEventName,
            ClaimEventType: element.ClaimEventType,
            EventDateFrom: element.EventDateFrom,
            EventDateTo: element.EventDateTo,
            Location: element.Location
          });
        });
      }
    }
    return claimData;
  }

  private handleError(err): Observable<any> {
    return of(throwError(err));
  }
}
