export const enum FieldTypes {
  Text = 'Text',
  Numeric = 'Numeric',
  Currency = 'Currency',
  Date = 'Date',
  DateTime = 'DateTime',
  Boolean = 'Boolean',
  HyperLink = 'HyperLink',
  StaticList = 'StaticList',
  LookupList = 'LookupList',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Textarea = 'Textarea',
  Email = 'Email',
  Readonly = 'Readonly',
  ToggleSwitch = 'ToggleSwitch',
  Search = 'Search',
  Link = 'Link',
  ComboBox = 'ComboBox',
  SpacerControl='SpacerControl'
}
