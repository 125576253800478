import { Injectable } from '@angular/core';
import {
  InitialDataElements,
  ElementUpdateRequests,
  ElementResponse
} from '../utility/data-element.def';
import { has } from '@tmhcc/util';
import { ElementName, ElementRequestName } from '../utility/deb-utility.def';
import { DateService } from '@tmhcc-ng-lib/framwork/services';

@Injectable({
  providedIn: 'root'
})
export class RequestElementConverterService {
  constructor(private dateservice: DateService) {}
  dataElements;
  requestData: ElementUpdateRequests;
  buildDataField(data: InitialDataElements[], value: any) {
    this.dataElements = [];
    data.forEach(element => {
      let obj;
      if (
        has(value, element.ElementId.toString()) &&
        Array.isArray(value[element.ElementId.toString()]) //&&
        //value[element.ElementId.toString()].length > 0
      ) {
        obj = this.fieldConverter(element, value[element.ElementId.toString()]);
        this.dataElements.push(obj);
      } else if (
        has(value, element.ElementId.toString()) //&&
        //value[element.ElementId.toString()]
      ) {
        obj = this.fieldConverter(element, value[element.ElementId.toString()]);
        this.dataElements.push(obj);
      } else if (
        has(value, element.ElementId.toString() + '_CMB_ddl') //&&
        //value[element.ElementId.toString() + '_CMB_ddl']
      ) {
        const combinationVal = {
          combinationDId:
            value[element.ElementId.toString() + '_CMB_ddl']
              .DataElementResponseId,
          combinationTxt: value[element.ElementId.toString() + '_CMB_txt']
        };
        obj = this.fieldConverter(element, combinationVal);
        this.dataElements.push(obj);
      }
    });
    return this.dataElements;
  }

  createCheckBoxField(data: InitialDataElements, value) {
    const checkboxField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.CB,
      FreeTextValue: '',
      BooleanValue: value ? true : false
    };
    if (has(data, 'RowNum')) {
      checkboxField.RowNum = data.RowNum;
      checkboxField.DataGroupName = data.DataGroupName;
      checkboxField.ElementName = data.ElementName;
    }
    return checkboxField;
  }

  createTextField(data: InitialDataElements, value) {
    const textField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.TXT,
      FreeTextValue: value ? value : ''
    };
    if (has(data, 'RowNum')) {
      textField.RowNum = data.RowNum;
      textField.DataGroupName = data.DataGroupName;
      textField.ElementName = data.ElementName;
    }
    return textField;
  }

  createRadioField(data: InitialDataElements, value) {
    const selectedRadioElem = [];
    const radioField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.RB,
      FreeTextValue: '',
      BooleanValue: value ? true : false
    };

    if (has(data, 'RowNum')) {
      radioField.RowNum = data.RowNum;
      radioField.DataGroupName = data.DataGroupName;
      radioField.ElementName = data.ElementName;
    }

    const objIndex = data.DataElementResponses.findIndex(
      obj => obj.ResponseName === value
    );
    if (has(data.DataElementResponses[objIndex], 'DataElementResponseId')) {
      selectedRadioElem.push(
        data.DataElementResponses[objIndex].DataElementResponseId
      );
      radioField.SelectedDataElementResponseIds = selectedRadioElem;
    } else {
      radioField.SelectedDataElementResponseIds = [];
    }
    // console.log(radioField, '---------radio');
    // selectedRadioElem = data.DataElementResponses.filter(item => item.ResponseName === value);
    // if(selectedRadioElem.length) {
    //   radioField.SelectedDataElementResponseIds.push()
    // }
    return radioField;
  }

  createMultiLookupField(data: InitialDataElements, value) {
    const selectedElement = [];
    if (value) {
      value.forEach((element: ElementResponse) => {
        selectedElement.push(element.DataElementResponseId);
      });
    }
    const lookupMultiField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.MSC,
      FreeTextValue: '',
      SelectedDataElementResponseIds: selectedElement
    };
    if (has(data, 'RowNum')) {
      lookupMultiField.RowNum = data.RowNum;
      lookupMultiField.DataGroupName = data.DataGroupName;
      lookupMultiField.ElementName = data.ElementName;
    }
    return lookupMultiField;
  }

  createLookupField(data: InitialDataElements, value) {
    const selectedElement = [];
    if (value) {
      selectedElement.push(value.DataElementResponseId);
    }
    const lookupField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.SLT,
      FreeTextValue: '',
      SelectedDataElementResponseIds: selectedElement
    };
    if (has(data, 'RowNum')) {
      lookupField.RowNum = data.RowNum;
      lookupField.DataGroupName = data.DataGroupName;
      lookupField.ElementName = data.ElementName;
    }
    return lookupField;
  }

  createIntegerField(data: InitialDataElements, value) {
    const integerField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.INT,
      FreeTextValue: '',
      IntegerValue: value ? value : ''
    };
    if (has(data, 'RowNum')) {
      integerField.RowNum = data.RowNum;
      integerField.DataGroupName = data.DataGroupName;
    }
    return integerField;
  }

  createDecimalField(data: InitialDataElements, value) {
    const decimalField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.DEC,
      FreeTextValue: '',
      DecimalValue: value ? value : ''
    };
    if (has(data, 'RowNum')) {
      decimalField.RowNum = data.RowNum;
      decimalField.DataGroupName = data.DataGroupName;
      decimalField.ElementName = data.ElementName;
    }
    return decimalField;
  }

  createMoneyField(data: InitialDataElements, value) {
    const moneyField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.MNY,
      FreeTextValue: '',
      MoneyValue: value ? value : ''
    };
    if (has(data, 'RowNum')) {
      moneyField.RowNum = data.RowNum;
      moneyField.DataGroupName = data.DataGroupName;
      moneyField.ElementName = data.ElementName;
    }
    return moneyField;
  }

  createCombinationField(data: InitialDataElements, value) {
    const lookupMultiField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.CMB,
      FreeTextValue: '',
      SelectedDataElementResponseIds: value.combinationDId
        ? [value.combinationDId]
        : []
    };
    if (has(data, 'RowNum')) {
      lookupMultiField.RowNum = data.RowNum;
      lookupMultiField.DataGroupName = data.DataGroupName;
      lookupMultiField.ElementName = data.ElementName;
    }
    return lookupMultiField;
  }

  createDateField(data: InitialDataElements, value) {
    const dateField: ElementUpdateRequests = {
      DataGroupId: data.DataGroupId,
      IsArray: data.GroupIsArray,
      ElementId: data.ElementId,
      ResponseType: ElementRequestName.DT,
      FreeTextValue: '',
      DateValue: value ? this.convertDate(value) : ''
    };
    if (has(data, 'RowNum')) {
      dateField.RowNum = data.RowNum;
      dateField.ElementName = data.ElementName;
      dateField.DataGroupName = data.DataGroupName;
    }
    return dateField;
  }

  convertDate(date) {
    const convertDate = this.dateservice.toLongISO(
      this.dateservice.moment(date)
    );
    return convertDate;
  }

  fieldConverter(element: InitialDataElements, value) {
    if (element.ResponseType.trim() === ElementName.Checkbox) {
      return this.createCheckBoxField(element, value);
    } else if (element.ResponseType === ElementName.Combination) {
      return this.createCombinationField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Date) {
      return this.createDateField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Decimal) {
      return this.createDecimalField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Integer) {
      return this.createIntegerField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Money) {
      return this.createMoneyField(element, value);
    } else if (element.ResponseType.trim() === ElementName.LookupList) {
      return this.createLookupField(element, value);
    } else if (element.ResponseType.trim() === ElementName.MultiCheckBoxes) {
    } else if (element.ResponseType.trim() === ElementName.MultiSelectCombo) {
      return this.createMultiLookupField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Radio) {
      return this.createRadioField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Text) {
      return this.createTextField(element, value);
    }
    return {};
  }
}
