import { Injectable } from '@angular/core';
//import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CustomPopupAlertService {
  private subject = new Subject<any>();
  private keepAfterRouteChange = false;
  constructor() {
    //   this.router.events.subscribe(event => {
    //     if (event instanceof NavigationStart) {
    //         if (this.keepAfterRouteChange) {
    //             // only keep for a single route change
    //             this.keepAfterRouteChange = false;
    //         } else {
    //             // clear alert message
    //             this.clear();
    //         }
    //     }
    // });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(
    message: string,
    showIcon = false,
    title = '',
    keepAfterRouteChange = false
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type: 'success',
      text: message,
      showIcon: showIcon,
      title: title
    });
  }

  error(
    message: string,
    showIcon = false,
    title = '',
    keepAfterRouteChange = false
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type: 'error',
      text: message,
      showIcon: showIcon,
      title: title
    });
  }

  warning(
    message: string,
    showIcon = false,
    title = '',
    keepAfterRouteChange = false
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type: 'warning',
      text: message,
      showIcon: showIcon,
      title: title
    });
  }

  info(
    message: string,
    showIcon = false,
    title = '',
    keepAfterRouteChange = false
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type: 'info',
      text: message,
      showIcon: showIcon,
      title: title
    });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this.subject.next;
  }
}
