import { HostBinding, Renderer2, ElementRef, Directive } from '@angular/core';
import { FormElementBase } from './form-element-base';

@Directive()
export abstract class FormElement<T> extends FormElementBase<T> {
  @HostBinding('class.form-control') formClass = true;
  @HostBinding('class.m-2') marginRequired = false;

  constructor(protected renderer: Renderer2, protected el: ElementRef) {
    super(renderer, el);
  }
}
