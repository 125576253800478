<form [formGroup]="frmGroup">
  <label
    [ngbTooltip]="toolTipText"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <hcc-custom-validation
    [validations]="fieldInfo.validations"
    *ngIf="!InputConfig?.readonlyConfig?.readOnly; else readonlyTemplate"
  >
    <input
      type="text"
      inputValidateRef
      [value]="value"
      [formControlName]="control"
      class="form-control"
      acInputText
      [control]="control"
      [fieldInfo]="fieldInfo"
      readonly
    />
  </hcc-custom-validation>
  <ng-template #readonlyTemplate>
    <p
      class="disablePara form-control"
      *ngIf="
        InputConfig?.readonlyConfig?.type == 'Currency';
        else staticTemplate
      "
    >
      {{ frmGroup.get(control).value | hccCurrency }}
    </p>
  </ng-template>
  <ng-template #staticTemplate>
    <p class="m-0">
      {{ frmGroup.get(control).value }}
    </p>
  </ng-template>
</form>
