import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import {
  OktaLoginComponent,
  LogoutComponent
} from '@tmhcc-ng-lib/framwork/okta-login';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { PCIRoutes } from '@tmhcc/product-contamination';

const routes: Routes = [
  {
    path: 'callback',
    component: OktaCallbackComponent
  },
  {
    path: 'login',
    component: OktaLoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  ...PCIRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
