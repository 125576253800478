import { CreateActivityLogItem } from './quote-create.model';

export class ClaimContact {
  id: number;
  insuredName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}


export class ClaimContactPayload {
  InsuredName?: string;
  Address?: String;
  City?: String;
  Country?: String;
  State?: String;
  ZipCode?: String;
}

export class ClaimContactResponse {
  Name: string;
  NameType: string;
  NameTypeId?: number;
  ContactId: number;
  MailTo?: boolean = false;
  Payee?: boolean = false;
  PayeeEndTextId?: number;
}

export class CoverageStatus {
  PolicyTxnId?: number;
  DeclinationTypeId?: number;
  DeclinationCategoryCode: string;
  CreateActivityLogItem: CreateActivityLogItem;
}
