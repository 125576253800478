import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextDirective } from './input-text.directive';
import { InputNumberDirective } from '../number/input-number.directive';
import { InputEmailDirective } from '../email/input-email.directive';
import { InputDateDirective } from '../date-picker/date.directive';
import { SelectBoxDirective } from '../dropdown/select-box.directive';
import { CheckboxDirective } from '../check-box/checkbox.directive';
import { DateinputDirective } from '../date-input/dateinput.directive';
import { InputTextAreaDirective } from '../text-area/input-text-area.directive';
import { RadiobuttonDirective } from '../radio-button/radiobutton.directive';
import { MyCurrencyDirective } from '../currency/mycurrency.directive';
import { InputLabelDirective } from '../input-label/input-label.directive';
import { InputSearchDirective } from '../search-input/search-input.directive';
@NgModule({
  imports: [CommonModule],
  declarations: [
    InputTextDirective,
    InputNumberDirective,
    InputEmailDirective,
    InputDateDirective,
    SelectBoxDirective,
    CheckboxDirective,
    DateinputDirective,
    InputTextAreaDirective,
    RadiobuttonDirective,
    MyCurrencyDirective,
    InputLabelDirective,
    InputSearchDirective
  ],
  exports: [
    InputTextDirective,
    InputNumberDirective,
    InputEmailDirective,
    InputDateDirective,
    SelectBoxDirective,
    CheckboxDirective,
    DateinputDirective,
    InputTextAreaDirective,
    RadiobuttonDirective,
    MyCurrencyDirective,
    InputLabelDirective,
    InputSearchDirective
  ]
})
export class InputTextModule {}
