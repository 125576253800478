export class SubjectivityGridRow {
  policySubjectivityId: number;
  description: string;
  checked: boolean;
  isBlank: boolean;
  checkboxChecked?: boolean;
  constructor() {}
}

export class SubjectivityGrid {
  subjectivityData: SubjectivityGridRow[];
}

export class SaveMiscellaneousChangesModel {
  PolicyTxnId: number;
  PolicyId: number;
  PolicyNum?: string;
  PolicyTxnNum: number;
  TxnStatusCode?: string;
}
