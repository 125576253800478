import { isEmpty } from '@tmhcc/util';
import { InitialDataElements } from '@tmhcc-product-contamination/framwork/data-element-builder';

// export class PolicyMasterData {
//   TransactionTypes: TransactionTypes[] = [];
//   TransactionStatuses: TransactionStatuses[] = [];
// }
export interface ClaimHandler {
  Id: string;
  Description: string;
}

export interface Country {
  Id: string;
  Description: string;
  IsoCode: string;
  AllowTravel: boolean;
  HasCalamity: boolean;
  CalamityDescription: string;
}
export interface StateOrProvince {
  CountryCode: string;
  Code: string;
  Id: string;
  Description: string;
  IsoCode: string;
}
export interface PolicyTypes {
  Id: string;
  Description: string;
}
export interface Underwriters {
  Id: string;
  Description: string;
}
export interface UnderwriterAssistants {
  Id: string;
  Description: string;
}
export interface TermTypes {
  Id: string;
  Description: string;
  PolicyTypeId: Number;
}
export interface RenewalOptions {
  Id: string;
  Description: string;
  PolicyTypeId: Number;
}
export interface SubmissionTypes {
  Id: string;
  Description: string;
  PolicyTypeId: Number;
}

export interface PaymentPlans {
  PolicyTypeId: Number;
  Id: Number;
  PaymentPlanName: string;
  ShortPayPlanName: string;
}
export interface ConfigSubjectivities {
  DefaultSubjectivityTypeId: number;
  DefaultSubjectivityType: string;
  SubjectivityDescription: string;
}

export interface UserConfigurableDataElements {
  DecimalResponseName: string;
  TextResponseName: string;
  DateResponseName: string;
  DataGroupId: number;
  GroupIsArray: boolean;
  ElementId: number;
  ElementCode: string;
  ElementName: string;
  ResponseType: string;
  SortOrder: number;
  DataElementResponses: DataElementRespons[];
}

export interface DataElementRespons {
  DataElementResponseId: number;
  ResponseCode: string;
  ResponseValue: string;
  ResponseName: string;
  ResponseText: string;
}

export interface WritingCompaniesByStates {
  WritingCompanyId: number;
  WritingCompanyCode: string;
  WritingCompany: string;
  PolicyTypeId: number;
  CountryCode: string;
  StateId: number;
  IsAdmitted: boolean;
  StateEffDate: Date;
}

export class PolicyMasterData {
  Underwriters: ClaimHandler[];
  Countries: Country[];
  StateOrProvinces: StateOrProvince[];
  ClaimHandler: ClaimHandler[];
  PolicyTypes: PolicyTypes[];
  UnderwriterAssistants: UnderwriterAssistants[];
  SubmissionTypes: SubmissionTypes[];
  RenewalOptions: RenewalOptions[];
  TermTypes: TermTypes[];
  PaymentPlans: PaymentPlans[];
  UserConfigurableDataElements: UserConfigurableDataElements[];
  ConfigSubjectivities: ConfigSubjectivities[];
  WritingCompaniesByStates: WritingCompaniesByStates[];
  FormsetTypes: FormsetTypes[];
  TaxesAndFees: TaxesAndFees[];
  TransactionTypes: TransactionTypes[] = [];
  TransactionStatuses: TransactionStatuses[] = [];
  CovDataElements?: InitialDataElements[] = [];
  CoverageTypes?: CoverageTypes[] = [];
  CancelReasonCodes?: CancelReasonCodes[];
  ConfigRewriteTypes?: ConfigRewriteTypes[];
}

export class CancelReasonCodes {
  CancelReason: string;
  CancelReasonCode: string;
  CancelReasonId: number;
  DefaultCancelMethodCode: 'P';
  IsInactive: boolean;
}
export class ConfigRewriteTypes {
  SubCompanyCode: string;
  RewriteTypeCode: string;
  RewriteTypeId: number;
  RewriteType: string;
  IsInactive: boolean;
}
export class CoverageTypes {
  CovTypeId?: number;
  CovTypeCode?: string;
  CovType?: string;
  SubCompanyCode?: string;
  LOBId?: number;
  PremiumEntryMethodCode?: string;
}

export class FormsetTypes {
  FormSetId: number;
  FormSetTypeCode: string;
}

export class TaxesAndFees {
  TaxId: number;
  StateId: number;
}
export class TransactionTypes {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    if (isEmpty(id) || isEmpty(description)) {
      throw new Error('invalid Transaction Types');
    }

    this.id = id;
    this.description = description;
  }
}

export class TransactionStatuses {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    if (isEmpty(id) || isEmpty(description)) {
      throw new Error('invalid Transaction Status');
    }

    this.id = id;
    this.description = description;
  }
}
