import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface HttpOptions {
  body?: any;
  headers?: HttpHeaders;
  // observe?: 'body';
  params?: HttpParams;
  // reportProgress?: boolean;
  // responseType?: 'json';
  // withCredentials?: boolean;
}

// export interface HttpOptions {
//   body?: any;
//   headers?:
//     | HttpHeaders
//     | {
//         [header: string]: string | string[];
//       };
//   observe?: 'body';
//   params?:
//     | HttpParams
//     | {
//         [param: string]: string | string[];
//       };
//   reportProgress?: boolean;
//   responseType?: 'json';
//   withCredentials?: boolean;
// }
