<div class="form-group">
  <div [ngClass]="{ hasError: isError && isFormsubmitted }">
    <ng-content></ng-content>
    <ng-container *ngIf="isError && isFormsubmitted">
      <span
        class="text-danger"
        *ngFor="let msg of errorMessages; let i = index"
      >
        <ng-container *ngIf="errorMessages.length > 1">
          {{ i + 1 + '. ' + msg }} <br />
        </ng-container>
        <ng-container *ngIf="errorMessages.length == 1">
          {{ msg }}
        </ng-container>
      </span>
    </ng-container>
  </div>
</div>
