import { Injectable } from '@angular/core';
import { UCMForm } from '../def/sitecore-data-def';
import { DateService } from '@tmhcc-ng-lib/framwork/services';

@Injectable()
export class UCMFormSitecoreMapperService {
  constructor(private dateService: DateService) {}

  mapUCMFormData(data: UCMForm[]): UCMForm[] {
    let ucmForm = [];
    if (data && data.length > 0) {
      data.forEach(element => {
        ucmForm.push({
          formID: element.formID,
          formName: element.formName,
          elementDefaults: element.elementDefaults,
          productType: element.productType,
          tabName: element.tabName
        });
      });
    }
    return ucmForm;
  }
}
