import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { isEmpty, has } from '@tmhcc/util';
import { ClaimsMasterData } from '../claims-master.service';
import { BaseDataService } from '../base-data-service';
import { BaseSiteCoreAPIMapper } from './base-sitecore-api-mapper.service';
import { MappedPageData } from '../def/sitecore-data-def';
import { map } from 'rxjs/operators';
import { ClaimsMasterResponse } from '../def/claim-master';
import { ClaimMasterDataService } from '../claim-master.service';
import { of } from 'rxjs';
// import { SecurityUserGroupService } from '../security-user-group.service';
//import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { Router } from '@angular/router';
import { ClaimMasterPolicyDataService } from '../claim-master-policy.service';
import { LoaderService } from '@tmhcc-ng-lib/framwork/loader';

@Injectable()
export class GetCorePageDataService extends BaseDataService {
  cachedResponse = new Map();
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    protected claimMasterService: ClaimsMasterData,
    protected sitecoreDataMapper: BaseSiteCoreAPIMapper,
    private claimMasterDataService: ClaimMasterDataService,
    // private securityUserGroupService: SecurityUserGroupService,
    private policyMasterDataService: ClaimMasterPolicyDataService,
    public loaderService: LoaderService,
    private router: Router
  ) {
    super(http, configService);
  }

  getSiteCoreData(pageName) {
    if (this.cachedResponse.has(pageName)) {
      return of(this.cachedResponse.get(pageName));
    } else {
      return this.http
        .get(
          `${this.configService.config.contentServiceBaseUrl}?PageName=${pageName}`
        )
        .pipe(
          map(data => {
            let result = this.sitecoreDataMapper.mapSiteCoreData(data);
            this.cachedResponse.set(pageName, result);
            return result;
          })
        );
    }
  }

  // getSiteCoreData(pageName) {
  //   this.loaderService.show();
  //   if (pageName) {
  //     return this.http
  //       .get(
  //         `${this.configService.config.contentServiceBaseUrl}?PageName=${pageName}`
  //       )
  //       .pipe(
  //         map(data => {
  //           return this.sitecoreDataMapper.mapSiteCoreData(data);
  //         })
  //       );
  //   }
  // }

  // getClaimMasterData(subCompany = 'RRI', isCachedDataRequired = true) {
  //   //subCompany = sessionStorage.getItem(sessionStorage.getItem('UserId'));
  //   if (!subCompany) {
  //     return of(this.claimMasterDataService.masterDatCached);
  //   }
  //   if (this.claimMasterDataService.masterDatCached && isCachedDataRequired) {
  //     return of(this.claimMasterDataService.masterDatCached);
  //   } else {
  //     return this.get<ClaimsMasterResponse>(
  //       `${this.serviceBaseUrl}/ClaimMasterData/${subCompany}`
  //     ).pipe(
  //       map(data => {
  //         return this.claimMasterDataService.setPolicyMasterData(data);
  //       })
  //     );
  //   }
  // }

  getPolicyMasterData(subCompany = 'RRI', isCachedDataRequired = true) {
    if (!subCompany) {
      return of(this.policyMasterDataService.policyMasterCached);
    }
    if (this.policyMasterDataService.policyMasterCached) {
      return of(this.policyMasterDataService.policyMasterCached);
    } else {
      return this.get<ClaimsMasterResponse>(
        `${this.serviceBaseUrl}/PolicyMasterData/${subCompany}`
      ).pipe(
        map(data => {
          return this.policyMasterDataService.setPolicyMasterData(data);
        })
      );
    }
  }

  // async getSubCompanyData() {
  //   if (this.securityUserGroupService.subCompanyCached) {
  //     return this.securityUserGroupService.subCompanyCached;
  //   }
  //   return await this.securityUserGroupService.fetchSubCompany();
  // }
}
