import { Injectable } from '@angular/core';
import {
  InitialDataElements,
  ElementResponse,
  ElementUpdateRequests
} from '../utility/data-element.def';
import {
  InputConfig,
  FieldTypes,
  ComboBoxConfig
} from '@tmhcc-ng-lib/framwork/form-inputs';
import { ElementName } from '../utility/deb-utility.def';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { has } from '@tmhcc/util';
@Injectable({
  providedIn: 'root'
})
export class ResponseElementConverterService {
  constructor(protected dateService: DateService) {}
  fields;

  setInitialField(data: InitialDataElements[], dataElem: any) {
    this.fields = [];
    let filteredDataElem;
    let obj;
    data.forEach(element => {
      filteredDataElem = dataElem.filter(
        item => item.ElementId === element.ElementId
      );
      if (filteredDataElem.length) {
        obj = this.fieldConverter(element, filteredDataElem[0]);
      } else {
        obj = this.fieldConverter(element);
      }
      this.fields.push(obj);
    });
    return this.fields;
  }

  createCheckBoxField(data: InitialDataElements, value: ElementUpdateRequests) {
    const checkboxField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        label: data.ElementName,
        showLabel: true,
        fieldType: FieldTypes.Checkbox
      },
      config: {
        value:
          value !== null && has(value, 'BooleanValue')
            ? value.BooleanValue
            : '',
        isDisabled: data.isDisabled === true ? data.isDisabled : false
      },
      colClass: 'col-sm-4 col-lg-4 pt-4'
    };
    return checkboxField;
  }

  createCombinationField(
    data: InitialDataElements,
    value: ElementUpdateRequests
  ) {
    const combobox: ComboBoxConfig = {
      label: data.ElementName,
      field: [
        {
          fieldInfo: {
            fieldName: data.ElementId.toString() + '_CMB_ddl',
            required: true,
            showLabel: false,
            label: data.ElementName,
            fieldType: FieldTypes.LookupList
          },
          config: {
            selectConfig: {
              options: data.DataElementResponses,
              optionConfig: {
                displayKey: 'ResponseName',
                search: true,
                limitTo: 1
              },
              selectedOption: this.getSelectedComboVal(data, value)[0],
              multiple: false
            },
            value: this.getSelectedComboVal(data, value)[0],
            isDisabled: data.isDisabled === true ? data.isDisabled : false
          },
          colClass:
            data.AllowDateResponse ||
            data.AllowDecimalResponse ||
            data.AllowTextResponse
              ? 'col-sm-8 col-lg-8 float-left pr-1'
              : 'col-sm-12 col-lg-12 float-left pr-1',
          columnType: 'ComboBox'
        }
        // {
        //   fieldInfo: {
        //     fieldName: data.ElementId.toString() + '_CMB_txt',
        //     label: data.ElementName,
        //     required: false,
        //     showLabel: false,
        //     fieldType: FieldTypes.Numeric
        //   },
        //   colClass: 'col-sm-4 col-lg-4 float-left pr-1'
        // }
      ]
    };
    if (
      data.AllowDateResponse ||
      data.AllowDecimalResponse ||
      data.AllowTextResponse
    ) {
      const obj = this.attachCombinationField(data, value);
      combobox.field.push(obj);
    }
    return combobox;
  }

  attachCombinationField(
    data: InitialDataElements,
    value: ElementUpdateRequests
  ) {
    let config: InputConfig;
    if (data.AllowTextResponse) {
      config = {
        fieldInfo: {
          fieldName: data.ElementId.toString() + '_CMB_txt',
          label: data.ElementName,
          required: false,
          showLabel: false,
          fieldType: FieldTypes.Text
        },
        config: {
          value: value.FreeTextValue
        },
        colClass: 'col-sm-4 col-lg-4 float-left pr-1'
      };
    } else if (data.AllowDateResponse) {
      config = {
        fieldInfo: {
          fieldName: data.ElementId.toString() + '_CMB_txt',
          label: data.ElementName,
          decimal: 0,
          showLabel: true,
          fieldType: FieldTypes.Date
        },
        config: {
          value:
            has(value, 'DateValue') && value.DateValue
              ? this.convertDate(value.DateValue)
              : '',
          //isDisabled: true,
          datePickerConfig: {
            dateInputFormat: this.dateService.getFormat(),
            isCalanderRequired: true,
            maxDate: new Date()
          }
        },
        colClass: 'col-sm-4 col-lg-4 float-left pr-1'
      };
    } else if (data.AllowDecimalResponse) {
      config = {
        fieldInfo: {
          fieldName: data.ElementId.toString() + '_CMB_txt',
          label: data.ElementName,
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2
        },
        config: {
          value: value.DecimalValue,
          numericConfig: {
            type: 'currency'
          }
        },
        colClass: 'col-sm-4 col-lg-4 float-left pr-1'
      };
    }
    return config;
  }

  createDateField(data: InitialDataElements, value: ElementUpdateRequests) {
    const dateField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        label: data.ElementName,
        decimal: 0,
        showLabel: true,
        fieldType: FieldTypes.Date
      },
      config: {
        value:
          has(value, 'DateValue') && value.DateValue
            ? this.convertDate(value.DateValue)
            : '',
        isDisabled: data.isDisabled === true ? data.isDisabled : false,
        //isDisabled: true,
        datePickerConfig: {
          dateInputFormat: this.dateService.getFormat(),
          isCalanderRequired: true,
          maxDate: new Date()
        }
      },
      colClass: 'col-sm-4 col-lg-4'
    };
    return dateField;
  }

  createDecimalField(data: InitialDataElements, value: ElementUpdateRequests) {
    const decimalField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        label: data.ElementName,
        //required: true,
        showLabel: true,
        fieldType: FieldTypes.Numeric,
        decimal: 2
      },
      config: {
        numericConfig: {
          type: 'currency'
        },
        value: value.DecimalValue,
        isDisabled: data.isDisabled === true ? data.isDisabled : false
      },
      colClass: 'col-sm-4 col-lg-4'
    };
    return decimalField;
  }

  createIntegerField(data: InitialDataElements, value: ElementUpdateRequests) {
    const integerField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        label: data.ElementName,
        //required: true,
        showLabel: true,
        fieldType: FieldTypes.Numeric,
        decimal: 2
      },
      config: {
        numericConfig: {
          type: 'currency'
        },
        value:
          value !== null && has(value, 'IntegerValue')
            ? value.IntegerValue
            : '',
        isDisabled: data.isDisabled === true ? data.isDisabled : false
      },
      colClass: 'col-sm-4 col-lg-4'
    };
    return integerField;
  }

  createLookupField(data: InitialDataElements, value) {
    const lookupField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        showLabel: true,
        label: data.ElementName,
        fieldType: FieldTypes.LookupList
      },
      config: {
        selectConfig: {
          options: data.DataElementResponses,
          optionConfig: {
            displayKey: 'ResponseName',
            search: true,
            limitTo: 1
          },
          selectedOption: this.getSelectedComboVal(data, value)[0],
          multiple: false
        },
        value: this.getSelectedComboVal(data, value)[0],
        isDisabled: data.isDisabled === true ? data.isDisabled : false
      },
      colClass: 'col-sm-4 col-lg-4'
    };
    return lookupField;
  }

  createMultiLookupField(
    data: InitialDataElements,
    value: ElementUpdateRequests
  ) {
    const lookupMultiField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        showLabel: true,
        label: data.ElementName,
        fieldType: FieldTypes.LookupList
      },
      config: {
        selectConfig: {
          options: data.DataElementResponses,
          optionConfig: {
            displayKey: 'ResponseName',
            search: true,
            limitTo: 1
          },
          selectedOption: this.getSelectedComboVal(data, value),
          multiple: true
        },
        value: this.getSelectedComboVal(data, value),
        isDisabled: data.isDisabled === true ? data.isDisabled : false
      },
      colClass: 'col-sm-4 col-lg-4'
    };
    return lookupMultiField;
  }

  createRadioField(data: InitialDataElements, value: ElementUpdateRequests) {
    const radios = [];
    data.DataElementResponses.forEach((ele: ElementResponse) => {
      const obj = {
        checked: this.getRadioBoxSelectedValue(ele, value),
        label: ele.ResponseName,
        value: data.ElementId.toString(),
        style: 'col-3 col-lg-4 pl-0 float-left'
      };
      radios.push(obj);
    });
    const radioField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        showLabel: true,
        label: data.ElementName,
        fieldType: FieldTypes.Radio
      },
      config: {
        radioCofig: {
          radioGroup: radios
        },
        isDisabled: data.isDisabled === true ? data.isDisabled : false,
        value: this.getRadioValue(data, value)
      },
      colClass: 'col-sm-12 col-lg-12'
    };
    return radioField;
  }

  createTextField(data: InitialDataElements, value: ElementUpdateRequests) {
    const textField: InputConfig = {
      fieldInfo: {
        fieldName: data.ElementId.toString(),
        label: data.ElementName,
        showLabel: true,
        fieldType: FieldTypes.Text
      },
      config: {
        value: value !== null && value.FreeTextValue ? value.FreeTextValue : '',
        isDisabled: data.isDisabled === true ? data.isDisabled : false
      },
      colClass: 'col-sm-4 col-lg-4'
    };
    return textField;
  }

  fieldConverter(element: InitialDataElements, value = null) {
    if (element.ResponseType.trim() === ElementName.Checkbox) {
      return this.createCheckBoxField(element, value);
    } else if (element.ResponseType === ElementName.Combination) {
      return this.createCombinationField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Date) {
      return this.createDateField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Decimal) {
      return this.createDecimalField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Integer) {
      return this.createIntegerField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Money) {
      return this.createIntegerField(element, value);
    } else if (element.ResponseType.trim() === ElementName.LookupList) {
      return this.createLookupField(element, value);
    } else if (element.ResponseType.trim() === ElementName.MultiCheckBoxes) {
    } else if (element.ResponseType.trim() === ElementName.MultiSelectCombo) {
      return this.createMultiLookupField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Radio) {
      return this.createRadioField(element, value);
    } else if (element.ResponseType.trim() === ElementName.Text) {
      return this.createTextField(element, value);
    }
    return {};
  }

  convertDate(date) {
    const convertDate = this.dateService.toLongISO(
      this.dateService.moment(date)
    );
    return new Date(convertDate);
  }

  getSelectedComboVal(data: InitialDataElements, value: ElementUpdateRequests) {
    const filteredArr = [];
    if (
      has(value, 'SelectedDataElementResponseIds') &&
      value.SelectedDataElementResponseIds.length
    ) {
      data.DataElementResponses.forEach(element => {
        return value.SelectedDataElementResponseIds.forEach(item => {
          if (element.DataElementResponseId === item) {
            filteredArr.push(element);
          }
        });
      });
    }
    return filteredArr;
  }

  getRadioBoxSelectedValue(data, value: ElementUpdateRequests) {
    let radioVal = false;
    if (
      has(value, 'SelectedDataElementResponseIds') &&
      value.SelectedDataElementResponseIds.length
    ) {
      value.SelectedDataElementResponseIds.forEach(item => {
        if (data.DataElementResponseId === item) {
          radioVal = true;
        }
      });
    }
    return radioVal;
  }

  getRadioValue(data, value: ElementUpdateRequests) {
    let Val = '';
    if (
      has(value, 'SelectedDataElementResponseIds') &&
      value.SelectedDataElementResponseIds.length
    ) {
      data.DataElementResponses.forEach(item => {
        if (
          item.DataElementResponseId === value.SelectedDataElementResponseIds[0]
        ) {
          Val = item.ResponseName;
        }
      });
    }
    return Val;
  }
}
