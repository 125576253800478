export enum AllowedRoute {
  Dashboard = '/dashboard',

  UCMQuoteDetails = '/ucm-forms/quote-details',
  UCMQuoteInfo = '/ucm-forms/quoteInfo',
  UCMAddInsured = '/ucm-forms/add-insured',
  UCMAccountDeatils = '/ucm-forms/account-details',

  AccountInfo = '/setup',
  InsuredSearch = '/setup/insured-search',

  // Quote = '/quoteInfo',

  QuoteSubjectivities = '/quotes/subjectivities',
  QuoteCoverageLimit = '/quotes/coverage-limit',

  Location = '/location-details',
  LocationDetils = '/location-details/locations-details-franchise-information',
  FranchiseDetils = '/location-details/locations-details-franchise-details',

  Bind = '/bind',
  BindForm = '/bind/forms',
  BindSubjectivities = '/bind/subjectivities',
  BindQuoteInfoSummary = '/bind/quoteInfo-summary',

  Document = '/document',
  Forms = '/policy-forms',
  Summary = '/bind-summary',

  Approval = '/approval',
  AccountDetails = '/account-details'
}
