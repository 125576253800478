export function objectValues(obj) {
  var res = [];
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      res.push(obj[i]);
    }
  }
  return res;
}

export function getIndexFromArray(arrayToCompare, objectToCompare) {
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < arrayToCompare.length; i++) {
    if (compareObjects(arrayToCompare[i], objectToCompare)) {
      return i;
    }
  }
}

export function compareObjects(o1, o2) {
  return Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every(p => o1[p] === o2[p])
    ? true
    : false;
}

export function getIndexFromArrayToDelete(arrayToCompare, objectToCompare) {
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < arrayToCompare.length; i++) {
    if (compareObjectsToDeleteRow(arrayToCompare[i], objectToCompare)) {
      return i;
    }
  }
}

export function compareObjectsToDeleteRow(previousGridColumn, newGridColumn) {
  return Object.keys(previousGridColumn).length ===
    Object.keys(newGridColumn).length &&
    Object.keys(previousGridColumn).every(
      p => previousGridColumn[p] === newGridColumn[p]
    )
    ? true
    : false;
}
