import { ElementUpdateRequests } from './events';
import { UpdateUserConfigurableDataElement } from './claim-get-policy';

export interface PolicyFormBuilderResponse {
  Status: string;
  Warnings: any;
  Errors: any;
  Data?: PolicyResult[];
  Exception: any;
  Validations: any;
}

export interface PolicyUpdateDate {
  PolicyTxnId: number;
  TxnEff: string;
  PolicyEff: string;
  PolicyExp: string;
  RateDate?: string;
  UpdateUserConfigurableDataElement: UpdateUserConfigurableDataElement;
}

export interface PolicyResult {
  Relationships: object;
  Id: number;
  Type: string;
  Attributes: PolicyType;
}

export interface PolicyType {
  PolicyTerm: any;
  PolicyTxn: PolicyTxnss;
}

export interface PolicyTxnss {
  Id: number;
  Type: string;
  Attributes: PolicyTxnData;
}

export interface PolicyTxnData {
  PolicyPortalStatus: any;
  PolicyPortalStatusId: number;
  Quotes: Quotes[];
}

export interface Quotes {
  UWQuoteId: number;
  Lobs: QuoteLOBs[];
  QuoteStatus?: string;
  PolicyTxnId?: number;
  QuoteNum?: number;
  QuoteDescription?: string;
  CurrencyCode?: string;
  IsRated?: boolean;
  WritingCompanyId?: number;
  ContractMasterId?: number;
  IsAdmitted?: boolean;
}

export interface QuoteLOBs {
  Id: number;
  Type: string;
  Risks: QuoteLOBRisks[];
}

export interface QuoteLOBRisks {
  UWRiskId?: number;
  RiskTrackingNum?: number;
  RiskTypeCode?: string;
  CountryCode?: string;
  StateId?: number;
  PolicyRiskCode?: string;
  RiskDescription?: string;
  RiskValueCurrencyCode?: string;
  CombinedSingleLimitCurrencyCode?: string;
  CurrencyCode?: string;
  DataElements?: LOBRiskDataElements;
  Location?: any;
  RiskTypeCod?: string;
  Coverages?: any;
}

// export interface Coverages {
//   Id: number,
//   Type: string = "Coverages",
//   Attributes :
// }

export interface LOBRiskDataElements {
  DataElements?: ElementUpdateRequest[];
  ElementUpdateRequests?: ElementUpdateRequests[];
}

// export interface ElementUpdateRequests {
//   ElementCode: string;
//   DataGroupName?: string;
//   ElementId: number;
//   ElementName?: string;
//   ResponseType: string;
//   FreeTextValue: string;
//   DateValue: string;
//   SelectedDataElementResponseIds: number[];
//   DecimalValue: number;
//   MoneyValue: number;
//   RowNum: number;
//   BooleanValue?: boolean;
// }

export interface ElementUpdateRequest {
  ElementCode: string;
  DataGroupName?: string;
  ElementId: number;
  ElementName?: string;
  ResponseType: string;
  FreeTextValue: string;
  DateValue: string;
  SelectedDataElementResponseIds: number[];
  DecimalValue: number;
  MoneyValue: number;
  RowNum: number;
  BooleanValue?: boolean;
}

export interface DeleteQuote {
  PolicyTxnId: number;
  UWQuoteId: number;
}

export interface CopyQuote {
  PolicyTxnId: number;
  UWQuoteId: number;
}

// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"
export interface RequestSaveFormBuilder {
  PolicyData?: DataRequest;
  RSXCalculateFlag: boolean;
  InitialSave: boolean;
  PolicyTxnId: number;
  ApplyDefaults?: boolean;
}

export interface CreateActivityLog {
  ActivityDate?: string;
  ActivityType?: string;
  Amount?: number;
  Description?: string;
  LoggedInUser?: string;
  PolicyTxnId?: number;
  CreationDate?: string;
}

export interface DataRequest {
  Data: Datum[];
}

export interface Formbuilder {
  Data: Datum[];
  Status: string;
  Warnings: any[];
  Errors: any[];
}

export interface Datum {
  Relationships: Relationships;
  Id: number;
  Type: string;
  Attributes: DatumAttributes;
}

export interface DatumAttributes {
  PolicyTerm: PolicyTerm;
  PolicyTxn: PolicyTxn;
}

export interface PolicyTerm {
  Id: number;
  Type: string;
  Attributes: PolicyTermAttributes;
}

export interface PolicyTermAttributes {
  PolicyId: number;
  PolicyTermNum: number;
  PolicyTypeId: number;
  TermTypeId: number;
  PayPlanId: number;
  BillToContactId: number;
  NextRenewalOptionId: number;
  NextBillToContactId: number;
  CurrencyCode: string;
  SubmissionNum: string;
}

export interface PolicyTxn {
  Id: number;
  Type: string;
  Attributes: PolicyTxnAttributes;
}

export interface PolicyTxnAttributes {
  PolicyPortalStatus: string;
  PolicyPortalStatusId: number;
  Quotes: Quote[];
  DataElements: PurpleDataElement[];
  PolicyId: number;
  PolicyTxnId: number;
  PolicyTxnNum: number;
  PolicyTermNum: number;
  CountryCode: string;
  StateId: number;
  LastTxnNum: number;
  InsuredContactId: number;
  PolicyEff: string;
  PolicyExp: string;
  TxnTypeCode: string;
  TxnUserId: string;
  TxnPayPlanId: number;
  BillToContactId: number;
  TxnEff: string;
  RateDate: string;
  SubmissionTypeId: number;
  PolicyStateCode: string;
  PolicyTypeCode: string;
  ProcessStatusCode: string;
  TxnStatusCode: string;
  WrittenPrem?: number;
  WrittenTaxes?: number;
}

export interface PurpleDataElement {
  Id: number;
  Type: string;
  Attributes: PurpleAttributes;
}

export interface PurpleAttributes {
  ElementCode?: ElementCode;
  PolicyDataElementId?: number;
  ElementId?: number;
  TextResponse?: string;
  DataElementResponseId?: number;
  RowNum?: number;
}

export enum ElementCode {
  AddNamedInsured = 'AddNamedInsured',
  AddlNamedInsured = 'AddlNamedInsured',
  TradeName = 'TradeName',
  TermLength = 'TermLength'
}

export enum DataElementType {
  DataElements = 'DataElements'
}

export interface Quote {
  Id: number;
  Type: string;
  Attributes: QuoteAttributes;
}

export interface QuoteAttributes {
  Lobs?: Lobs[];
  UWQuoteId?: number;
  PolicyTxnId?: number;
  QuoteNum?: number;
  QuoteDescription?: string;
  CurrencyCode?: string;
  IsRated?: boolean;
  WritingCompanyId?: number;
  ContractMasterId?: number;
  IsAdmitted?: boolean;
  QuoteStatusCode?: string;
  ProcessStatusCode?: string;
  AnnualPrem?: number;
  ActualPrem?: number;
  WrittenPrem?: number;
  WrittenTaxes?: number;
}

export interface Lobs {
  Id: number;
  Type: string;
  Attributes: LobAttributes;
}

export interface LobAttributes {
  Risks: Risks[];
  UWLOBId?: number;
  LOBId?: number;
  CountryCode: string;
  StateId?: number;
  IsDefaultFormsComplete?: boolean;
  IsUserSeqENForms?: boolean;
  IsDefaultFeesComplete?: boolean;
  ContractMasterId?: number;
  LOBTrackingNum?: number;
  IsSubjectToAudit?: boolean;
  WrittenAccretion?: number;
  CurrencyCode?: string;
  AnnualPrem?: number;
  ActualPrem?: number;
  WrittenPrem?: number;
  WrittenTaxes?: number;
  UwFees: UwFee[];
  PolicyProducerComms: PolicyProducerComm;
}

export interface PolicyProducerComm {
  Id?: number;
  Type?: string;
  Attributes: PolicyProducerAttributes;
}
export interface PolicyProducerAttributes {
  PolicyProducerCommId?: number;
  PolicyProducerId?: number;
  Uwlobid?: number;
  CommPlanId?: number;
  CommRate?: number;
  OverrideComm?: boolean;
  CommAmt?: number;
  DefaultCommRate?: number;
  IsPayNet?: boolean;
}
export interface Risks {
  Id: number;
  Type: string;
  Attributes: RiskAttributes;
}

export interface RiskAttributes {
  DataElements?: FluffyDataElement[];
  Location?: Location;
  Coverages?: Coverages[];
  UWRiskId?: number;
  RiskTrackingNum?: number;
  RiskTypeCode?: string;
  CountryCode?: string;
  StateId?: number;
  PolicyRiskCode?: string;
  RiskValueCurrencyCode?: string;
  CombinedSingleLimitCurrencyCode?: string;
  CurrencyCode?: string;
}

export interface Coverages {
  Id?: number;
  Type?: CoverageType;
  Attributes?: CoverageAttributes;
  CovTypeId?: number;
  RiskType?: string;
  QuoteNum?: number;
  CovName?: string;
  CovTypeCode?: string;
  PerOccurLimit?: number;
  PremiumAmount: number;
  CovDescription?: string;
}

export interface CoverageAttributes {
  UWCovId?: number;
  CovTrackingNum?: number;
  CovTypeId?: number;
  CovDescription?: string;
  PerOccurLimit?: number;
  AggregateLimit?: number;
  PerOccurDed?: number;
  CovFromDate?: string;
  IsBlanket?: boolean;
  IsExpIncluded?: boolean;
  PremEntryTypeId?: number;
  IsPurchased?: boolean;
  DedCurrencyCode?: string;
  LimitCurrencyCode?: string;
  WrittenPrem?: number;
  ActualPrem?: number;
  PremiumAmount?: number;
}

export enum CoverageType {
  Coverages = 'Coverages'
}

export interface FluffyDataElement {
  Id: number;
  Type: string;
  Attributes: FluffyAttributes;
  RiskType?: string;
  QuoteNum?: number;
  CovName?: string;
  ElementCode?: string;
  ElementId?: number;
}

export interface FluffyAttributes {
  ElementCode?: string;
  UWDataElementId?: number;
  ElementId?: number;
  TextResponse?: string;
  DataElementResponseId?: number;
  IntegerResponse?: number;
  ResponseType?: string;
  DecimalResponse?: number;
  MoneyResponse?: number;
}

export interface Location {
  Id: number;
  Type: string;
  Attributes: LocationAttributes;
}

export interface LocationAttributes {
  UWLocationId: number;
  City: string;
  CountyId: number;
  ZipCode: string;
  StreetAddress: string;
}

export interface Relationships {
  PolicyTxns: PolicyTxns;
}

export interface PolicyTxns {
  Type: string;
  Id: number;
}

export interface QuoteResult {
  PolicyAggr: number;
  WaitingPeriod: number;
  InsRetention: number;
  PremType: number;
  TwoYrPrepaidFactor: number;
  RNoLocs: number;
  BasePrem: number;
  WPVPrem: number;
  TRIARate: number;
  OneYrPremium: number;
  TwoYrPremium: number;
  RecallExp: number;
  RehabExp: number;
  LGPIndPeriod: number;
  LGPIncRoyalties: number;
  ExtortionCost: number;
  CrisisConsultExp: number;
  LostGrossProfitLimit: number;
  IncresedCost: number;
  Inoculations: number;
  Accidental: number;
  Tampering: number;
  SupContamination: number;
  AdversePub: number;
  OverallAggr: number;
  PerOccLimit: number;
  WPVIndemPeriod: number;
  Deductibles: number;
  PolicyTypeId: number;
  UWQuoteId: number;
  //--
  FR?: string;
  TaxFeeDscr1?: string;
  TaxFeeAmnt1?: number;
  TaxFeeDscr2?: string;
  TaxFeeAmnt2?: number;
  TaxFeeDscr3?: string;
  TaxFeeAmnt3?: number;
  TaxFeeDscr4?: string;
  TaxFeeAmnt4?: number;
  TaxFeeDscr5?: string;
  TaxFeeAmnt5?: number;
}
export interface CorporateLocationsDetailsAttributes {
  Id?: string;
  FirstName?: string;
  LastName?: string;
  Address?: Address[];
  Save?: boolean;
  UWNameId?: number;
  FullName?: string;
  NameTrackingNum?: number;
  ContactId?: number;
  NameTypeId?: number;
  AddlInfo?: string;
  AddDate?: Date;
  ZipCode?: string;
  StreetAddress?: string;
  StateCode?: string;
  StateId?: number;
  Country?: string;
  City?: string;
  AddressId?: number;
  TypeId?: number;
  CountryCode?: string;
  State?: string;
}

export interface FranchiseeLocationsDetailsAttributes {
  RFrnchMemName?: string;
  RFrnchMemNoLoc?: string;
  RFMemLocsAnnualSales?: number;
  RFCHLocsAnnualSales?: number;
  RiskTrackingNum?: number;
  RFrnchMemAddress?: string;
  TRIASelect?: string;
  WPVSelect?: number;
  ZipCode?: string;
  StreetAddress?: string;
  Address?: string;
  StateCode?: string;
  StateId?: number;
  Country?: string;
  CountryCode?: string;
  City?: string;
  BasePrem?: number;
  WPVPrem?: number;
  TRIA?: number;
  FullName?: string;
  EndrosmentBasePrem?: string;
  EndrosmentWPVPrem?: string;
  EndrosmentTRIA?: string;
}
export interface Address {
  AddressId: number;
  CountryCode: string;
  ZipCode: string;
  StreetAddress: string;
  StateCode: string;
  StateId: number;
  County: string;
  City: string;
  AddressTypes: number[];
}
export interface Attributes {
  FirstName: string;
  LastName: string;
  Address: Address[];
  Save: boolean;
  UWNameId: number;
  NameTrackingNum: number;
  ContactId: number;
  NameTypeId: number;
  AddlInfo: string;
  AddDate: Date;
}
export interface Address {
  AddressId: number;
  CountryCode: string;
  ZipCode: string;
  StreetAddress: string;
  StateCode: string;
  StateId: number;
  County: string;
  City: string;
  AddressTypes: number[];
}
export interface Attributes {
  FirstName: string;
  LastName: string;
  Address: Address[];
  Save: boolean;
  UWNameId: number;
  NameTrackingNum: number;
  ContactId: number;
  NameTypeId: number;
  AddlInfo: string;
  AddDate: Date;
}

export interface FormbuilderLOBS {
  Data: any[];
  Status: string;
  Warnings: any[];
  Errors: any[];
}

export interface FormbuilderRisks {
  Data: any[];
  Status: string;
  Warnings: any[];
  Errors: any[];
}

export interface ApproveQuote {
  PolicyTxnId: number;
  QuoteId: number;
  CreateActivityLogItem: ApprovQuoteActivity;
}

export interface ApprovQuoteActivity {
  ActivityDate?: string;
  ActivityType?: string;
  Amount?: number;
  Description?: string;
  LoggedInUser?: string;
  PolicyTxnId?: number;
}

export interface UwFee {
  Id?: number;
  Type?: string;
  Attributes?: UWAttributes;
}

export interface UWAttributes {
  UWFeeId: number;
  UWLOBId: number;
  TaxId: number;
  FeeAmt: number;
  IsCarriedForward: boolean;
  IsDelete: boolean;
}
