import { Component, forwardRef, ViewChild, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBase } from '../input-base';
import { has } from '@tmhcc/util';

@Component({
  selector: 'hcc-number-input',
  templateUrl: './number-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent extends InputBase<string> implements OnInit {
  constructor() {
    super();
  }
  toolTipText = '';
  ngOnInit() {
    if (has(this.fieldInfo, 'label') && this.fieldInfo.label.length > 100) {
      this.toolTipText = this.fieldInfo.label;
    }
  }
}
