import { Moment } from '@tmhcc-ng-lib/framwork/services';

export class ClaimSearchGridRow {
  PolicyTxnId: number;
  PolicyNum: string;
  PolicyId: string;
  PolicyType: string;
  InsuredName: string;
  TxnEffDate?: Moment;
  PolicyEffDate: Moment;
  PolicyExpDate: Moment;
  TxnExpDate?: Moment;
  ClaimBasis: string;
  SubCompanyCode: string;
  AgencyName: string;
  SubCompanyName: string;
  IsMultiLob: boolean;
}
