<form [formGroup]="frmGroup">
  <div class="custom-control custom-switch">
    <hcc-custom-validation [validations]="fieldInfo.validations">
      <input
        type="checkbox"
        [checked]="value"
        class="custom-control-input"
        [formControlName]="control"
        acCheckbox
        inputValidateRef
        [fieldInfo]="fieldInfo"
        (change)="change($event.currentTarget.checked)"
      />
    </hcc-custom-validation>
    <label
      *ngIf="fieldInfo.showLabel"
      [fieldInfo]="fieldInfo"
      class="custom-control-label"
      acForLable
      [fieldRequired]="fieldInfo.required"
    >
      {{ fieldInfo.label }}<span *ngIf="fieldInfo.required">*</span>
    </label>
  </div>
</form>
