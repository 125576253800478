import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { AppConfig } from 'libs/models/def/app-config';
import { BASE_URI } from 'libs/startup/tokens/base-uri.token';

@Injectable()
export class AppConfigService {
  config: AppConfig;

  constructor(@Inject(BASE_URI) private baseUri, private http: HttpClient) {}

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get<AppConfig>(`appconfig.json`)
        .pipe(
          catchError(error => {
            reject(error);
            return throwError(error);
          })
        )
        .subscribe(result => {
          this.config = result;
          resolve(result);
        });
    });
  }
}
