import { Injectable } from '@angular/core';
import { FormSitecoreMapperService } from './form-site-core-mapper.service';
import { GridSitecoreMapperService } from './grid-site-core-mapper.service';
import {
  pageData,
  MappedPageData,
  Field,
  Grid,
  Badge
} from '../def/sitecore-data-def';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { BaseDataService } from '../base-data-service';
import { UCMFormSitecoreMapperService } from './ucm-form-site-core-mapper.service';

@Injectable()
export class BaseSiteCoreAPIMapper extends BaseDataService {
  constructor(
    private FormSitecoreMapperService: FormSitecoreMapperService,
    private gridSitecoreMapperService: GridSitecoreMapperService,
    private ucmFormSitecoreMapperService: UCMFormSitecoreMapperService,
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  form;
  gird;
  accordion;

  mapSiteCoreData(sitecoreFormData = null): MappedPageData {
    if (!sitecoreFormData) {
      sitecoreFormData = {
        pageName: 'dashboard',
        forms: [],
        grids: [
          {
            gridName: 'Process Payments',
            rowText: '',
            deleteRow: false,
            paging: true,
            pageLength: 10,
            searching: false,
            ordering: true,
            isCheckBoxRequired: true,
            responsive: true,
            isVisibility: true,
            isPersist: true,
            isRadioBoxRequired: false,
            columnDefinitions: [
              {
                fieldName: 'ClaimPaymentId',
                label: 'Claim Payment Id',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'link',
                orderId: 1
              },
              {
                fieldName: 'ClaimTxnStatus',
                label: 'Status',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 2
              },
              {
                fieldName: 'Insured',
                label: 'Insured Name',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: '',
                orderId: 3
              },
              {
                fieldName: 'CheckNo',
                label: 'Check No',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 4
              },
              {
                fieldName: 'MailToFileAsName',
                label: 'Mailed To',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 5
              },
              {
                fieldName: 'CurrencyCode',
                label: 'Currency',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 6
              },
              {
                fieldName: 'BilledTotal',
                label: 'Billed Total',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 7
              },
              {
                fieldName: 'PaidTotal',
                label: 'Paid Total',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 8
              },
              {
                fieldName: 'Invoice',
                label: 'Inovice #',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'default',
                orderId: 9
              },
              {
                fieldName: 'DateEntered',
                label: 'Date Entered',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'date',
                orderId: 10
              },
              {
                fieldName: 'CreationUser',
                label: 'User Id',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 11
              },
              {
                fieldName: '',
                label: 'Action',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'view',
                orderId: 12
              }
            ]
          },
          {
            gridName: 'Process Reserves',
            rowText: '',
            deleteRow: false,
            paging: true,
            pageLength: 10,
            searching: false,
            ordering: true,
            isCheckBoxRequired: true,
            responsive: true,
            isVisibility: true,
            isPersist: true,
            isRadioBoxRequired: false,
            columnDefinitions: [
              {
                fieldName: 'ClaimNum',
                label: 'Claim No',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'link',
                orderId: 1
              },
              {
                fieldName: 'InsuredName',
                label: 'InsuredFileAsName',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 2
              },
              {
                fieldName: 'ReportedType',
                label: 'Reported Type',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 3
              },
              {
                fieldName: 'ReserveType',
                label: 'Reserve Type',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: '',
                orderId: 4
              },
              {
                fieldName: 'ClaimTxnStatus',
                label: 'Status',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 5
              },
              {
                fieldName: 'ReserveCat',
                label: 'Reserve Category',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 6
              },
              {
                fieldName: 'CurrencyCode',
                label: 'Currency',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 7
              },
              {
                fieldName: 'EnteredAmt',
                label: 'Amount',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 8
              },
              {
                fieldName: 'PerOccurLimit',
                label: 'Per Occurance',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 9
              },
              {
                fieldName: 'AggregateLimit',
                label: 'Aggregate',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 10
              },
              {
                fieldName: 'TotalIncurred',
                label: 'Total Incurred',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 11
              },
              {
                fieldName: 'LineOfBusiness',
                label: 'Line of Business',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 12
              },
              {
                fieldName: 'RiskDescription',
                label: 'Risk',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 13
              },
              {
                fieldName: 'CoverageType',
                label: 'Coverage Type',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 14
              },
              {
                fieldName: 'LossDate',
                label: 'Loss Date',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'date',
                orderId: null
              },
              {
                fieldName: 'DateEntered',
                label: 'Date Entered',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'date',
                orderId: 16
              },
              {
                fieldName: 'CreationUser',
                label: 'User Id',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 17
              },
              {
                fieldName: 'ClaimsAssistant',
                label: 'Claims Assistant',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 18
              },
              {
                fieldName: 'Description',
                label: 'Claims Attorney',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 19
              },
              {
                fieldName: 'EventId',
                label: 'Event Id',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 20
              }
            ]
          },
          {
            gridName: 'Process Payment Details',
            rowText: '',
            deleteRow: false,
            paging: false,
            pageLength: null,
            searching: false,
            ordering: true,
            isCheckBoxRequired: false,
            responsive: true,
            isVisibility: false,
            isPersist: false,
            isRadioBoxRequired: false,
            columnDefinitions: [
              {
                fieldName: 'EventId',
                label: 'Event Id',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 1
              },
              {
                fieldName: 'LineOfBusiness',
                label: 'Line of Business',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 2
              },
              {
                fieldName: 'RiskDescription',
                label: 'Risk',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 3
              },
              {
                fieldName: 'BilledAmt',
                label: 'Amount Billed',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currency',
                orderId: 4
              },
              {
                fieldName: 'DetailAmt',
                label: 'Amount Paid',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'currecy',
                orderId: 5
              },
              {
                fieldName: 'ClaimPaymentType',
                label: 'Payment Type',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 6
              },
              {
                fieldName: 'CovType',
                label: 'Coverage Type',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 7
              },
              {
                fieldName: 'ReserveCategory',
                label: 'Reserve Category',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 8
              },
              {
                fieldName: 'ReserveType',
                label: 'Reserve Type',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 9
              },
              {
                fieldName: 'LossDate',
                label: 'Loss Date',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'date',
                orderId: 10
              },
              {
                fieldName: 'ClaimsAttorney',
                label: 'Claims Attorney',
                isVisible: true,
                isSortable: true,
                alignment: '',
                columnType: 'string',
                orderId: 11
              }
            ]
          }
        ],
        accordians: [
          {
            heading: 'Action Required',
            displayItems: [
              {
                keyText: 'Process Reserves',
                description: '',
                apiMappingKey: 'processReserve',
                orderId: 1
              },
              {
                keyText: 'Pending Claims',
                description: '',
                apiMappingKey: 'PendingClaims',
                orderId: 2
              },
              {
                keyText: 'Process Payments',
                description: '',
                apiMappingKey: 'processpayments',
                orderId: 3
              }
            ]
          }
        ]
      };
    }
    let MappedData: MappedPageData = {
      gridData: this.gridSitecoreMapperService.mapGridSettings(
        sitecoreFormData.grids
      ),
      formData: this.FormSitecoreMapperService.mapFormData(
        sitecoreFormData.forms
      ),
      accordianData: sitecoreFormData.accordians,
      UCMforms: this.ucmFormSitecoreMapperService.mapUCMFormData(
        sitecoreFormData.UCMforms
      )
    };
    return MappedData;
  }

  fetchFormDataFromMappedSitecoreData(mappedData, formName = ''): Field[] {
    let formData = mappedData['formData'];
    let fields: Field[];
    if (formName != '') {
      formData.forEach(forms => {
        if (forms.formName === formName) {
          fields = forms.fields;
        }
      });
    } else {
      return formData;
    }
    return fields;
  }

  fetchGridDataFromMappedSitecoreData(mappedData, gridName = '') {
    let gridData = mappedData['gridData'];
    let grid: Grid[];
    if (gridName != '') {
      gridData.forEach(gridData => {
        if (gridData.gridName === gridName) {
          grid = gridData.data;
        }
      });
    } else {
      return gridData;
    }
    return grid;
  }

  fetchBadgeDataFromMappedSitecoreData(mappedData, Badge = ''): Badge {
    let badgeData = mappedData['siteCore']['accordianData'];
    return badgeData;
  }

  fetchUCMFromMappedSitecoreData(mappedData) {
    return mappedData['siteCore']['UCMforms'];
  }
}
