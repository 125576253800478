import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { OKTA_CONFIG } from '@okta/okta-angular';

import OktaSignIn from '@okta/okta-signin-widget';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { getBaseUrl } from '@tmhcc/util';
import { OktaConfig } from '@okta/okta-angular/okta/models/okta.config';
import { LoaderService } from '@tmhcc-ng-lib/framwork/loader';
import { environment } from '../../../../../apps/product-contamination/src/environments/environment';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { async } from 'rxjs/internal/scheduler/async';
//import { ObserveOnSubscriber } from 'rxjs/internal/operators/observeOn';
// import { SecurityUserGroupService } from '@tmhcc-ng-lib/data-service';
// import { ToastrService } from 'ngx-toastr';
// import { Messages } from '@tmhcc-ng-lib/models';
const DEFAULT_ORIGINAL_URI = window.location.origin;
@Component({
  selector: 'app-secure',
  template: `
    <!-- Container to inject the Sign-In Widget -->
    <div id="okta-signin-container"></div>
  `
})
export class OktaLoginComponent implements OnInit, OnDestroy {
  signIn;
  widget;

  constructor(
    //private oktaAuth: OktaAuthService,
    private router: Router,
    protected configService: AppConfigService,
    private loaderService: LoaderService,
    @Inject(OKTA_CONFIG) protected config: OktaConfig,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth// private securityUserGroupService: SecurityUserGroupService, // private alertService: ToastrService
  ) {
    this.signIn = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: environment.oidc.issuer.split('/oauth2')[0],
      clientId: environment.oidc.clientId,
      redirectUri: environment.oidc.redirectUri,
      logo: '/assets/images/logo-tmhcc.svg',
      i18n: {
        en: {
          'primaryauth.title': 'Sign In',
        },
      },
      authClient: oktaAuth,
      useClassicEngine: environment.widget.USE_CLASSIC_ENGINE === true,
    });
  }

  async ngOnInit() {
    if (document.getElementById('rri-header')) {
      document.getElementById('rri-header').classList.add('hide');
    }
    const authenticated = await this.oktaAuth.isAuthenticated();
    const loginUrl = `${this.configService.config.baseHref}/login`;
    this.loaderService.hide();
    if (authenticated) {
      await this.oktaAuth.signOut(); //loginUrl);
      this.oktaAuth.tokenManager.clear();
      } else {
        const originalUri = this.oktaAuth.getOriginalUri();

        if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
          this.oktaAuth.setOriginalUri('/');
        }
        this.signIn.showSignInToGetTokens({
          el: '#okta-signin-container',
          scopes: environment.oidc.scopes
        }).then(async (tokens: Tokens) => {
          // Remove the widget
          this.signIn.remove();
          // In this flow the redirect to Okta occurs in a hidden iframe
          this.oktaAuth.handleLoginRedirect(tokens);
          const userClaims = await this.oktaAuth.getUser();
          sessionStorage.setItem('UserId', userClaims.name);
          //remove hide class from top header
          document.getElementById('rri-header').classList.remove('hide');
          document.getElementById('headerUserName').textContent = userClaims.name;

        }).catch((err: any) => {
          // Typically due to misconfiguration
          throw err;
        });
      }
  }
  ngOnDestroy() {
    this.signIn.remove();
  }
}
