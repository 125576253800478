export const enum ElementName {
  Text = 'TXT',
  Integer = 'INT',
  Date = 'DT',
  LookupList = 'SLT',
  MultiSelectCombo = 'MSC',
  Radio = 'RB',
  Money = 'MNY',
  MultiCheckBoxes = 'MCB',
  Decimal = 'DEC',
  Checkbox = 'CB',
  Combination = 'CMB'
}

export const enum ElementRequestName {
  TXT = 'FreeTextResponse',
  INT = 'IntegerResponse',
  DT = 'DateResponse',
  SLT = 'ChooseOneComboResponse',
  MSC = 'ChooseManyComboResponse',
  RB = 'ChooseOneResponse',
  MNY = 'MoneyResponse',
  MCB = 'MultipleCheckBoxResponse',
  DEC = 'DecimalResponse',
  CB = 'CheckBoxResponse',
  CMB = 'CombinationResponse'
}
