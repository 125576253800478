import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ClaimsMasterRequest, ClaimsMasterResponse } from './def/claim-master';
import { ClaimMaster } from 'libs/models/classes/claims-master-data';
import { of } from 'rxjs';
// import { ClaimsMasterRequest, ClaimsMasterResponse } from './def/claim-master';
// import { ClaimMaster } from '@tmhcc-ng-lib/models';

@Injectable()
export class ClaimsMasterData extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  masterData: ClaimMaster;
  policyData: any;
  contactPolicyMasterCachedData: any;
  getContactMasterData() {
    return this.contactPolicyMasterCachedData;
  }

  setContactMasterData(data) {
    this.contactPolicyMasterCachedData = data;
  }

  getPolicyMasterData() {
    return this.policyData;
  }

  setPolicyMasterData(data) {
    this.policyData = data;
  }

  claimMasterData(payload: ClaimsMasterRequest): Observable<ClaimMaster> {
    const url = `${this.serviceBaseUrl}/ClaimMasterData/${payload.SubCompanyCode}`;

    return this.get<ClaimsMasterResponse>(url).pipe(
      map(data => {
        this.masterData = this.masterClaimMapper(data);
        return this.masterData;
      }),
      catchError(this.handleError)
    );
  }

  // policyMasterData(payload: ClaimsMasterRequest): Observable<any> {
  //   const url = `${this.serviceBaseUrl}/PolicyMasterData/${payload.SubCompanyCode}`;

  //   return this.get<any>(url).pipe(
  //     map(data => {
  //       this.policyData = data;
  //       return data;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }

  contactMasterData(): Observable<any> {
    if (this.contactPolicyMasterCachedData) {
      return of(this.contactPolicyMasterCachedData);
    } else {
      const url = `${this.serviceBaseUrl}/ContactMasterData`;
      return this.get<any>(url).pipe(
        map(data => {
          this.contactPolicyMasterCachedData = data;
          return data;
        }),
        catchError(this.handleError)
      );
    }
  }

  private masterClaimMapper(data: ClaimsMasterResponse): ClaimMaster {
    let {
      SubCompanies,
      ClaimStatuses,
      LinesOfBusiness,
      CauseofLoss,
      Countries,
      StateOrProvinces,
      ReportedBy,
      ClaimSeverities,
      CaseTypes,
      CaseSubTypes,
      ClaimEntryTypes,
      Disciplines,
      SubDisciplines,
      Professions,
      Underwriters,
      ClaimHandler,
      ReserveTypes,
      Currencies,
      ClaimAttorney,
      InternalAdjuster,
      NatureOfDamages,
      ClaimClosedReasons,
      UserConfigurableDataElements,
      ClaimRecoveryTypes,
      PaymentTypes,
      ClaimPaymentTypes,
      PaymentMethods,
      CashBatchTypes,
      EventTypes
    } = data;

    let entitiyserach: ClaimMaster = {
      ClaimStatuses,
      SubCompanies,
      LinesOfBusiness,
      CauseofLoss,
      Countries,
      StateOrProvinces,
      Underwriters,
      ClaimHandler,
      ReportedBy,
      ClaimSeverities,
      CaseTypes,
      ReserveTypes,
      Currencies,
      CaseSubTypes,
      ClaimEntryTypes,
      Disciplines,
      SubDisciplines,
      Professions,
      ClaimAttorney,
      InternalAdjuster,
      NatureOfDamages,
      ClaimClosedReasons,
      UserConfigurableDataElements,
      ClaimRecoveryTypes,
      PaymentTypes,
      ClaimPaymentTypes,
      PaymentMethods,
      CashBatchTypes,
      EventTypes
    };

    return entitiyserach;
  }

  private handleError(err) {
    return throwError(err);
  }
}
