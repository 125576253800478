import { Observable, Subject } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class DialogRef {
  constructor() {}

  close(result?: any) {
    this._afterClosed.next(result);
  }

  private readonly _afterClosed = new Subject<any>();
  afterClosed: Observable<any> = this._afterClosed.asObservable();
}
