<ng-template #template>
  <div>
    <div
      class="modal-header px-0"
      *ngIf="message.title != undefined && message.title != ''"
    >
      <h4 class="modal-title pull-left">{{ message.title }}</h4>
      <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body px-0">
      <div class="row">
        <div class="col-sm-8 offset-sm-2">
          <div *ngIf="message" [ngClass]="message.cssClass">
            <i
              *ngIf="message.showIcon"
              [ngClass]="message.iconCls"
              aria-hidden="true"
            ></i>
            &nbsp;
            <span>
              {{ message.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer px-0">
      <button
        class="btn btn-primary btn-sm"
        (click)="closeModal()"
        data-dismiss="modal"
        type="button"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
