import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  TemplateRef
} from '@angular/core';

import { CustomPopupAlertService } from '../custom-popup-alert.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'ac-custom-popup-alert',
  templateUrl: './custom-popup-alert.component.html',
  styleUrls: ['./custom-popup-alert.component.scss']
})
export class CustomPopupAlertComponent implements OnInit, OnDestroy {
  @ViewChild('template', { static: true })
  noteBox: TemplateRef<any>;
  private subscription: Subscription;
  message: any;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private alertService: CustomPopupAlertService
  ) {}

  ngOnInit() {
    this.subscription = this.alertService.getAlert().subscribe(message => {
      switch (message && message.type) {
        case 'success':
          message.iconCls = 'fa fa-check';
          message.cssClass = 'alert alert-success';
          this.openModal();
          break;
        case 'error':
          message.iconCls = 'fa fa-times-circle';
          message.cssClass = 'alert alert-danger';
          this.openModal();
          break;
        case 'warning':
          message.iconCls = 'fa fa-exclamation-triangle';
          message.cssClass = 'alert alert-warning';
          this.openModal();
          break;
        case 'info':
          message.iconCls = 'fa fa-info-circle';
          message.cssClass = 'alert alert-info';
          this.openModal();
          break;
      }

      this.message = message;
    });
  }

  openNotes() {
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modalService.show(this.noteBox, {
      class: 'gray modal-lg',
      animated: false,
      backdrop: 'static',
      keyboard: false
    });
  }

  closeModal() {
    this.modalRef.hide();
  }

  closeAlert() {
    this.closeModal();
    this.alertService.clear();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
