import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { PciSubjectivitiesDataResponse,PciSubjectivityGrid,PciSubjectivityGridRow } from "../models/classes/pci-subjectivity-data.model";
import {PciSaveSubjectiviyRequest,PciSubjectivityUpdateRequest} from "libs/data-service/def/pci-subjectivity";
import { coveragePrimeryFoodRequest } from 'libs/data-service/def/coverage-pf-request.d';
@Injectable()
export class PciCoveragesService extends BaseDataService {    
    constructor(
        protected http: HttpClient,
        protected configService: AppConfigService
    ) {
        super(http, configService);
    }

    saveCoveragesPrimaryFood(payload:any): Observable<string>  {
        let baseURL = this.serviceBaseUrl;   
        let serviceApiKey = this.config.dataServiceApiKey;      
        const url = `${baseURL}/api/v1.0/coverages/primary/food`;   
        return this.postv1<coveragePrimeryFoodRequest>(url, { body: payload },serviceApiKey).pipe(
          map(data => this.mapSaveCoveragesPrimaryFood(data)) ,
          catchError(this.handleError)     
        );
    }
    mapSaveCoveragesPrimaryFood(data: any) {   
        return data;
      }
      protected handleError(err) {
        return throwError(err);
      }
}
