import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBase } from '../input-base';
import { has } from '@tmhcc/util';

@Component({
  selector: 'hcc-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent extends InputBase<boolean> implements OnInit {
  constructor() {
    super();
  }
  toolTipText = '';
  ngOnInit() {
    if (has(this.fieldInfo, 'label') && this.fieldInfo.label.length > 30) {
      this.toolTipText = this.fieldInfo.label;
    }
    if (
      this.InputConfig.checkboxConfig === undefined ||
      this.InputConfig.checkboxConfig === null
    ) {
      this.InputConfig.checkboxConfig = {
        marginRequired: true,
        customGridCheckbox: false
      };
    }
  }

  getCustomStyle(value) {
    if (value) {
      return 'translateY(-16px)scale(1.5)';
    } else {
      return 'translate scale(1.5)';
    }
  }
}
