<form [formGroup]="frmGroup" class="pl-1" *ngIf="!fieldInfo.hidden">
  <div class="form-check">
    <hcc-custom-validation [validations]="fieldInfo.validations">
      <input
        type="checkbox"
        [checked]="frmGroup.get(control).value"
        inputValidateRef
        updateEvent="change"
        [formControlName]="control"
        acCheckbox
        [control]="control"
        [formGroup]="frmGroup"
        [fieldInfo]="fieldInfo"
        [marginRequired]="InputConfig?.checkboxConfig?.marginRequired"
        class="form-check-input"
        *ngIf="InputConfig?.isDisabled"
        disabled
        [ngStyle]="{
          transform: getCustomStyle(
            InputConfig?.checkboxConfig?.customGridCheckbox
          )
        }"
      />
      <input
        *ngIf="!InputConfig?.isDisabled"
        type="checkbox"
        [checked]="frmGroup.get(control).value"
        inputValidateRef
        updateEvent="change"
        [formControlName]="control"
        acCheckbox
        [control]="control"
        [formGroup]="frmGroup"
        [fieldInfo]="fieldInfo"
        [marginRequired]="InputConfig?.checkboxConfig?.marginRequired"
        class="form-check-input"
        [ngStyle]="{
          transform: getCustomStyle(
            InputConfig?.checkboxConfig?.customGridCheckbox
          )
        }"
      />
    </hcc-custom-validation>
    <label
      [ngbTooltip]="toolTipText"
      tooltipClass="my-custom-class"
      *ngIf="fieldInfo.showLabel"
      [fieldInfo]="fieldInfo"
      acForLable
      [fieldRequired]="fieldInfo.required"
    >
      {{ fieldInfo.label
      }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
    </label>
  </div>
  <!-- <pre>{{frmGroup.get(control).value}}</pre> -->
</form>
