import { AfterViewInit, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import isEqual from 'lodash-es/isEqual';

@Directive()
export class ValueAccessorBase<T>
  implements ControlValueAccessor, AfterViewInit {
  protected innerValue: T;
  protected loaded = false;
  disabled: boolean;
  changed = [];
  touched = [];

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    if (!this.equal(this.innerValue, value)) {
      this.innerValue = value;
      this.change(value);
    }
  }

  protected equal(val1: T, val2: T) {
    return isEqual(val1, val2);
  }

  touch() {
    this.touched.forEach(f => f());
  }

  change(value: T) {
    this.changed.forEach(f => f(value));
  }

  writeValue(value: T): void {
    if (!this.loaded) {
      this.innerValue = value;
      return;
    }
    this.value = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaded = true;
    });
  }
}
