import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { AppConfig } from '@tmhcc-ng-lib/models';
import { HttpOptions } from '@tmhcc-ng-lib/framwork/http';

export abstract class BaseContentService {
  protected config: AppConfig;
  protected serviceBaseUrl: string;

  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    this.config = configService.config;
    this.serviceBaseUrl = this.config.dataServiceBaseUrl;
  }

  protected get<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.http.get<T>(url, options);
  }

  protected post<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.http.post<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }
}
