import { Injectable, Inject } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { has } from '@tmhcc/util';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class OktaAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
    const authenticated = await this.oktaAuth.isAuthenticated();
    if (authenticated) {
      if (!sessionStorage.getItem('UserId') || !localStorage.getItem('UserId')) {
        const userClaims = await this.oktaAuth.getUser();
        if (has(userClaims, 'email')) {
          sessionStorage.setItem('UserId', userClaims.email);
          localStorage.setItem('UserId', userClaims.email);
        }
      }
      return true;
    }
    sessionStorage.setItem('okta-app-url', state.url || this.router.url);

    // Updated to use Authorization Code flow with redirect
    // N.B. this is required to resolve the issue with future deprecation of
    // 3rd party cookies
    this.oktaAuth.signInWithRedirect();

    return false;
  }
}
