<form [formGroup]="frmGroup">
  <label
    [ngbTooltip]="fieldInfo.label"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validations">
    <input
      type="text"
      [value]="value"
      (change)="change($event.target.value)"
      (blur)="touch()"
      inputValidateRef
      [control]="control"
      [formGroup]="frmGroup"
      class="form-control"
      [formControlName]="control"
      [ngbTypeahead]="search"
    />
  </hcc-custom-validation>
</form>
