<form [formGroup]="frmGroup">
  <label
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <a
    href="javascript:void(0)"
    class="disablePara m-0"
    (click)="change(frmGroup.get(control).value)"
  >
    {{ frmGroup.get(control).value }}
  </a>
</form>
