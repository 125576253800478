import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';

import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable()
export class UCMDataService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }
  protected data = new Map();

  getUCMDataApiService(formid) {
    // if (this.data.has(formid)) {
    //   return of(this.data.get(formid));
    // }
    return this.fetchForm(formid);
  }

  private fetchForm(formId) {
    return this.get<any>(`${this.serviceBaseUrl}/FormBuild/` + formId).pipe(
      map(data => {
        //  this.data.set(formId, data);
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err) {
    return throwError(err);
  }
}
