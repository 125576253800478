export class PciSubjectivitiesDataResponse {
    constructor(
        public subjectivities: PciSubjectivities[]
    ) { }
}
export class PciSubjectivities{
  id:string;
  type: string;
  description: string;
}    

export class PciSubjectivityGridRow {    
    description: string;    
    checked: boolean;
    type: string;
    subjectivityid: string;
    isBlank: boolean;
    checkboxChecked?: boolean;
    createdBy: string;
    constructor() {}
  }
  
  export class PciSubjectivityGrid {
    subjectivityData: PciSubjectivityGridRow[];
  }
  
  export class PciSaveMiscellaneousChangesModel {
    PolicyTxnId: number;
    PolicyId: number;
    PolicyNum?: string;
    PolicyTxnNum: number;
    TxnStatusCode?: string;
  }
  