import { FieldInfo } from './field-info';
import { DateFormats } from '@tmhcc-ng-lib/framwork/services';
import { FieldTypes } from './field-types';

export interface SelectOptionSettings {
  displayKey?: string;
  placeholder?: string;
  search?: boolean;
  limitTo?: number;
}
export interface SelectList {
  selectedOption?: any[];
  options: any[];
  optionConfig?: SelectOptionSettings;
  multiple?: boolean;
  dependent?: DependentSelect;
  gridView?: boolean;
  placeholder?: string;
  appendTo?: string;
  type?: string;
}

export interface CheckboxConfig {
  marginRequired?: boolean;
  customGridCheckbox?: boolean;
}

export interface ComboBoxConfig {
  field: InputConfig[];
  label: string;
  //FieldSecond : InputConfig;
}

export interface DependentSelect {
  field: string;
  state: boolean;
  mapper: {
    key: string;
    value: Array<string>;
  };
}

export interface TextArea {
  rows?: number;
  col?: number;
}

export interface Datepicker {
  dateInputFormat: DateFormats;
  isAnimated?: boolean;
  adaptivePosition?: boolean;
  hideOnScroll?: boolean;
  returnFocusToInput?: boolean;
  selectFromOtherMonth?: boolean;
  containerClass?:
    | 'theme-default'
    | 'theme-green'
    | 'theme-blue'
    | 'theme-dark-blue'
    | 'theme-red'
    | 'theme-orange';
  disableDays?: any[];
  disableDates?: Date[];
  enableDates?: Date[];
  maxDate?: Date;
  defaultDate?: Date;
  minDate?: Date;
  isCalanderDisable?: boolean;
  isCalanderRequired?: boolean;
  outSideClick?: boolean;
  showWeekNumbers?: boolean;
}

export interface BaseInputConfig {
  class: string;
  name: string;
}

export interface ToggleSwitch {
  switchLabel: string;
}

export interface RadioGroup {
  label: string;
  value: string;
  style?: string;
  checked?: boolean;
}
export interface RadioConfig {
  radioGroup: RadioGroup[];
}

export interface InputConfig {
  config?: {
    value?: any;
    baseConfig?: BaseInputConfig;
    textAreaConfig?: TextArea;
    selectConfig?: SelectList;
    checkboxConfig?: CheckboxConfig;
    datePickerConfig?: Datepicker;
    switchConfig?: ToggleSwitch;
    radioCofig?: RadioConfig;
    validation?: any;
    readonlyConfig?: ReadOnlyConfig;
    optionField?: any;
    isDisabled?: boolean;
    isHidden?: boolean;
    numericConfig?: NumericConfig;
    isDelete?: boolean;
  };
  colClass?: string;
  rowVisible?: boolean;
  isAdvanceSearch?: boolean;
  fieldInfo: FieldInfo;
  dependency?: ControlDependency;
  isSortable?: boolean;
  columnType?: string;
  isKeyColumn?: boolean;
}

export interface ControlDependency {
  parentControl?: string;
  childControl?: any;
  valueType?: any;
  dependencyType?: any;
}

export interface NumericConfig {
  type?: string;
}

export interface ReadOnlyConfig {
  readOnly?: boolean;
  type?: FieldTypes;
}
