export class PciEndorsementsDataResponse {
    constructor(
        public endorsements: PciEndorsements[]
    ) { }
}
export class PciEndorsements{
  id:string;
  type: string;
  description: string;
}    

export class PciEndorsementsGridRow {    
    description: string;    
    checked: boolean;
    type: string;
    subjectivityid: string;
    isBlank: boolean;
    checkboxChecked?: boolean;
    createdBy: string;
    constructor() {}
  }
  
  export class PciEndorsementsGrid {
    subjectivityData: PciEndorsementsGridRow[];
  }
  
  // export class PciSaveMiscellaneousChangesModel {
  //   PolicyTxnId: number;
  //   PolicyId: number;
  //   PolicyNum?: string;
  //   PolicyTxnNum: number;
  //   TxnStatusCode?: string;
  // }
  