import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

//import { OktaAuthService } from '@okta/okta-angular';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { OktaAuth } from "@okta/okta-auth-js";
@Component({
  selector: 'app-logout',
  template: ``
})
export class LogoutComponent implements OnInit, OnDestroy {
  constructor(
   // private oktaAuth: OktaAuthService,
    protected configService: AppConfigService,
    private oktaAuth: OktaAuth,
  ) {}

  async ngOnInit() {
    sessionStorage.clear();
    localStorage.clear();
    const url = `${this.configService.config.baseHref}/login`;
    await this.oktaAuth.signOut({postLogoutRedirectUri:url});
  }

  ngOnDestroy() {}
}
