import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import {
  ClaimPolicySearchResponse,
  ClaimPolicySearchRequest
} from './def/claim-policy-search';
import { ClaimSearchGridRow } from '@tmhcc-ng-lib/models';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { CommonContentService } from '@tmhcc-ng-lib/content-service';
import {
  ClaimGetPolicyResponse,
  ClaimGetPolicyRequest,
  Risk
} from './def/claim-get-policy';
import { ClaimGetPolicyData } from 'libs/models/classes/claim-getpolicy.model';

@Injectable()
export class ClaimGetPolicyService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  getPolicyData: ClaimGetPolicyData;

  claimGetPolicyServiceData(
    payload: ClaimGetPolicyRequest
  ): Observable<ClaimGetPolicyData> {
    const url = `${this.serviceBaseUrl}/GetClaimPolicy/${payload.policyTxnId}/${payload.lossDate}`;
    return this.get<ClaimGetPolicyResponse>(url).pipe(
      map(data => {
        this.getPolicyData = this.policySearchDataMapper(data);
        return this.getPolicyData;
      }),
      catchError(this.handleError)
    );
  }

  private policySearchDataMapper(
    data: ClaimGetPolicyResponse
  ): ClaimGetPolicyData {
    if (!data) return;
    let risk: Risk[] = [];
    let claimsMapData: ClaimGetPolicyData;
    let lobDescription = '';
    let uWLOBId: number[] = [];
    let policyParticipationId: number;
    let participationPercent: string;
    let lobArr = [];
    let {
      UWQuotes,
      PolicyTypeDescription,
      SubCompanyName,
      PolicyTxnNum,
      PolicyId
    } = data;
    if (UWQuotes && UWQuotes.length) {
      UWQuotes.forEach(UWItems => {
        UWItems.LOBs.forEach(lobItems => {
          lobArr.push(lobItems);
          lobDescription = lobItems.LOBDescription;
          participationPercent = lobItems.ParticipationPercent;
          uWLOBId.push(lobItems.UWLOBId);
          policyParticipationId = lobItems.PolicyParticipationId;
          lobItems.Risks.forEach((riskItem: Risk) => {
            risk.push(riskItem);
          });
        });
      });
    }
    claimsMapData = {
      PolicyTypeDescription: PolicyTypeDescription,
      LOBDescription: lobDescription,
      LOBs: lobArr,
      Risks: risk,
      UWLOBId: uWLOBId,
      PolicyId: PolicyId,
      PolicyTxnNum: PolicyTxnNum,
      SubCompanyName: SubCompanyName,
      ParticipationPercent: participationPercent,
      PolicyParticipationId: policyParticipationId
    };
    return claimsMapData;
  }

  private handleError(err) {
    return throwError(err);
  }
}
