import { Component, forwardRef } from '@angular/core';
import { InputBase } from '../input-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hcc-textarea-input',
  templateUrl: './textarea.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaInputComponent),
      multi: true
    }
  ]
})
export class TextareaInputComponent extends InputBase<string> {
  constructor() {
    super();
  }
}
