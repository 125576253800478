import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { UpdateClaimResponse } from './def/update-claim';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class UpdateClaimService extends BaseDataService {
  updateClaimRes: UpdateClaimResponse;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  updateClaim(data) {
    const url = `${this.serviceBaseUrl}/UpdateClaim`;
    return this.put<UpdateClaimResponse>(url, { body: data }).pipe(
      map(data => {
        this.updateClaimRes = data;
        return this.updateClaimRes;
      }),
      catchError(this.handleError)
    );
  }

  saveClaimEvent(data): Observable<UpdateClaimResponse> {
    const url = `${this.serviceBaseUrl}/SaveClaimEvent`;
    return this.put<UpdateClaimResponse>(url, { body: data }).pipe(
      map(data => {
        this.updateClaimRes = data;
        return this.updateClaimRes;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(err) {
    return throwError(err);
  }

  // update general info page update req
  setGeneralInfoUpdateReq(updatedValues, getClaimResp) {
    let updateClaimReq = {};
    Object.keys(updatedValues).forEach(keys => {
      switch (keys) {
        case 'CaseSubTypes':
          if (_.has(updatedValues[keys], 'CaseSubTypeId')) {
            updateClaimReq['CaseSubTypeId'] = updatedValues[keys].CaseSubTypeId;
          }
          break;

        case 'CauseofLoss':
          if (_.has(updatedValues[keys], 'Id')) {
            updateClaimReq['CauseofLossId'] = updatedValues[keys].Id;
          }
          break;

        case 'ClaimAttorney':
          if (_.has(updatedValues[keys], 'ClaimAttorney')) {
            updateClaimReq['ClaimsAttorney'] = Number(
              updatedValues[keys].ClaimAttorney
            );
          }
          break;

        // case 'ClaimClosedReasons':
        //   if (_.has(updatedValues[keys], 'Id')) {
        //     updateClaimReq['ClaimCloseReasonId'] = updatedValues[keys].Id;
        //   }
        //   break;

        // case 'ClaimHandler':
        //   if (_.has(updatedValues[keys], 'ClaimHandler')) {
        //     updateClaimReq['ClaimHandler'] = Number(updatedValues[keys].ClaimHandler);
        //   }
        //   break;

        case 'ClaimSeverities':
          if (_.has(updatedValues[keys], 'ClaimSeverityId')) {
            updateClaimReq['SeverityId'] = updatedValues[keys].ClaimSeverityId;
          }
          break;

        case 'ClaimStatuses':
          updateClaimReq['IsClosedClaim'] =
            updatedValues[keys].Id == 1 || updatedValues[keys].Id == 4
              ? false
              : true;
          break;

        case 'ClaimTitle':
          updateClaimReq['ClaimTitle'] =
            updatedValues[keys] != undefined ? updatedValues[keys] : '';
          break;

        case 'Countries':
          updateClaimReq['LossCountry'] = _.has(updatedValues[keys], 'Id')
            ? updatedValues[keys].Id
            : '';
          break;

        case 'LossCity':
          updateClaimReq['LossCity'] = updatedValues[keys];
          break;

        case 'SubDisciplines':
          if (_.has(updatedValues.SubDisciplines, 'SubDisciplineId')) {
            updateClaimReq['SubDisciplineId'] =
              updatedValues.SubDisciplines.SubDisciplineId;
          }
          break;

        case 'Disciplines':
          if (_.has(updatedValues.Disciplines, 'DisciplineId')) {
            updateClaimReq['DisciplineId'] =
              updatedValues.Disciplines.DisciplineId;
          }
          break;
        case 'LossDescription':
          updateClaimReq['LossDescription'] = updatedValues[keys];
          break;

        case 'ReportedBy':
          updateClaimReq['ReportedById'] = _.has(
            updatedValues[keys],
            'ClaimReportedById'
          )
            ? updatedValues[keys].ClaimReportedById
            : '';
          break;

        case 'StateOrProvinces':
          if (_.has(updatedValues[keys], 'Id')) {
            updateClaimReq['LossStateId'] = Number(updatedValues[keys].Id);
          }
          break;

        case 'NatureOfDamages':
          if (_.has(updatedValues[keys], 'NatureOfDamageId')) {
            updateClaimReq['NatureOfDamageId'] =
              updatedValues.NatureOfDamages.NatureOfDamageId;
          }
          break;

        case 'AltClaimNumber':
          updateClaimReq['SecondaryClaimNum'] = updatedValues.AltClaimNumber;
          break;

        case 'CoverageDate':
          updateClaimReq['ClaimCoverageDate'] = updatedValues.CoverageDate;
          break;

        case 'Counties':
          updateClaimReq['LossCountyId'] = updatedValues.Counties.CountyId;
          break;
      }

      // if (_.has(getClaimResp, 'LossCounty')) {
      //   updateClaimReq['LossCountyId'] = getClaimResp.LossCounty;
      // }

      if (_.has(getClaimResp, 'CaseTypeId')) {
        updateClaimReq['CaseTypeId'] = getClaimResp['CaseTypeId'];
      }

      // if (_.has(getClaimResp, 'AltClaimNumber')) {
      //   updateClaimReq['AltClaimNum'] = getClaimResp.AltClaimNumber;
      // }

      if (_.has(getClaimResp, 'ClaimId')) {
        updateClaimReq['ClaimId'] = getClaimResp.ClaimId;
      }

      if (_.has(getClaimResp, 'SubCompanyCode')) {
        updateClaimReq['SubCompanyCode'] = getClaimResp.SubCompanyCode;
      }

      // if (_.has(getClaimResp, 'NatureofDamages')) {
      //   updateClaimReq['NatureOfDamageId'] = getClaimResp.NatureofDamages;
      // }

      if (_.has(getClaimResp, 'ClaimHandler')) {
        updateClaimReq['ClaimHandler'] = getClaimResp.ClaimHandler;
      }
      if (_.has(getClaimResp, 'ClaimAttorney')) {
        updateClaimReq['ClaimsAttorney'] = getClaimResp.ClaimAttorney;
      }
    });
    return updateClaimReq;
  }

  // update event info page update req
  setEventInfoUpdateReq(getClaimResp) {
    let updateClaimReq = {};
    Object.keys(getClaimResp).forEach(keys => {
      switch (keys) {
        case 'PrimaryCauseofLoss':
          updateClaimReq['CauseofLossId'] = getClaimResp[keys];
          break;

        case 'ClaimAttorney':
          updateClaimReq['ClaimsAttorney'] = _.has(
            getClaimResp[keys],
            'ClaimAttorney'
          )
            ? Number(getClaimResp[keys].ClaimAttorney)
            : 0;
          break;

        case 'ClaimClosedReasons':
          updateClaimReq['ClaimCloseReasonId'] = _.has(getClaimResp[keys], 'Id')
            ? getClaimResp[keys].Id
            : 0;
          break;

        case 'ClaimHandler':
          updateClaimReq['ClaimHandler'] = _.has(
            getClaimResp[keys],
            'ClaimHandler'
          )
            ? Number(getClaimResp[keys].ClaimHandler)
            : 0;
          break;

        case 'ClaimSeverities':
          if (_.has(getClaimResp[keys], 'ClaimSeverityId')) {
            updateClaimReq['SeverityId'] = getClaimResp[keys].ClaimSeverityId;
          }
          break;

        case 'ClaimStatuses':
          updateClaimReq['IsClosedClaim'] =
            getClaimResp[keys].Id == 1 || getClaimResp[keys].Id == 4
              ? false
              : true;
          break;

        case 'ClaimTitle':
          updateClaimReq['ClaimTitle'] =
            getClaimResp[keys] != undefined ? getClaimResp[keys] : '';
          break;

        case 'Countries':
          updateClaimReq['LossCountry'] = _.has(getClaimResp[keys], 'Id')
            ? getClaimResp[keys].Id
            : '';
          break;

        case 'LossCity':
          updateClaimReq['LossCity'] = getClaimResp[keys];
          break;

        case 'LossDescription':
          updateClaimReq['LossDescription'] = getClaimResp[keys];
          break;

        case 'ReportedBy':
          updateClaimReq['ReportedBy'] = _.has(
            getClaimResp[keys],
            'ClaimReportedById'
          )
            ? getClaimResp[keys].ClaimReportedById
            : '';
          break;

        case 'StateOrProvinces':
          updateClaimReq['LossState'] = _.has(getClaimResp[keys], 'Id')
            ? Number(getClaimResp[keys].Id)
            : 0;
          break;

        case 'Professions':
          updateClaimReq['CaseTypeId'] = _.has(getClaimResp[keys], 'CaseTypeId')
            ? Number(getClaimResp[keys].CaseTypeId)
            : 0;
          break;
      }
    });
    updateClaimReq['CauseofLoss'] = _.has(getClaimResp, 'PrimaryCauseofLoss')
      ? getClaimResp.PrimaryCauseofLoss
      : 0;
    return updateClaimReq;
  }

  setUpdateRequest(page, updatedValues = null, getClaimResp = null) {
    let updateClaimReq;
    if (page == 'generalInfo') {
      updateClaimReq = this.setGeneralInfoUpdateReq(
        updatedValues,
        getClaimResp
      );
    } else if (page == 'eventInfo') {
      updateClaimReq = this.setEventInfoUpdateReq(getClaimResp);
    }
    return updateClaimReq;
  }
}
