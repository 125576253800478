import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { InputBase } from '../input-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { has } from '@tmhcc/util';

@Component({
  selector: 'hcc-ready-only',
  templateUrl: './read-only.component.html',
  styleUrls: ['./read-only.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReadyOnlyComponent),
      multi: true
    }
  ]
})
export class ReadyOnlyComponent extends InputBase<string> implements OnInit {
  constructor() {
    super();
  }
  toolTipText = '';
  ngOnInit() {
    if (has(this.fieldInfo, 'label') && this.fieldInfo.label.length > 30) {
      this.toolTipText = this.fieldInfo.label;
    }
  }
}
