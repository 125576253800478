import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ComboBoxConfig,
  InputConfig
} from '@tmhcc-ng-lib/framwork/form-inputs';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { has } from '@tmhcc/util';

@Component({
  selector: 'hcc-deb-combobox',
  templateUrl: './deb-combobox.component.html',
  styleUrls: ['./deb-combobox.component.scss']
})
export class DebComboboxComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder) {}

  @Input() comboConfig;
  @Input() frmGroup: UntypedFormGroup;
  @Input() data: any;
  @Output() changeValue = new EventEmitter();
  // new EventEmitter<{
  //   value: any;
  //   IsInvalid: boolean;
  // }>();
  ChangeSub: Subscription;
  controlComboVisible = false;

  ngOnInit(): void {
    this.setControls(this.comboConfig, this.data);
  }

  setControls(fieldConfig: ComboBoxConfig, data: any) {
    if (!fieldConfig || fieldConfig.field.length === 0) {
      return;
    }
    const obj = {};
    fieldConfig.field.forEach(element => {
      if (element) {
        let validators;
        if (element.config && element.config.validation) {
          validators = element.config.validation;
        } else {
          validators = null;
        }
        this.frmGroup.addControl(
          element.fieldInfo.fieldName,
          has(element.config, 'value') && element.config.value !== undefined
            ? new UntypedFormControl(
                element.config.value,
                Validators.compose(validators)
              )
            : new UntypedFormControl('', Validators.compose(validators))
        );
      }
    });
    this.controlComboVisible = true;
    fieldConfig.field.forEach((element: InputConfig) => {
      this.frmGroup
        .get(element.fieldInfo.fieldName)
        .valueChanges.subscribe(value => {
          this.changeValue.emit(true);
        });
      // }
    });
  }
}
