import { Moment } from '@tmhcc-ng-lib/framwork/services';
import { ElementUpdateRequests } from 'libs/data-service/def/quote-policy-step';
import { UpdateUserConfigurableDataElement } from '@tmhcc-ng-lib/data-service';

export class PolicyTxnData {
  PolicyNum?: number;
  PolicyTxnId?: string;
  InsuredContactId?: number;
  PolicyProducerContactId?: number;
  SubCompanyName?: string;
  PolicyTypeId?: number;
  PolicyTypeDescription?: string;
  TermTypeId?: number;
  User1Id?: number;
  User2Id?: number;
  NextRenewalOptionId?: number;
  SubmissionTypeId?: number;
  PolicyYear?: string;
  CountryCode?: string;
  StateId?: number;
  TxnStatusCode?: string;
  TxnEff?: string;
  CurrencyCode?: string;
  TxnPayPlanId?: number;
  TxnBillToContactId?: number;
  PolicyStatus?: string;
  PolicyTermNum: number;
  PolicyTxnNum: number;
  PolicyEff?: any;
  PolicyExp?: any;
  DateReceived?: string;
  RateDate?: string;
  ProcessStatusCode?: string;
  //policyDataElements: policyDataElements[];
  policyDataElements: policyDataElements;
  UpdateUserConfigurableDataElement: UpdateUserConfigurableDataElement;
  PolicyMovement?: PolicyMovement[];
  CreateActivityLogItem?: CreateActivityLogItemObj;
  PolicyContacts?: PolicyContacts[];
  AdditionalSummaries?: AdditionalSummaries[];
  UWQuotes: UWQuotes[];
  DMSFolderId: string;
  OrgFullName: string;
  TxnTypeDescription: string;
  BrokerName: string;
  Insuredname: string;
  TxnTypeCodeDesc: string;
}
export class policyDataElements {
  ElementUpdateRequests: ElementUpdateRequests[];
}
export class PolicyMovement {
  PolicyId: number;
  PolicyTxnId: number;
  PortalStatus: number;
  Status: string;
  ProcessStatusCode?: string;
  TxnTypeDescription?: string;
}

export class AdditionalSummaries {
  Description: string;
  Amount: number;
  UWQuoteId?: number;
}
export class UWQuotes {
  UWQuoteId: number;
  QuoteDescription: string;
  LOBs: LOBs[];
  WritingCompanyId: number;
  QuoteStatus?: string;
  ProducerCommissions: ProducerCommissions[];
  UWQuotaShares: UWQuotaShares[];
}

export class UWQuotaShares {
  QuotaSharePremium: number;
}

export class ConsolidatedTaxesAndFeesResponseItems {
  TaxesAndFees?: number;
  TaxType?: string;
  WrittenAmount?: number;
}

export class ProducerCommissions {
  CommRate?: number;
  DefaultCommRate?: number;
  CommAmt?: number;
  UWQuoteId?: number;
  UWLOBId?: number;
  PolicyProducerContactId?: number;
  OverrideComm?: boolean;
  IsPayNet?: boolean;
}

export class LOBs {
  StateId: number;
  Risks: LOBRisks[];
}

export class LOBRisks {
  RiskDataElements: RiskDataElements;
  RiskTypeCode: string;
  Coverages: Coverages[];
  UWRiskId?: number;
}
export class Coverages {
  CovTypeCode?: string;
  PerOccurLimit?: number;
  PremiumAmount: number;
  CovDescription?: string;
}
export class RiskDataElements {
  ElementUpdateRequests: ElementUpdateRequests[];
}

// export class ElementUpdateRequests {
//   ElementCode: string;
//   ElementId: number;
//   ElementName?: string;
//   ResponseType: string;
//   FreeTextValue: string;
//   DateValue: string;
//   SelectedDataElementResponseIds: number[];
//   DecimalValue: number;
//   MoneyValue: number;
//   RowNum: number;
//   DataGroupName?: string;
//   BooleanValue?: boolean = false;
// }
export class PolicyTxnRequest {
  PolicyTxnID?: string;
}

export class PolicyTxnPayload {
  policyTxnID?: string;
}
export class QuotePolcyTxnData {
  policyTxnData: PolicyTxnData;
}
export class CreateActivityLogItemObj {
  PolicyTxnId?: number;
  ActivityType?: string;
  ActivityDate?: string;
  Description?: string;
  LoggedInUser?: string;
  Amount?: number;
}
export class PolicyContacts {
  ContactId?: number;
  FullName?: string;
}
export class QuoteOptions {
  customId?: number;
  UWRiskId?: number;
  RiskTypeCode: string;
  eventVenue?: string;
  option1?: number;
  option2?: number;
  option3?: number;
  option4?: number;
  option5?: number;
  checkedOption1?: boolean;
  checkedOption2?: boolean;
  checkedOption3?: boolean;
  checkedOption4?: boolean;
  checkedOption5?: boolean;
  AdditionalPerls?: number;
  infoIconText?: string;
  customID: string;
  additionalPerilLabel1: string;
  additionalPerilLabel2: string;
  additionalPerilLabel3: string;
  additionalPerilValue1: string;
  additionalPerilValue2: string;
  additionalPerilValue3: string;
  address: string;
  baseRate: number;
  bioChemTerrIncl: number;
  city: string;
  civilCommotion: number;
  communicableDisease: number;
  country: string;
  csTornadoHail: number;
  cyber: number;
  earthquake: number;
  era: number;
  eventDeductible: number;
  eventDescription: string;
  eventEndDate: Moment;
  eventLimitOfInsurance: number;
  eventStartDate: Moment;
  eventType: string;
  limitedTerrorism: number;
  limitedWar: number;
  nationalMounting: number;
  nonAppearance: number;
  nucBioChemTerrorism: number;
  nuclearHazard: number;
  state: string;
  strike: number;
  terrorism: number;
  war: number;
  wildfire: number;
  windstorm: number;
  winterWeather: number;
  zipCode: number;
  empty?: string;
  tradeShowPolicytype?: boolean;
  standardPolicyType?: boolean;
  readOnly?: boolean;
}

export class PolicySubmissionTxnData {
  PolicyTxnId?: number;
  PolicyNum?: number;
  PolicyTypeId?: number;
  PolicyType?: string;
  StatusOfPolicy?: string;
  ProcessStatusCode?: string;
  ProcessStatusDescription?: string;
  TxnStatusCode?: string;
  TxnTypeCode?: string;
  TxnTypeCodeDesc?: string;
  InsuredContactId?: number;
  InsuredContactName?: string;
  PolicyProducerContactId?: number;
  PolicyProducerName?: string;
  SelectedQuoteId?: number;
  PortalStatusId?: number;
  PortalStatus?: string;
  PolicyStatus?: string;
  DataelementResponseID?: number;
  ProductDescription?: string;
}

export class PolicyData {
  PolicyTxnId?: number;
  PolicyNum?: number;
  PolicyTypeId?: number;
  PolicyType?: string;
  StatusOfPolicy?: string;
  ProcessStatusCode?: string;
  ProcessStatusDescription?: string;
  TxnStatusCode?: string;
  TxnTypeCode?: string;
  TxnTypeCodeDesc?: string;
  InsuredContactId?: number;
  InsuredContactName?: string;
  PolicyProducerContactId?: number;
  PolicyProducerName?: string;
  SelectedQuoteId?: number;
  PortalStatusId?: number;
  PortalStatus?: string;
  PolicyStatus?: string;
  DataelementResponseID?: number;
  ProductDescription?: string;
}

export class ManualTaxAndFeeDataList {
  data: ManualTaxAndFeeData[];
}
export class ManualTaxAndFeeData {
  TaxId: number;
  TaxTypeName: string;
  TaxTypeId: number;
  IsFee: boolean;
  TaxTypeDescription: string;
}
