import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CoverageTypes } from '@tmhcc-ng-lib/models';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import {
  PolicyFormBuilderResponse,
  Formbuilder,
  Quote,
  Lobs,
  Risks,
  Coverages,
  FluffyDataElement,
  PolicyTxnAttributes
} from './def/coverage-policies';
import { has } from '@tmhcc/util';
import {
  CoverageCodes,
  RiskType,
  PolicyTxnDataElement
} from './def/coverage-limit-data-elements';
import { InitialDataElements } from '@tmhcc-product-contamination/framwork/data-element-builder';
import { ElementName } from 'libs/framwork/data-element-builder/src/lib/utility/deb-utility.def';
import {
  genericObj,
  CoverageDataElementsService
} from '@tmhcc/product-contamination';
import { QouteProductType } from './def/rri-risk-product';

@Injectable()
export class FranchiseFormBuilderService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private requestConverterService: CoverageDataElementsService
  ) {
    super(http, configService);
  }

  quoteCachedData = [];
  quoteFormBuilderCache: Formbuilder;
  quoteAttributeCache = [];
  QuoteResultCache = [];
  UWquoteId: number = null;
  quoteGeneralCache: PolicyTxnAttributes;

  lobDataElements: FluffyDataElement[] = [];
  lobCoverages: Coverages[] = [];
  lobLevelRisks = [];
  QuoteContainer: QouteProductType[] = [];

  updateQuote(payload: any) {
    console.log(
      payload.PolicyData.Data[0].Attributes.PolicyTxn.Attributes.Quotes,
      'request======='
    );
    const url = `${this.serviceBaseUrl}/UpdateQuoteRRI`;
    return this.put<PolicyFormBuilderResponse>(
      url,
      { body: payload },
      false
    ).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  get FormBuilderQuotes() {
    if (!this.quoteCachedData) {
      throw new Error(
        'Form Builder data must be fetched before it can be used.'
      );
    }
    return this.quoteCachedData;
  }

  public mapToQuotePolicyFranchiseMem(
    data: Formbuilder,
    coveragesTypes: CoverageTypes[],
    dataTypeElements: InitialDataElements[]
  ): any {
    //if (!data) return;
    if (data) {
      this.lobCoverages = [];
      this.lobDataElements = [];
      this.lobCoverages = [];
      this.lobLevelRisks = [];
      this.quoteAttributeCache = [];
      this.lobDataElements = [];
      this.quoteCachedData = [];
      let quoteData: Quote[] = [];
      let lobsData: Lobs[] = [];
      let riskData: Risks[] = [];
      this.QuoteContainer = [];
      this.quoteFormBuilderCache = data;
      // if (data) {
      if (has(data['Data'][0], 'Attributes')) {
        this.quoteGeneralCache =
          data['Data'][0]['Attributes']['PolicyTxn']['Attributes'];
        quoteData =
          data['Data'][0]['Attributes']['PolicyTxn']['Attributes']['Quotes'];
        let isExistElements =
          data['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
            'DataElements'
          ];
        if (isExistElements) {
          isExistElements.forEach(el => {
            if (el.Attributes.ElementCode in PolicyTxnDataElement) {
              //covElements.push(element);
              this.UWquoteId = el.Attributes['IntegerResponse'];
            }
          });
        }
      }
      //debugger;
      quoteData.forEach((element: Quote) => {
        let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
        let resultQuoteData: genericObj = {
          PolicyAggr: null, //
          InsRetention: null, //
          ExtortionCost: null, //
          CrisisConsultExp: null, //
          LostGrossProfitLimit: null, //
          IncresedCost: null, //
          Inoculations: null, //
          Accidental: null, //
          AccidentalPremium: null,
          Tampering: null, //
          SupContamination: null, //
          AdversePub: null, //
          OverallAggr: null, //
          PerOccLimit: null, //
          Deductibles: null, //
          OneYrPremium: null,
          TwoYrPremium: null,
          WaitingPeriod: null,
          RecallExp: null,
          RehabExp: null,
          LGPIncRoyalties: null,
          LGPIndPeriod: null,
          WPVIndemPeriod: null,
          PremType: null,
          RNoLocs: null,
          TwoYrPrepaidFactor: null,
          BasePrem: null,
          WPVPrem: null,
          TRIARate: null,
          TRIAPrem: null,
          PolicyTypeId: null,
          UWQuoteId: null,
          QuoteDescription: '',
          QuoteNum: null,
          PerLocationLimit: null,
          RecallExpPerLoc: null,
          RehabExpPerLoc: null,
          AccidentalPerLocation: null,
          TamperingPerLocation: null,
          SupContaminationPerLocation: null,
          AdversePubPerLocation: null,
          QuoteStatusCode: '',
          ProcessStatusCode: '',
          Premium: null,
          Taxes: null,
          FR: null,
          TaxFeeDscr1: null,
          TaxFeeAmnt1: null,
          TaxFeeDscr2: null,
          TaxFeeAmnt2: null,
          TaxFeeDscr3: null,
          TaxFeeAmnt3: null,
          TaxFeeDscr4: null,
          TaxFeeAmnt4: null,
          TaxFeeDscr5: null,
          TaxFeeAmnt5: null
        };
        if (has(this.quoteGeneralCache, 'ProcessStatusCode')) {
          resultQuoteData.ProcessStatusCode = this.quoteGeneralCache.ProcessStatusCode;
        }
        if (has(this.quoteGeneralCache, 'WrittenPrem')) {
          resultQuoteData.Premium = this.quoteGeneralCache.WrittenPrem;
        }
        if (has(this.quoteGeneralCache, 'WrittenTaxes')) {
          resultQuoteData.Taxes = this.quoteGeneralCache.WrittenTaxes;
        }
        resultQuoteData.PolicyTypeId = element.Attributes.PolicyTxnId;
        resultQuoteData.UWQuoteId = element.Attributes.UWQuoteId;
        resultQuoteData.QuoteDescription = element.Attributes.QuoteDescription;
        resultQuoteData.QuoteNum = element.Attributes.QuoteNum;
        if (has(element.Attributes, 'QuoteStatusCode')) {
          resultQuoteData.QuoteStatusCode = element.Attributes.QuoteStatusCode;
        }
        this.lobLevelRisks = riskResult;
        riskResult.forEach((riskElement: Risks) => {
          let QuoteContainerObj: QouteProductType = {
            QuoteId: null,
            RiskId: null,
            QuoteNum: null,
            DataElements: [],
            Coverages: [],
            WrittenPrem: null,
            WrittenTaxes: null
          };
          //Added on Mar 28 2021 for adding container
          QuoteContainerObj.QuoteId = element.Attributes.UWQuoteId;
          QuoteContainerObj.QuoteNum = element.Attributes.QuoteNum;
          if (has(element.Attributes, 'WrittenPrem')) {
            QuoteContainerObj.WrittenPrem = element.Attributes.WrittenPrem;
          }
          if (has(element.Attributes, 'WrittenTaxes')) {
            QuoteContainerObj.WrittenTaxes = element.Attributes.WrittenTaxes;
          }
          //QuoteContainerObj.RiskCodeType = riskElement.Attributes.RiskTypeCode;
          QuoteContainerObj.RiskId = riskElement.Attributes.UWRiskId;
          QuoteContainerObj.RiskCodeType = riskElement.Attributes.RiskTypeCode;
          QuoteContainerObj.DataElements = riskElement.Attributes.DataElements;
          QuoteContainerObj.Coverages = riskElement.Attributes.Coverages;
          //Ended on Mar 28 2021 for adding container
          // let riskNestcoverages = riskElement.Attributes.Coverages;
          // let dataNestElement = riskElement.Attributes.DataElements;
          if (
            QuoteContainerObj.DataElements &&
            riskElement.Attributes.RiskTypeCode === RiskType.LOB
          ) {
            // this.lobDataElements = dataNestElement;

            QuoteContainerObj.DataElements.forEach(
              (dataElement: FluffyDataElement) => {
                let objElement: FluffyDataElement;
                let isPresent = dataTypeElements.find(function(el) {
                  return el.ElementCode === dataElement.Attributes.ElementCode;
                });
                if (isPresent && isPresent.ResponseType === ElementName.Text) {
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.TextResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.lobDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Integer
                ) {
                  if (dataElement.Attributes.ElementCode === '1YrPrem') {
                    dataElement.Attributes.ElementCode = 'OneYrPremium';
                  }
                  if (dataElement.Attributes.ElementCode === '2YrPrem') {
                    dataElement.Attributes.ElementCode = 'TwoYrPremium';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.IntegerResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.lobDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Decimal
                ) {
                  if (
                    dataElement.Attributes.ElementCode === '2YrPrepaidFactor'
                  ) {
                    dataElement.Attributes.ElementCode = 'TwoYrPrepaidFactor';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.DecimalResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.lobDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.Money
                ) {
                  if (dataElement.Attributes.ElementCode === 'FPLPerLocLimit') {
                    dataElement.Attributes.ElementCode = 'PerLocationLimit';
                  }
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    dataElement.Attributes.MoneyResponse;
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.lobDataElements.push(objElement);
                } else if (
                  isPresent &&
                  isPresent.ResponseType === ElementName.LookupList
                ) {
                  let objData = isPresent.DataElementResponses.find(function(
                    el
                  ) {
                    return (
                      el.DataElementResponseId ===
                      dataElement.Attributes.DataElementResponseId
                    );
                  });
                  resultQuoteData[dataElement.Attributes.ElementCode] =
                    objData !== undefined ? objData : {};
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.lobDataElements.push(objElement);
                } else {
                  objElement = dataElement;
                  objElement.RiskType = riskElement.Attributes.RiskTypeCode;
                  objElement.QuoteNum = element.Attributes.QuoteNum;
                  objElement.ElementId = dataElement.Attributes.ElementId;
                  this.lobDataElements.push(objElement);
                  //this.lobDataElements
                }
              }
            );
          }
          if (
            QuoteContainerObj.Coverages &&
            riskElement.Attributes.RiskTypeCode === RiskType.LOB
          ) {
            //this.lobCoverages = riskNestcoverages;
            QuoteContainerObj.Coverages.forEach((covergeElement: Coverages) => {
              let objCoverage: Coverages;
              var isPresent = coveragesTypes.find(function(el) {
                return el.CovTypeId === covergeElement.Attributes.CovTypeId;
              });
              //---
              if (isPresent && isPresent.CovTypeCode === CoverageCodes.RRI) {
                resultQuoteData.PolicyAggr =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                resultQuoteData.InsRetention =
                  covergeElement.Attributes.PerOccurDed;
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              }
              //---
              else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACL
              ) {
                resultQuoteData.AccidentalPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.MTL
              ) {
                resultQuoteData.TamperingPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.SCL
              ) {
                resultQuoteData.SupContaminationPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.APL
              ) {
                resultQuoteData.AdversePubPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ASC
              ) {
                resultQuoteData.SupContamination =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.WVA
              ) {
                resultQuoteData.OverallAggr =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                resultQuoteData.Deductibles =
                  covergeElement.Attributes.PerOccurDed; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ICW
              ) {
                resultQuoteData.IncresedCost =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.LGP
              ) {
                resultQuoteData.LostGrossProfitLimit =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.IV
              ) {
                resultQuoteData.Inoculations =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.XXC
              ) {
                resultQuoteData.ExtortionCost =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACO
              ) {
                resultQuoteData.CrisisConsultExp =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACC
              ) {
                resultQuoteData.Accidental =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.MTA
              ) {
                resultQuoteData.Tampering =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ADP
              ) {
                resultQuoteData.AdversePub =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.ACL
              ) {
                resultQuoteData.AccidentalPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.MTL
              ) {
                resultQuoteData.TamperingPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.SCL
              ) {
                resultQuoteData.SupContaminationPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.APL
              ) {
                resultQuoteData.AdversePubPerLocation =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else if (
                isPresent &&
                isPresent.CovTypeCode === CoverageCodes.WRK
              ) {
                resultQuoteData.PerOccLimit =
                  covergeElement.Attributes.AggregateLimit; //or else per occ
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              } else {
                objCoverage = covergeElement;
                objCoverage.RiskType = riskElement.Attributes.RiskTypeCode;
                objCoverage.QuoteNum = element.Attributes.QuoteNum;
                this.lobCoverages.push(covergeElement);
              }
            });
          }
          this.QuoteContainer.push(QuoteContainerObj);
        });
        this.quoteAttributeCache.push(resultQuoteData);
      });
      // }
      this.quoteCachedData = quoteData;
      return quoteData;
    }
  }

  get GetQuoteContainer() {
    if (!this.QuoteContainer) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }

    return this.QuoteContainer;
  }

  get GetFormBuilder() {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }

    return this.quoteFormBuilderCache;
  }

  get GetPolicyLevelDataElement() {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    let PolicyDataElement = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    return PolicyDataElement;
  }

  updateDataElement(elementKey, UWQuoteId) {
    let oldCDataElements = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    let isOldElementExist;
    //const oldCDataElements = this.GetPolicyLevelDataElement;
    if (oldCDataElements) {
      isOldElementExist = oldCDataElements.find(function(el) {
        return el.Attributes.ElementCode === elementKey.ElementCode;
      });
    }
    if (!isOldElementExist) {
      let objelement = {};
      oldCDataElements = [];
      //isNewCovergaeExist.ResponseType = ElementName.Integer;
      objelement = this.requestConverterService.createIntegerField(
        elementKey,
        UWQuoteId
      );
      // let  this.requestConverterService.createIntegerField();
      let objElement = {
        Id: null,
        Type: 'DataElements',
        Attributes: objelement
      };
      // objCoverage.Attributes[Field] = Value;
      oldCDataElements.push(objElement);
      // (result.type = 'new'), (result.covid = isNewCovergaeExist.CovTypeId);
    } else {
      const index = oldCDataElements.findIndex(
        item => item.Attributes.ElementCode === elementKey.ElementCode
      );
      if (index > -1) {
        if (elementKey.ResponseType === ElementName.Integer) {
          //isOldElementExist.Attributes.ResponseType = ElementName.Integer;
          (oldCDataElements[index]['ElementId'] =
            oldCDataElements[index].Attributes.ElementId),
            (oldCDataElements[index].Attributes['IntegerResponse'] = UWQuoteId);
        }
      }
      // result.covid = isOldCoverageExist.CovTypeId;
    }

    this.quoteAttributeCache = [];
    let quoteData: Quote[] = [];
    if (this.quoteFormBuilderCache) {
      if (has(this.quoteFormBuilderCache['Data'][0], 'Attributes')) {
        quoteData = this.quoteFormBuilderCache['Data'][0]['Attributes'][
          'PolicyTxn'
        ]['Attributes']['Quotes'];
      }
      //debugger;
      quoteData = quoteData.filter(function(obj) {
        return obj.Attributes.UWQuoteId === UWQuoteId;
      });
      this.quoteFormBuilderCache['Data'][0]['Attributes']['PolicyTxn'][
        'Attributes'
      ]['Quotes'] = quoteData;
      this.quoteFormBuilderCache['Data'][0]['Attributes']['PolicyTxn'][
        'Attributes'
      ]['DataElements'] = oldCDataElements;
    }
    return this.quoteFormBuilderCache.Data;
    //this.dataElements.forEach(element => {});
  }

  UpdateFormBuilder(QuoteId, DataElement) {
    if (!this.quoteFormBuilderCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    let PolicyDataElement = this.quoteFormBuilderCache.Data[0].Attributes
      .PolicyTxn.Attributes.DataElements;
    //PolicyDataElement =
    let data = this.quoteFormBuilderCache.Data[0].Attributes.PolicyTxn
      .Attributes.Quotes;
    let data1 = data.filter(el => el.Attributes.UWQuoteId == QuoteId);
    // data.forEach(element => {
    //   delete element.QuoteNum;
    // });
    return this.quoteFormBuilderCache;
  }

  GetBindSummary(DataElements) {
    if (!this.quoteGeneralCache) {
      throw new Error(
        'quote form builder data data must be fetched before it can be used.'
      );
    }
    let termObj = [];
    let wpvSelect;
    let triaSelect;
    this.lobDataElements.forEach(el => {
      if (el.Attributes.ElementCode === 'TRIASelect' && el.RiskType === 'LOB') {
        triaSelect = el.Attributes.DataElementResponseId;
      }
      if (el.Attributes.ElementCode === 'WPVSelect' && el.RiskType === 'LOB') {
        wpvSelect = el.Attributes.DataElementResponseId;
      }
    });
    DataElements.forEach(ele => {
      if (ele.ElementId === 29907) {
        let isExist = this.quoteGeneralCache.DataElements.find(
          el => el.Attributes.ElementId === 29907
        );
        if (isExist) {
          termObj = ele.DataElementResponses.filter(
            value =>
              value.DataElementResponseId ==
              isExist.Attributes.DataElementResponseId
          );
        }
      }
      if (ele.ElementCode === 'TRIASelect' && triaSelect) {
        // if (el && value) {
        let filterId = ele['DataElementResponses'].filter(
          el => el.DataElementResponseId === triaSelect
        );
        triaSelect = filterId[0]['ResponseName'];
      }
      if (ele.ElementCode === 'WPVSelect' && wpvSelect) {
        let filterId = ele['DataElementResponses'].filter(
          el => el.DataElementResponseId === wpvSelect
        );
        wpvSelect = filterId[0]['ResponseName'];
      }
    });
    let policyBindDetails = {
      TermLength: termObj,
      PolicyEff: this.formatDate(this.quoteGeneralCache.PolicyEff),
      PolicyExp: this.formatDate(this.quoteGeneralCache.PolicyExp),
      WpvSelect: wpvSelect === undefined ? 'Yes' : wpvSelect,
      TriaSelect: triaSelect === undefined ? 'Yes' : triaSelect,
      WrittenPrem: this.quoteGeneralCache.WrittenPrem,
      WrittenTaxes: this.quoteGeneralCache.WrittenTaxes,
      WriitenTotal:
        this.quoteGeneralCache.WrittenPrem + this.quoteGeneralCache.WrittenTaxes
    };
    return policyBindDetails;
  }

  formatDate(element) {
    let date;
    if (element) {
      let calDate = this.dateService.toLongISO(
        this.dateService.moment(element)
      );
      date = new Date(calDate);
    } else {
      date = '';
    }
    return date;
  }

  get GetPolicyDetails() {
    if (!this.quoteGeneralCache) {
      throw new Error(
        'quote policy details must be fetched before it can be used.'
      );
    }
    return this.quoteGeneralCache;
  }

  setUWQuoteId(Id) {
    this.UWquoteId = Id;
  }

  getUWQuoteId() {
    return this.UWquoteId;
  }

  get GetFormBuilderCoverages() {
    if (!this.lobCoverages) {
      throw new Error(
        'coverages form builder data data must be fetched before it can be used.'
      );
    }

    return this.lobCoverages;
  }

  get GetFormBuilderDataElements() {
    if (!this.lobDataElements) {
      throw new Error(
        'Data Elements form builder data data must be fetched before it can be used.'
      );
    }

    return this.lobDataElements;
  }

  get GetFormBuilderQuote() {
    if (!this.quoteAttributeCache) {
      throw new Error('quote data must be fetched before it can be used.');
    }

    return this.quoteAttributeCache;
  }

  SetFranchiseFormBuilderValue(
    result: Formbuilder,
    coveragesTypes: Coverages[],
    alldataTypeElements: FluffyDataElement[],
    QuoteNum: number
  ) {
    //let result: Formbuilder;
    //result = this.quoteFormBuilderCache;
    if (result) {
      let quoteData: Quote[] = [];
      if (result) {
        if (has(result['Data'][0], 'Attributes')) {
          quoteData =
            result['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
              'Quotes'
            ];
        }
        //debugger;
        quoteData = quoteData.filter(function(obj) {
          return obj.Attributes.QuoteNum === QuoteNum;
        });
        result['Data'][0]['Attributes']['PolicyTxn']['Attributes'][
          'Quotes'
        ] = quoteData;
        quoteData.forEach((element: Quote) => {
          let riskResult = element.Attributes.Lobs[0].Attributes.Risks;
          riskResult.forEach((riskElement: Risks) => {
            //let dataNestElement;
            if (!riskElement.Attributes.DataElements) {
              riskElement.Attributes['DataElements'] = [];
              // dataNestElement = riskElement.Attributes.DataElements;
            }
            //else {
            //riskElement.Attributes['DataElements'] = [];
            // dataNestElement = riskElement.Attributes.DataElements;
            //}
            if (riskElement.Attributes.RiskTypeCode === RiskType.LOB) {
              if (alldataTypeElements) {
                let elements = alldataTypeElements.filter(
                  el => el.RiskType === RiskType.LOB && el.QuoteNum === QuoteNum
                );
                elements.forEach(function(v) {
                  if (v.RiskType == RiskType.LOB) {
                    delete v.QuoteNum;
                    delete v.RiskType;
                    delete v.ElementId;
                    delete v.Attributes.ResponseType;
                  }
                });
                riskElement.Attributes.DataElements = elements;
              }
            }
            // if (riskElement.Attributes.RiskTypeCode === RiskType.FCH) {
            //   if (alldataTypeElements) {
            //     let elements = alldataTypeElements.filter(
            //       el => el.RiskType === RiskType.FCH && el.QuoteNum === QuoteNum
            //     );

            //     elements.forEach(function(v) {
            //       if (v.RiskType == RiskType.FCH) {
            //         delete v.QuoteNum;
            //         delete v.RiskType;
            //         delete v.ElementId;
            //         delete v.Attributes.ResponseType;
            //       }
            //     });
            //     riskElement.Attributes.DataElements = elements;
            //   }
            // }
            if (riskElement.Attributes.RiskTypeCode === RiskType.LOB) {
              if (coveragesTypes) {
                let elements = coveragesTypes.filter(
                  el => el.RiskType === RiskType.LOB && el.QuoteNum === QuoteNum
                );
                elements.forEach(function(v) {
                  delete v.QuoteNum;
                  delete v.RiskType;
                  delete v.CovTypeId;
                });
                riskElement.Attributes.Coverages = elements;
              }
            }
            // else if (riskElement.Attributes.RiskTypeCode === RiskType.FCH) {
            //   if (coveragesTypes) {
            //     let elements = coveragesTypes.filter(
            //       el => el.RiskType === RiskType.FCH && el.QuoteNum === QuoteNum
            //     );
            //     elements.forEach(function(v) {
            //       delete v.QuoteNum;
            //       delete v.RiskType;
            //       delete v.CovTypeId;
            //     });
            //     riskElement.Attributes.Coverages = elements;
            //   }
            // }
          });
        });
      }
      return result.Data;
    }
  }

  private handleError(err) {
    return throwError(err);
  }

  setRisks = {
    Id: null,
    Type: '',
    Attributes: {}
  };
}
