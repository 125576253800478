import { Injectable } from '@angular/core';
import { InputConfig } from '@tmhcc-ng-lib/framwork/form-inputs';
import { Validators } from '@angular/forms';
import { Form, Field } from '../def/sitecore-data-def';
import { DateService } from '@tmhcc-ng-lib/framwork/services';

@Injectable()
export class FormSitecoreMapperService {
  constructor(private dateService: DateService) {}

  mapFormData(data: Form[]): Form[] {
    let form = [];
    if (data && data.length > 0) {
      data.forEach(element => {
        form.push({
          formName: element.formName,
          IsAdvanceSearch: element.IsAdvanceSearch,
          fields: this.fieldDataManipulation(element.fields)
        });
      });
    }
    return form;
  }

  fieldDataManipulation(fields: Field[]): Field[] {
    fields.forEach((field: Field) => {
      return Object.keys(field.fieldInfo).forEach((data, index) => {
        if (data == 'validations') {
          const validation: any = Object.values(field.fieldInfo)[index];
          if (validation.length) {
            return this.setFieldConfig(field, validation);
          }
        }
        field.config = {};
        if (field && field.fieldInfo.fieldType == 'Date') {
          field.fieldInfo.validations.push({
            key: 'bsDate',
            value: `Please Enter a valid date`
          });
          field.config['datePickerConfig'] = {
            dateInputFormat: this.dateService.getFormat(),
            isCalanderRequired: true,
            isAnimated: true,
            maxDate: new Date()
          };
        }
        if (field && field.fieldInfo.fieldType == 'LookupList') {
          field.config['selectConfig'] = {
            options: [],
            optionConfig: {
              displayKey: ''
            },
            selectedOption: []
          };
        }
        if (field && field.fieldInfo.fieldType == 'Numeric') {
          field.config['numericConfig'] = {
            type: ''
          };
        }
      });
    });
    return fields;
  }

  setFieldConfig(field, fieldValidations) {
    const validationArray = [];
    if (field && fieldValidations) {
      fieldValidations.forEach(validations => {
        switch (validations.key) {
          case 'required':
            if (validations.value && validations.value != '') {
              validations.value = validations.value.replace(
                '{fieldname}',
                field.fieldInfo.label
              );
            }
            return validationArray.push(Validators.required);
          case 'maxlength':
            return validationArray.push(
              Validators.maxLength(field.fieldInfo.maxChar)
            );
          case 'minlength':
            return validationArray.push(
              Validators.minLength(field.fieldInfo.minChar)
            );
          case 'pattern':
            return validationArray.push(
              Validators.pattern(field.fieldInfo.pattern)
            );
        }
      });
      field.config.validation = validationArray;
      return field;
    }
  }
}
