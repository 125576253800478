import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import { CurrencyService } from '@tmhcc-ng-lib/framwork/services';
@Pipe({
  name: 'gridCellFormatter'
})
export class GridCellFormatterPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyService,
    private dateService: DateService,
    private titleCasePipe: TitleCasePipe
  ) {}
  transform(value: any, format: string): any {
    if (format) {
      if (format.toLowerCase() === 'currency') {
        return this.currencyPipe.toCurrency(value, 'USD');
      } else if (format.toLowerCase() === 'date') {
        if (value)
          return this.dateService.toShortDate(this.dateService.moment(value));
      }
      // else if (format.toLowerCase() === 'string') {
      //   return this.titleCasePipe.transform(value);
      // }
    }
    return value;
  }
}
