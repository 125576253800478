import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementFormat } from '../element-format';
import { TextFormElement } from '../text/text-form-element';
import { has } from '@tmhcc/util';

@Directive({
  selector: 'input[acInputNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberDirective),
      multi: true
    }
  ]
})
export class InputNumberDirective extends TextFormElement {
  @HostBinding('autocomplete') autocomplete = 'off';
  fieldVal = '';
  decimalCount = 0;
  decimalImpl = false;

  @HostListener('input', ['$event'])
  onChange(event) {
    this.fieldVal = event.target.value;
    this.change(event.target.value);
  }
  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute('updateEvent') protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
  //private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
  //private regex: RegExp = new RegExp(/^[0-9]+(,[0-9]{1,3})*(\.[0-9]*){0,1}$/g);
  private regex: RegExp = new RegExp(/^[0-9-]*$/);
  private AllowedKeyStrok = [46, 8, 9, 27, 13, 110, 190, 45];
  private specialKeys: Array<string> = [
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Comma',
    ',',
    'Dash',
    'Shift'
  ];

  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let decimalPlace;
    if (this.fieldInfo.decimal) {
      decimalPlace = this.fieldInfo.decimal;
    } else {
      decimalPlace = 2;
    }
    const regEx = new RegExp(/[!@#$%^&*(),?":{}|<>/;_+=']/);
    if (this.specialKeys.indexOf(event.key) !== -1 || regEx.test(event.key)) {
      event.preventDefault();
      return;
    }

    const e = <KeyboardEvent>event;
    if (this.fieldVal.split('.')[1]) {
      this.AllowedKeyStrok = [8, 9, 27, 13, 110];
      this.decimalImpl = true;
    } else {
      this.AllowedKeyStrok = [46, 8, 9, 27, 13, 110, 190, 45];
      this.decimalImpl = false;
    }

    if (this.decimalImpl) {
      this.decimalCount++;
    } else {
      this.decimalCount = 0;
    }

    if (this.decimalCount > decimalPlace - 1) {
      e.preventDefault();
      return false;
    }

    switch (e.key) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
      case 'Backspace':
        return true;
        break;

      case '.':
        if (this.fieldVal.indexOf('.') === -1) {
          return true;
        } else {
          return false;
        }

      case '-':
        if (has(this.fieldInfo, 'allowDash')) {
          if (this.fieldInfo.allowDash) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      default:
        return false;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData(
      'text/plain'
    );
    if (clipboardData) {
      const regEx = new RegExp(/^[1-9]\d*(\.\d{1,2})?$/);
      const regEx2 = new RegExp(/^[0-9-]*$/);
      if (!regEx.test(clipboardData) && !regEx2.test(clipboardData)) {
        event.preventDefault();
      }
    }
    return;
  }
}
