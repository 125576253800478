import { ValueAccessorBase } from './value-accessor-base';
import { Directive } from "@angular/core";

@Directive()
export class ElementBase<T> extends ValueAccessorBase<T> {
  constructor() {
    super();
    this.init();
  }

  private init() {}
}
