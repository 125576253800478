import { BaseContentService } from './base-content-service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { Injectable } from '@angular/core';
import { dashboardGridLable } from './def/dashboard-content';
import { Errors } from './def/common-content';
import { check } from 'prettier';

@Injectable()
export class DashboardContentService extends BaseContentService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  private labels: dashboardGridLable;

  getDashboardGridLabels() {
    if (!this.labels) {
      this.labels = this.staticLabels;
    }
    return this.labels;
  }

  private readonly staticLabels: dashboardGridLable = {
    PendingClaimPaymentsResponse: {
      ClaimPaymentId: 'Claim PaymentId',
      SubCompanyCode: 'SubCompany',
      ClaimTxnStatus: 'Status',
      InsuredName: 'Insured Name',
      CheckNum: 'Check #',
      MailToFileAsName: 'Mail To',
      BilledTotal: 'Billed Total',
      PaidTotal: 'Paid Total',
      InvoiceNum: 'Invoice #',
      CurrencyCode: 'Curr.',
      CreationDate: 'Date Entered',
      CreationUser: 'User Id'
    },
    PendingClaimReservesResponse: {
      ClaimNum: 'Claim No.',
      Insured: 'Insured',
      Status: 'Status',
      ReserveCategory: 'Reserve Category',
      Currency: 'Curr.',
      Amount: 'Amount',
      PerOccur: 'Per Occur.',
      Aggregate: 'Aggregate',
      LineOfBusiness: 'Line Of Business',
      Risk: 'Risk',
      LossDate: 'Loss Date',
      DateEntered: 'Date Entered',
      ReportedType: 'Reported Type',
      ReserveType: 'Reserve Type',
      ClaimAsst: 'Claim Asst',
      ClaimHandler: 'Claim Handler',
      ClaimAttorney: 'Claim Attorney',
      EventID: 'Event ID',
      UserID: 'User ID'
    }
  };
}
