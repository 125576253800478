import { Injectable } from '@angular/core';

import {
  Types,
  GridOptions,
  ColumnDefinition,
  GridDefinition,
  GridOtherDefinitions
} from 'libs/framwork/grid/src/lib/shared/grid.interface';
import { Grid, MappedGridData } from '../def/sitecore-data-def';

@Injectable()
export class GridSitecoreMapperService {
  constructor() {}

  mapGridSettings(data: Grid[]): MappedGridData[] {
    const gridDataArr: MappedGridData[] = [];
    if (data && data.length > 0) {
      data.forEach((element: Grid) => {
        let gridDataObj: MappedGridData = {
          gridName: element.gridName,
          data: {
            GirdDefination: this.mapperForGridDefinitionDataFromSitecore(
              element
            ),
            ColumnDefinition: this.mapperForColumnsDefinitionDataFromSitecore(
              element.columnDefinitions
            ),
            OtherDefination: this.mapperForOtherDefinitionDataFromSitecore(
              element
            )
          }
        };
        gridDataArr.push(gridDataObj);
      });
    }
    return gridDataArr;
  }

  mapperForGridDefinitionDataFromSitecore(data): GridDefinition {
    let obj: any = {
      pageLength: data.pageLength,
      searching: data.searching,
      paging: data.paging,
      ordering: data.ordering,
      isVisibility: data.isVisibility,
      isPersist: data.isPersist,
      gridName: data.gridName,
      allowExcelExport: data.allowExcelExport
    };
    return obj;
  }

  mapperForColumnsDefinitionDataFromSitecore(data): ColumnDefinition[] {
    const colDefs = [];
    data.forEach(element => {
      if (element.isVisible) {
        let obj: any = {
          fieldInfo: {
            fieldName: element.fieldName,
            label: element.label
          },
          isSortable: element.isSortable,
          columnType: element.columnType,
          isKeyColumn: element.isKeyColumn
        };
        if (element.alignment != '') {
          obj.alignment = element.alignment;
        }
        colDefs.push(obj);
      }
    });
    return colDefs;
  }

  mapperForOtherDefinitionDataFromSitecore(data): GridOtherDefinitions {
    let obj: any = {
      isDeleteOption: data.deleteRow,
      isRadioBox: data.isCheckBoxRequired,
      isCheckbox: data.isRadioBoxRequired
    };
    return obj;
  }
}
