export interface coverageInfo {
  PolicyType: string;
  cancelledStatus: boolean;
}

export interface genericObj {
  PolicyAggr: number;
  PerLocationLimit?: number; //
  WaitingPeriod: object;
  InsRetention: number;
  PremType: object;
  TwoYrPrepaidFactor: number;
  RNoLocs: number;
  BasePrem: number;
  WPVPrem: number;
  TRIARate: number;
  TRIAPrem: number;
  OneYrPremium: number;
  TwoYrPremium: number;
  RecallExp: number;
  RecallExpPerLoc?: number; //
  RehabExp: number;
  RehabExpPerLoc?: number; //
  LGPIndPeriod: object;
  LGPIncRoyalties: object;

  ExtortionCost: number;
  CrisisConsultExp: number;
  LostGrossProfitLimit: number;
  IncresedCost: number;
  Inoculations: number;
  Accidental: number;
  AccidentalPremium: number;
  AccidentalPerLocation?: number; //
  Tampering: number;
  TamperingPerLocation?: number; //
  SupContamination: number;
  SupContaminationPerLocation?: number; //
  AdversePub: number;
  AdversePubPerLocation?: number; //
  OverallAggr: number;
  PerOccLimit: number;
  WPVIndemPeriod: object;
  Deductibles: number;
  PolicyTypeId: number;
  UWQuoteId: number;
  QuoteDescription?: string;
  QuoteNum?: number;
  QuoteStatusCode?: string;
  ProcessStatusCode?: string;
  WPVSelect?: object;
  TRIASelect?: object;
  FPLPerLocLimit?: number;
  Premium?: number;
  Taxes?: number;
  FranchiseePolicyLimit?: number;
  WorkplaceViolencePerLocation?: number;
  IsRated?: boolean;
  TermLength?: object;
  PolicyEff?: string;
  PolicyExp?: string;
  //--
  FR?: any;
  TaxState?: any,
  TaxFeeDscr1?: any;
  TaxFeeAmnt1?: any;
  TaxFeeDscr2?: any;
  TaxFeeAmnt2?: any;
  TaxFeeDscr3?: any;
  TaxFeeAmnt3?: any;
  TaxFeeDscr4?: any;
  TaxFeeAmnt4?: any;
  TaxFeeDscr5?: any;
  TaxFeeAmnt5?: any;
}
