import { Moment } from '@tmhcc-ng-lib/framwork/services';

export class ClaimGridRow {
  ClaimId: number;
  ClaimNum: string;
  PolicyNum: string;
  LossDate: Moment;
  ClaimStatus: string;
  ClaimHandler: string;
  InsuredName: string;
  ClaimTitle: string;
  ClaimAttorney?: string;
  ClaimantName?: string;
  RelatedClaimCount?: number;
  //   constructor(){}
}
