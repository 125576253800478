<form [formGroup]="frmGroup" *ngIf="!fieldInfo.hidden">
  <label
    [ngbTooltip]="toolTipText"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validations">
    <input
      type="text"
      inputValidateRef
      [value]="value"
      [formControlName]="control"
      class="form-control"
      acInputText
      [control]="control"
      [fieldInfo]="fieldInfo"
      [readOnly]="InputConfig?.isDisabled"
      [ngClass]="fieldInfo.class"
    />
  </hcc-custom-validation>
</form>
