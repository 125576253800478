export enum CurrencyTypes {
  USD = 'USD',
  INR = 'INR',
  EUR = 'EUR',
  CAD = 'CAD',
  AUD = 'AUD',
  BRL = 'BRL',
  DKK = 'DKK',
  HKD = 'HKD',
  JPY = 'JPY',
  AED = 'AED',
  CHF = 'CHF',
  SEK = 'SEK',
  KRW = 'KRW',
  ZAR = 'ZAR',
  GBP = 'GBP',
  PLN = 'PLN',
  PKR = 'PKR',
  NOK = 'NOK'
}
