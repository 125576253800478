<div class="row">
  <form [formGroup]="frmGroup" *ngIf="controlComboVisible">
    <ng-container *ngFor="let items of comboConfig.field">
      <div [class]="items?.colClass">
        <hcc-input-generator
          [frmGroup]="frmGroup"
          [fieldInfo]="items.fieldInfo"
          [control]="items.fieldInfo.fieldName"
          [InputConfig]="items?.config"
        ></hcc-input-generator>
      </div>
    </ng-container>
  </form>
</div>
