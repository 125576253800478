export const environment = {
  production: true,
  oidc: {
    clientId: "0oa1tu0a6yfQroXvw0h8",
    issuer: "https://hcc-dev.oktapreview.com/oauth2/aus1vw45zznPfwlQl0h8",
    redirectUri: "https://pci-dev.tmhcc.com/callback",
    scopes: ['openid', 'email', 'profile', 'address', 'phone', 'offline_access'],
    testing: {
      disableHttpsCheck: false
    }
  },
  widget: {
    USE_CLASSIC_ENGINE: false
  },
};
