import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextFormElement } from '../text/text-form-element';

@Directive({
  selector: 'textarea[acInputTextArea]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextAreaDirective),
      multi: true
    }
  ]
})
export class InputTextAreaDirective extends TextFormElement {
  @HostBinding('autocomplete') autocomplete = 'off';

  @HostListener('input', ['$event'])
  onChange(event) {
    this.change(event.target.value);
  }

  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute('updateEvent') protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
}
