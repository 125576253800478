import { Provider } from '@angular/core';
import { CommonContentService } from './common-content.service';
import { ClaimSearchContentService } from './claim-search-content.service';

import { from } from 'rxjs';
// services
export * from './common-content.service';
export * from './dashboard-content.service';
export * from './claim-search-content.service';

export const ContentServiceProviders: Provider[] = [CommonContentService];
