import { Provider, Type, APP_INITIALIZER } from '@angular/core';
import { AppConfigService } from 'libs/global-services/app-config.service';
import { getWindow, getBaseUri, initApp } from './factory-functions';
import { BASE_URI } from './tokens/base-uri.token';
import { APP_NAME } from './tokens/app-name.token';
import { WINDOW } from './tokens/window.token';
import { AcHttpInterceptorProviders } from 'libs/framwork/http';
import { AcServiceProviders } from '@tmhcc-ng-lib/framwork/services';
import { DataServiceProviders } from '@tmhcc-ng-lib/data-service';
import { ContentServiceProviders } from '@tmhcc-ng-lib/content-service';

export function GetProviders(
  appName,
  appConfigService: Type<AppConfigService> = AppConfigService
): Provider[] {
  return [
    { provide: AppConfigService, useClass: appConfigService },
    { provide: BASE_URI, useFactory: getBaseUri },
    { provide: APP_NAME, useValue: appName },
    { provide: WINDOW, useFactory: getWindow },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [APP_NAME, AppConfigService]
    },
    ...AcHttpInterceptorProviders,
    ...AcServiceProviders,
    ...DataServiceProviders,
    ...ContentServiceProviders
  ];
}
