import { FieldTypes, InputConfig } from '@tmhcc-ng-lib/framwork/form-inputs';
import { Validators } from '@angular/forms';
import { PolicyTypes } from 'libs/product-contamination/shared/enum-type';
import { DateService } from '@tmhcc-ng-lib/framwork/services';
import {
  PolicyMasterData,
  UserConfigurableDataElements
} from '@tmhcc-ng-lib/models';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
export class CoverageConfig {
  constructor(protected dateService: DateService) {}

  getCovElementData(policyMaster: PolicyMasterData, elementCode) {
    let result = [];
    const objIndex: UserConfigurableDataElements = policyMaster.UserConfigurableDataElements.find(
      obj => obj.ElementCode === elementCode
    );
    if (objIndex) {
      result = objIndex.DataElementResponses;
    }
    return result;
  }

  getQuoteFields(Disabled: boolean = false, masterData): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'TermLength',
          label: 'Term Length',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please select the Term Length'
            }
          ]
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: null,
            options: masterData.UserConfigurableDataElements.find(
              el => el.ElementId === 29907
            ).DataElementResponses,
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          }
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-12 col-sm-6 col-lg-2 col-xl-20'
      },
      {
        fieldInfo: {
          fieldName: 'PolicyEff',
          label: 'Effective Date',
          decimal: 0,
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Date,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Effective Date'
            }
          ]
        },
        config: {
          validation: [Validators.required],
          value: new Date(),
          isDisabled: false,
          datePickerConfig: {
            dateInputFormat: this.dateService.getFormat(),
            isCalanderRequired: true
            //minDate: null
          }
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-12 col-sm-6 col-lg-2 col-xl-20'
      },
      {
        fieldInfo: {
          fieldName: 'PolicyExp',
          label: 'Expiration Date',
          decimal: 0,
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Date,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Expiration Date'
            }
          ]
        },
        config: {
          validation: [Validators.required],
          value: new Date(),
          isDisabled: false,
          datePickerConfig: {
            dateInputFormat: this.dateService.getFormat(),
            isCalanderRequired: true
            // /maxDate: new Date()
          }
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-12 col-sm-6 col-lg-2 col-xl-20'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: '',
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'PolicyAggr',
          label: 'Policy Aggregate',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,

          validations: [
            {
              key: 'required',
              value: 'Please enter the Policy Aggregate.'
            }
          ]
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-12 col-sm-6 col-lg-3 col-xl-20'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: '',
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'PerLocationLimit',
          label: 'Per Location Limit',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Per Location Limit.'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-12 col-sm-6 col-lg-3 col-xl-20'
      },
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'WaitingPeriod',
          label: 'Waiting Period',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please select the Waiting Period'
            }
          ]
        },
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: this.getCovElementData(masterData, 'WaitingPeriod'),
            // options: [
            //   {
            //     DataElementResponseId: 44391,
            //     ResponseCode: '0Days',
            //     ResponseValue: '0 Days',
            //     ResponseName: '0 Days',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 44392,
            //     ResponseCode: '3Days',
            //     ResponseValue: '3 Days',
            //     ResponseName: '3 Days',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 44393,
            //     ResponseCode: '5Days',
            //     ResponseValue: '5 Days',
            //     ResponseName: '5 Days',
            //     ResponseText: ''
            //   }
            // ],
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          }
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-12 col-sm-6 col-lg-3 col-xl-20'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: '',
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'InsRetention',
          label: 'Self-Insured Retention',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Self-Insured Retention'
            }
          ]
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-12 col-sm-6 col-lg-3 col-xl-3'
      }
    ];
  }

  getPremiumTypeFields(Disabled: boolean = false, masterData): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'PremType',
          label: 'Select',
          showLabel: true,
          fieldType: FieldTypes.LookupList
        },
        config: {
          isDisabled: Disabled,
          selectConfig: {
            selectedOption: [],
            options: this.getCovElementData(masterData, 'PremType'),
            // options: [
            //   {
            //     DataElementResponseId: 45387,
            //     ResponseCode: 'PerLocation',
            //     ResponseValue: 'Per Location',
            //     ResponseName: 'Per Location',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 45388,
            //     ResponseCode: 'PerPolicy',
            //     ResponseValue: 'Per Policy',
            //     ResponseName: 'Per Policy',
            //     ResponseText: ''
            //   }
            // ],
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          }
        },
        columnType: PolicyTypes.Corporate,
        colClass: 'col-6'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: ''
        },
        fieldInfo: {
          fieldName: 'TwoYrPrepaidFactor',
          label: '2 Year Prepaid Multiplier',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          validations: [
            {
              key: 'required',
              value: 'Please enter the TwoYrPrepaidFactor'
            }
          ]
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [],
          value: ''
        },
        fieldInfo: {
          fieldName: 'RNoLocs',
          label:
            sessionStorage.policyType == PolicyTypes.Corporate
              ? 'Corporate Location Count'
              : 'Location Count',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          validations: [],
          hidden: false
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      }
    ];
  }

  getUWInputFields(Disabled: boolean = false): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: '',
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'BasePrem',
          label: 'Base Premium',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Base Premium'
            }
          ]
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: '',
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'WPVPrem',
          label: 'Workplace Violence',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Workplace Violence'
            }
          ]
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          value: ''
        },
        fieldInfo: {
          fieldName: 'TRIARate',
          label: 'TRIA Rate',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          validations: [
            {
              key: 'required',
              value: 'Please enter the TRIA Rate'
            }
          ]
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      }
    ];
  }

  getBaseFields(Disabled: boolean = false): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        config: {
          value: '',
          isDisabled: true,
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'OneYrPremium',
          label: '1 Year Premium',
          showLabel: true,
          fieldType: FieldTypes.Numeric
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: '',
          isDisabled: true,
          numericConfig: { type: 'currency' }
        },
        fieldInfo: {
          fieldName: 'TwoYrPremium',
          label: '2 Year Premium',
          showLabel: true,
          decimal: 2,
          fieldType: FieldTypes.Numeric
        },
        colClass: 'col-6',
        columnType: PolicyTypes.Corporate
      }
    ];
  }
  getSurplusLinesFields(
    Disabled: boolean = false,
    masterData,
    taxAndFeesList,
    PolicyStateCode,
    uwFormBuilderData
  ): Observable<InputConfig[]> {
    //debugger;
    let data: InputConfig[];
    data = [
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'FR',
          label: 'Filing Responsibility',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: false
        },
        config: {
          validation: [],
          isDisabled: Disabled,
          value:
            uwFormBuilderData != undefined
              ? { ResponseValue: 'HCC', ResponseCode: 'HCC' }
              : { ResponseValue: 'Broker', ResponseCode: 'BRK' },
          //value: 'Broker',
          selectConfig: {
            selectedOption:
              uwFormBuilderData != undefined
                ? [{ ResponseValue: 'HCC', ResponseCode: 'HCC' }]
                : [{ ResponseValue: 'Broker', ResponseCode: 'BRK' }],
            options: masterData.UserConfigurableDataElements.find(
              el => el.ElementId === 33823
            ).DataElementResponses,
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          }
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          validation: [],
          value: PolicyStateCode,
          isDisabled: true
        },
        fieldInfo: {
          fieldName: 'TaxState',
          label: 'Tax State',
          showLabel: true,
          fieldType: FieldTypes.Text
        },
        colClass: 'col-6 small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'TaxFeeDscr1',
          label: 'Tax/Fee Description',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: false,
          validations: []
        },
        config: {
          validation: [],
          isDisabled: Disabled,
          value: null,
          // validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: taxAndFeesList,
            optionConfig: {
              displayKey: 'TaxTypeDescription',
              search: false,
              limitTo: 3
            },
            multiple: false
          },
          isDelete: false
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          validation: [],
          value: uwFormBuilderData
            ? uwFormBuilderData[0].Attributes.FeeAmt
            : null,
          isDisabled: true,
          numericConfig: {
            type: 'currency'
          },
          isDelete: false
        },
        fieldInfo: {
          fieldName: 'TaxFeeAmnt1',
          label: 'Tax/Fee Amount',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please enter Tax/Fee amount'
            },
            {
              key: 'min',
              value: 'Tax/Fee amount should be greter than zero'
            }
          ]
        },
        colClass: 'col-6 small-label',
        columnType: PolicyTypes.Corporate
      },

      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'TaxFeeDscr2',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please select Tax/Fee Description'
            }
          ]
        },

        config: {
          isDisabled: Disabled,
          value: null,
          validation:[],
          // validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: taxAndFeesList,
            optionConfig: {
              displayKey: 'TaxTypeDescription',
              search: false,
              limitTo: 3
            },
            multiple: false
          },
          isDelete: false
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: uwFormBuilderData
            ? uwFormBuilderData[1] != undefined
              ? uwFormBuilderData[1].Attributes.FeeAmt
              : null
            : null,
          isDisabled: true,
          numericConfig: {
            type: 'currency'
          },
          isDelete: false,
          validation:[],
        },
        fieldInfo: {
          fieldName: 'TaxFeeAmnt2',
          required: false,
          label: '',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          validations: [
            {
              key: 'required',
              value: 'Please enter Tax/Fee amount'
            }
          ]
        },
        colClass: 'col-6 small-label',
        columnType: PolicyTypes.Corporate
      },

      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'TaxFeeDscr3',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please select Tax/Fee Description'
            }
          ]
        },
        config: {
          isDisabled: Disabled,
          value: null,
          validation:[],
          selectConfig: {
            selectedOption: [],
            options: taxAndFeesList,
            optionConfig: {
              displayKey: 'TaxTypeDescription',
              search: false,
              limitTo: 3
            },
            multiple: false
          },
          isDelete: false
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: uwFormBuilderData
            ? uwFormBuilderData[2] != undefined
              ? uwFormBuilderData[2].Attributes.FeeAmt
              : null
            : null,
          isDisabled: true,
          numericConfig: {
            type: 'currency'
          },
          isDelete: false,
          validation:[],
        },
        fieldInfo: {
          fieldName: 'TaxFeeAmnt3',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please enter Tax/Fee amount'
            }
          ]
        },
        colClass: 'col-6 small-label',
        columnType: PolicyTypes.Corporate
      },

      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'TaxFeeDscr4',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please select Tax/Fee Description'
            }
          ]
        },
        config: {
          isDisabled: Disabled,
          value: null,
          selectConfig: {
            selectedOption: [],
            options: taxAndFeesList,
            optionConfig: {
              displayKey: 'TaxTypeDescription',
              search: false,
              limitTo: 3
            },
            multiple: false
          },
          isDelete: false,
          validation:[],
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: uwFormBuilderData
            ? uwFormBuilderData[3] != undefined
              ? uwFormBuilderData[3].Attributes.FeeAmt
              : null
            : null,
          isDisabled: true,
          numericConfig: {
            type: 'currency'
          },
          isDelete: false,
          validation:[],
        },
        fieldInfo: {
          fieldName: 'TaxFeeAmnt4',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please enter Tax/Fee amount'
            }
          ]
        },
        colClass: 'col-6 small-label',
        columnType: PolicyTypes.Corporate
      },

      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'TaxFeeDscr5',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please select Tax/Fee Description'
            }
          ]
        },
        config: {
          isDisabled: Disabled,
          isDelete: false,
          selectConfig: {
            options: taxAndFeesList,
            optionConfig: {
              displayKey: 'TaxTypeDescription',
              search: false,
              limitTo: 3
            },
            //selectedOption: this.getSelectedComboVal(data, value)[0],
            selectedOption: [],
            multiple: false,
          },
          value: null,
          validation:[]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: uwFormBuilderData
            ? uwFormBuilderData[4] != undefined
              ? uwFormBuilderData[4].Attributes.FeeAmt
              : null
            : null,
          //validation: [Validators.required],
          isDisabled: true,
          numericConfig: {
            type: 'currency'
          },
          isDelete: false,
          validation:[]
        },
        fieldInfo: {
          fieldName: 'TaxFeeAmnt5',
          label: '',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          required: false,
          validations: [
            {
              key: 'required',
              value: 'Please enter Tax/Fee amount'
            }
          ]
        },
        colClass: 'col-6 small-label',
        columnType: PolicyTypes.Corporate
      }
    ];
    return of(data);
  }
  getInsuredLossesFields(Disabled: boolean = false, masterData): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required]
        },
        fieldInfo: {
          fieldName: 'RecallExp',
          label: 'Recall Expenses (%)',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Recall Expenses (%)'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required]
        },
        fieldInfo: {
          fieldName: 'RecallExpPerLoc',
          label: 'Recall Expenses (%) Per Location ',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Recall Expenses (%) Per Location'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-6  small-label'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required]
        },
        fieldInfo: {
          fieldName: 'RehabExp',
          label: 'Rehabilitation Expenses (%)',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          decimal: 2,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Rehabilitation Expenses (%)'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required]
        },
        fieldInfo: {
          fieldName: 'RehabExpPerLoc',
          label: 'Rehabilitation Expenses (%) Per Location',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          decimal: 2,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Rehabilitation Expenses (%) Per Location'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-6  small-label'
      },
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'LGPIndPeriod',
          label: 'LGP: Indemnity Period',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please select the Indemnity Period'
            }
          ]
        },
        config: {
          isDisabled: Disabled,
          selectConfig: {
            selectedOption: [],
            options: this.getCovElementData(masterData, 'LGPIndPeriod'),
            // options: [
            //   {
            //     DataElementResponseId: 43344,
            //     ResponseCode: '3Months',
            //     ResponseValue: '3 Months',
            //     ResponseName: '3 Months',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 43345,
            //     ResponseCode: '6Months',
            //     ResponseValue: '6 Months',
            //     ResponseName: '6 Months',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 43346,
            //     ResponseCode: '12Months',
            //     ResponseValue: '12 Months',
            //     ResponseName: '12 Months',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 43347,
            //     ResponseCode: '18Months',
            //     ResponseValue: '18 Months',
            //     ResponseName: '18 Months',
            //     ResponseText: ''
            //   }
            // ],
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          },
          validation: [Validators.required]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'LGPIncRoyalties',
          label: 'LGP: Includes Royalties',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please select the Includes Royalties'
            }
          ]
        },
        config: {
          isDisabled: Disabled,
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: this.getCovElementData(masterData, 'LGPIncRoyalties'),
            // options: [
            //   {
            //     DataElementResponseId: 43342,
            //     ResponseCode: 'Yes',
            //     ResponseValue: 'Yes',
            //     ResponseName: 'Yes',
            //     ResponseText: ''
            //   },
            //   {
            //     DataElementResponseId: 43343,
            //     ResponseCode: 'No',
            //     ResponseValue: 'No',
            //     ResponseName: 'No',
            //     ResponseText: ''
            //   }
            // ],
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          }
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'ExtortionCost',
          label: 'Extortion Costs',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please select the Extortion Costs'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'CrisisConsultExp',
          label: 'Crisis Consultant Expenses',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Crisis Consultant Expenses'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'LostGrossProfitLimit',
          label: 'Lost Gross Profit Limits',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Lost Gross Profit Limits'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'IncresedCost',
          label: 'Increased costs of working',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Increased costs of working'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'Inoculations',
          label: 'Inoculations & Vaccinations',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Inoculations & Vaccinations'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      }
    ];
  }

  getInsuredEventFields(Disabled: boolean = false): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'Accidental',
          label: 'Accidental',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Accidental'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'AccidentalPerLocation',
          label: 'Accidental Per Location',
          showLabel: true,
          required: true,
          fieldType: FieldTypes.Numeric,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Accidental Per Location'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-6  small-label'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'Tampering',
          label: 'Malicious Tampering',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Malicious Tampering'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'TamperingPerLocation',
          label: 'Malicious Tampering Per Location',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Malicious Tampering Per Location'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-6  small-label'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'SupContamination',
          label: 'Supplier Contamination',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Supplier Contamination'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'SupContaminationPerLocation',
          label: 'Supplier Contaminations Per Location',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Supplier Contaminations Per Location'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-6  small-label'
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'AdversePub',
          label: 'Adverse Publicity',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Adverse Publicity'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          isDisabled: Disabled,
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'AdversePubPerLocation',
          label: 'Adverse Publicity Per Location',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Adverse Publicity Per Location'
            }
          ]
        },
        columnType: PolicyTypes.Franchisee,
        colClass: 'col-6  small-label'
      }
    ];
  }

  getWorkPlaceViolenceFields(
    isDisabled: boolean = false,
    masterData
  ): InputConfig[] {
    return [
      {
        isAdvanceSearch: false,
        config: {
          value: '',
          validation: [Validators.required, Validators.max(5000000)],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'OverallAggr',
          label: 'Overall Aggregate',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Overall Aggregate'
            },
            {
              key: 'max',
              value: 'Maximum value allowed: $5,000,000.'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'PerOccLimit',
          label: 'Per Occurrence Limit',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Per Occurrence Limit'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        fieldInfo: {
          fieldName: 'WPVIndemPeriod',
          label: 'Indemnity Period',
          showLabel: true,
          fieldType: FieldTypes.LookupList,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please select the Indemnity Period'
            }
          ]
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [
              {
                DataElementResponseId: 45389,
                ResponseCode: '3Months',
                ResponseValue: '3 Months',
                ResponseName: '3 Months',
                ResponseText: ''
              },
              {
                DataElementResponseId: 45390,
                ResponseCode: '6Months',
                ResponseValue: '6 Months',
                ResponseName: '6 Months',
                ResponseText: ''
              }
              // {
              //   DataElementResponseId: 45391,
              //   ResponseCode: '12Months',
              //   ResponseValue: '12 Months',
              //   ResponseName: '12 Months',
              //   ResponseText: ''
              // },
              // {
              //   DataElementResponseId: 45392,
              //   ResponseCode: '18Months',
              //   ResponseValue: '18 Months',
              //   ResponseName: '18 Months',
              //   ResponseText: ''
              // }
            ],
            optionConfig: {
              displayKey: 'ResponseValue',
              search: false,
              limitTo: 3
            },
            multiple: false
          }
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      },
      {
        isAdvanceSearch: false,
        config: {
          value: '',
          validation: [Validators.required],
          numericConfig: {
            type: 'currency'
          }
        },
        fieldInfo: {
          fieldName: 'Deductibles',
          label: 'Deductible',
          showLabel: true,
          fieldType: FieldTypes.Numeric,
          required: true,
          validations: [
            {
              key: 'required',
              value: 'Please enter the Deductibles'
            }
          ]
        },
        colClass: 'col-6  small-label',
        columnType: PolicyTypes.Corporate
      }
    ];
  }

  lobs() {
    return [
      'PolicyAggr',
      'WaitingPeriod', //
      'InsRetention',
      'LGPIncRoyalties',
      'LGPIndPeriod',
      'SupContamination',
      'OverallAggr',
      'WPVIndemPeriod',
      'PremType',
      'RNoLocs',
      'TwoYrPrepaidFactor',
      'BasePrem',
      'WPVPrem',
      'TRIARate',
      'OneYrPremium',
      'TwoYrPremium',
      'PerOccLimit',
      'Deductibles',
      'WPVSelect',
      'TRIASelect',
      'ActofTerrorism'
      //'RecallExpPerLoc',
      //'RehabExpPerLoc',
      //'AccidentalPerLocation',
      // 'TamperingPerLocation',
      //'SupContaminationPerLocation',
      // 'AdversePubPerLocation'
    ];
  }

  fchs() {
    return [
      'LostGrossProfitLimit',
      'IncresedCost',
      'Inoculations',
      'ExtortionCost',
      'CrisisConsultExp',
      'Accidental',
      'AccidentalPremium',
      'Tampering',
      'AdversePub',
      'RecallExp',
      'RehabExp',
      'TER',
      'FranchiseePolicyLimit',
      'WorkplaceViolencePerLocation'
      //'PerLocationLimit'
    ];
  }

  quoteLOBElements() {
    return [
      'WaitingPeriod',
      'LGPIncRoyalties',
      'LGPIndPeriod',
      'WPVIndemPeriod',
      'PremType',
      'RNoLocs',
      'TwoYrPrepaidFactor',
      'BasePrem',
      'WPVPrem',
      'TRIARate',
      'WPVSelect',
      'TRIASelect'
      //'RecallExpPerLoc',
      //'RehabExpPerLoc'
    ];
  }

  quoteFCHElements() {
    return ['RecallExp', 'RehabExp'];
  }

  FranchiseFCHCoverages() {
    return ['PerLocationLimit'];
  }

  FranchiseElements() {
    return [
      'WaitingPeriod',
      'LGPIncRoyalties',
      'LGPIndPeriod',
      'WPVIndemPeriod',
      'PremType',
      'RNoLocs',
      'TwoYrPrepaidFactor',
      'BasePrem',
      'WPVPrem',
      'TRIARate',
      'RecallExpPerLoc',
      'RehabExpPerLoc',
      'RecallExp',
      'RehabExp',
      'PerLocationLimit' //'FPLPerLocLimit'
    ];
  }

  FranchiseLOBCoverages() {
    return [
      'PolicyAggr',
      'WaitingPeriod', //
      'InsRetention',
      'LGPIncRoyalties',
      'LGPIndPeriod',
      'SupContamination',
      'OverallAggr',
      'WPVIndemPeriod',
      'PremType',
      'RNoLocs',
      'TwoYrPrepaidFactor',
      'BasePrem',
      'WPVPrem',
      'TRIARate',
      'OneYrPremium',
      'TwoYrPremium',
      'RecallExpPerLoc',
      'RehabExpPerLoc',
      'AccidentalPerLocation',
      'TamperingPerLocation',
      'SupContaminationPerLocation',
      'AdversePubPerLocation',
      'Deductibles',
      'LostGrossProfitLimit',
      'IncresedCost',
      'Inoculations',
      'ExtortionCost',
      'CrisisConsultExp',
      'Accidental',
      'Tampering',
      'AdversePub',
      'RecallExp',
      'RehabExp',
      'PerLocationLimit', //'FPLPerLocLimit',
      'WRKPerLocLimit',
      'PerOccLimit'
      // 'FR',
      // 'TaxFeeDscr1',
      // 'TaxFeeAmnt1',
      // 'TaxFeeDscr2',
      // 'TaxFeeAmnt2',
      // 'TaxFeeDscr3',
      // 'TaxFeeAmnt3',
      // 'TaxFeeDscr4',
      // 'TaxFeeAmnt4',
      // 'TaxFeeDscr5',
      // 'TaxFeeAmnt5'
    ];
  }

  SurplusLineElements() {
    return [
      'FR',
      'TaxFeeDscr1',
      'TaxFeeAmnt1',
      'TaxFeeDscr2',
      'TaxFeeAmnt2',
      'TaxFeeDscr3',
      'TaxFeeAmnt3',
      'TaxFeeDscr4',
      'TaxFeeAmnt4',
      'TaxFeeDscr5',
      'TaxFeeAmnt5'
    ];
  }
}
