import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener,
  OnInit,
  Input,
  OnChanges
} from '@angular/core';
import { TextFormElement } from '../text/text-form-element';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldInfo } from '../field-info';
import { TruncateTextPipe } from '@tmhcc-ng-lib/framwork/pipes';

@Directive({
  selector: 'label[acForLable]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputLabelDirective),
      multi: true
    }
  ]
})
export class InputLabelDirective implements OnInit, OnChanges {
  @HostBinding('attr.for') forLabel: string;
  @Input() fieldInfo: FieldInfo;
  @Input() fieldRequired: boolean;
  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    public customPipe: TruncateTextPipe
  ) {}

  ngOnInit() {
    this.forLabel = this.buildForLabel();
  }

  ngOnChanges() {
    this.forLabel = this.buildForLabel();
  }

  protected buildForLabel() {
    let checkForLabelLength = this.customPipe.transform(
      this.fieldInfo.label,
      this.fieldInfo.labelLength || 100
    );
    if (this.fieldInfo.required) {
      checkForLabelLength += ` <span class="required-star">*</span>`;
    }
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      checkForLabelLength
    );

    if (!this.fieldInfo || !this.fieldInfo.fieldName) {
      return this.el.nativeElement.getAttribute('id');
    }
    return `${this.fieldInfo.fieldName}`;
  }
}
