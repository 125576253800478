import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from '@tmhcc-ng-lib/framwork/logging';
// import { ErrorService } from '@tmhcc-ng-lib/framwork/services';
import { ToastrService } from 'ngx-toastr';
import { createGuid } from '@tmhcc/util';
import { LoaderService } from '@tmhcc-ng-lib/framwork/loader';
import { ErrorService } from './error.service';
import { TimeoutError } from 'rxjs';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: HttpErrorResponse) {
    const loader = this.injector.get(LoaderService);
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(ToastrService);

    let message;
    let stackTrace;
    let clientMessage = 'Something went wrong, please try again';
    if (error instanceof HttpErrorResponse) {
      // Server Error
      if (error.status === 504) {
        clientMessage = 'API gateway request timed out. Please try again';
      } else if (error.status === 404) {
        clientMessage =
          'API end point not found on API gateway. Please check the end point url';
      }
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      if (
        message.includes('Endpoint request timed out') &&
        message.includes('504')
      ) {
        clientMessage = 'API gateway request timed out. Please try again';
      } else if (message === 'No Internet Connection') {
        clientMessage =
          'No Internet Connection. Please check your network and try again';
      }
      stackTrace = errorService.getClientStack(error);
    }
    loader.hide();
    notifier.error(clientMessage, '', {
      positionClass: 'toast-top-right',
      progressBar: true,
      timeOut: 5000,
      tapToDismiss: true
    });

    let prop = {
      Tenant: 'TMHHC Restaurant Recovery',
      ErrorId: createGuid(),
      ApplicationPlatform: 'Web.Frontend',
      Component: '',
      Method: ''
    };
    logger.logException(
      new Error('Error: ' + message + ' \nStack Trace: ' + stackTrace),
      prop
    );
  }
}
