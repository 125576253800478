<form [formGroup]="frmGroup">
  <label
    [ngbTooltip]="fieldInfo.label"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validations">
    <textarea
      [value]="value"
      [formControlName]="control"
      class="form-control"
      acInputTextArea
      inputValidateRef
      [rows]="InputConfig?.textAreaConfig?.rows"
      [control]="control"
      [formGroup]="frmGroup"
      [fieldInfo]="fieldInfo"
      [readOnly]="InputConfig?.isDisabled"
      [maxlength]="fieldInfo?.maxChar"
    ></textarea>
  </hcc-custom-validation>
</form>
