import { Moment } from '@tmhcc-ng-lib/framwork/services';

export enum EventDeductibleType {
  VALUE = 'value',
  PERCENTAGE = 'percent'
}

export class EventGridRow {
  customID: string;
  additionalPerilLabel1: string;
  additionalPerilLabel2: string;
  additionalPerilLabel3: string;
  additionalPerilValue1: string;
  additionalPerilValue2: string;
  additionalPerilValue3: string;
  address: string;
  baseRate: number;
  bioChemTerrIncl: number;
  city: string;
  civilCommotion: number;
  communicableDisease: number;
  country: string;
  csTornadoHail: number;
  cyber: number;
  earthquake: number;
  era: number;
  eventDeductible: string;
  eventDeductibleType: string;
  eventDescription: string;
  eventEndDate: Moment;
  eventLimitOfInsurance: string;
  eventStartDate: Moment;
  eventType: string;
  eventVenue: string;
  limitedTerrorism: number;
  limitedWar: number;
  nationalMounting: number;
  nonAppearance: number;
  threat: number;
  // nucBioChemTerrorism: number;
  nuclearHazard: number;
  state: string;
  strike: number;
  tria: number;
  // terrorism: number;
  war: number;
  wildfire: number;
  windstorm: number;
  winterWeather: number;
  zipCode: number;

  constructor() {
    // this.additionalPerilLabel1 = "";
    // this.additionalPerilLabel2 = "";
    // this.additionalPerilLabel3 = "";
    // this.additionalPerilValue1 = "";
    // this.additionalPerilValue2 = "";
    // this.additionalPerilValue3 = "";
    // this.address = "";
    // this.baseRate = null;
    // this.bioChemTerrIncl = null;
    // this.city = "";
    // this.civilCommotion = null;
    // this.communicableDisease = null;
    // this.country = "";
    // this.csTornadoHail = null;
    // this.cyber = null;
    // this.earthquake = null;
    // this.era = null;
    // this.eventDeductible = "";
    // this.eventDescription = "";
    // this.eventEndDate = null;
    // this.eventLimitOfInsurance = "";
    // this.eventStartDate = null;
    // this.eventType = "";
    // this.eventVenue = "";
    // this.limitedTerrorism = null;
    // this.limitedWar = null;
    // this.nationalMounting = null;
    // this.nonAppearance = null;
    // this.nucBioChemTerrorism = null;
    // this.nuclearHazard = null;
    // this.state = "";
    // this.strike = null;
    // this.terrorism = null;
    // this.war = null;
    // this.wildfire = null;
    // this.windstorm = null;
    // this.winterWeather = null;
    // this.zipCode = null;
  }
}
