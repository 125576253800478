import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import {
  SecurityUserGroupRequest,
  SecurityUserGroupResponse,
  SubCompany
} from './def/claim-subcompany';
import { Observable, BehaviorSubject } from 'rxjs';
//import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class SubCompanyService {
  constructor() {}

  private currentSubCompanyCode = new BehaviorSubject<any>({
    url: '',
    subCompanyCode: '',
    subCompanyList: []
  });

  private navigationPath = new BehaviorSubject<any>({
    url: '',
    subCompanyCode: '',
    subCompanyList: []
  });

  updateRoute(
    url: string,
    subCompanyCode: string,
    subCompanyList: SubCompany[]
  ) {
    this.navigationPath.next({
      url: url,
      subCompanyCode: subCompanyCode,
      subCompanyList: subCompanyList
    });
  }

  getUpdatedRoute(): Observable<any> {
    return this.navigationPath.asObservable();
  }

  updateSelectedSubCompanyCode(
    url: string,
    subCompanyCode: string,
    subCompanyList: SubCompany[]
  ) {
    this.currentSubCompanyCode.next({
      url: url,
      subCompanyCode: subCompanyCode,
      subCompanyList: subCompanyList
    });
  }

  getSelectedSubCompany(): Observable<any> {
    return this.currentSubCompanyCode.asObservable();
  }
}
